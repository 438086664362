import {useFeaturesState} from '../../../../Organisms/Features'
import {ExcludedInvoice} from './useTotalAmount'
import {Invoice} from '../Invoice.types'

export const useExcludedInvoiceNumber = () => {
  const {getFeature} = useFeaturesState()
  const isCustomerInvoiceNumber = getFeature('CustomerInvoiceNumber')

  const invoiceNumber = (excludedInvoice: ExcludedInvoice, invoices: Invoice[]): string => {
    const invoice = invoices.find((invoice) => invoice.invoiceId === excludedInvoice.invoiceId)
    return isCustomerInvoiceNumber
      ? invoice?.customerInvoiceNumber ?? ''
      : invoice?.invoiceNumber ?? ''
  }

  return {
    invoiceNumber
  }
}
