import {Box} from '@material-ui/core'
import React from 'react'

import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'

import {Year, YearFilter} from './YearFilter'
import {PageNames} from '../../../../common/constants'

export interface FilterBarErrorStatements {
  key: string
  label: string
}

export interface StatementsFilter {
  year?: string
  payerId?: string
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
}

interface FilterType {
  errors?: FilterBarErrorStatements[]
  otherProps?: React.ReactPropTypes
  xs?: boolean
  selectedYear?: Year
  onDropdownChange: (year: {label: string; id: number}) => void
}

export const Filters: React.FC<FilterType> = ({xs, otherProps, selectedYear, onDropdownChange}) => {
  if (xs) {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <CustomersFilter onLight page={PageNames.STATEMENTS} />
        <Box my={1} />
        <YearFilter year={selectedYear} onDropdownChange={onDropdownChange} />
      </Box>
    )
  }
  return (
    <GenericFilterBar
      data-test-id="statements-filters"
      toolbarProps={{notEndLast: true}}
      {...otherProps}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
        <Box display="flex">
          <CustomersFilter onLight page={PageNames.STATEMENTS} />
          <Box mx={1} />
          <YearFilter year={selectedYear} onDropdownChange={onDropdownChange} />
        </Box>
      </Box>
    </GenericFilterBar>
  )
}
