export interface Payment {
  amount: number
  companyCode: string
  currency: string
  paymentId: string
  paymentNumber: string
  postingDate: string
  remark: string
  surchargeItem?: SurchargeItem
}

export interface SurchargeItem {
  amount: number
  currency: string
}
