import {combineReducers} from 'redux'

import {
  DeliveriesStateType,
  INVOICES_CLEAR,
  INVOICES_FETCH_SUCCESS,
  InvoicesActionTypes,
  InvoicesStateType,
  deliveries
} from '../../../Organisms/Invoices'
import invoices from '../../../Organisms/Invoices/Invoices.reducer'
import {concatIds} from '../../../util/reducer.helper'

import {
  INVOICES_CLEAR_VIEW,
  INVOICES_SWITCH_VIEW,
  InvoicePageActionTypes,
  InvoicePageState,
  InvoiceView
} from './Action.types'

const initialState: InvoicePageState = {
  currentView: InvoiceView.DEFAULT,
  [InvoiceView.DEFAULT]: [],
  [InvoiceView.LOOKUP]: []
}

export const view = (
  state = initialState,
  action: InvoicePageActionTypes | InvoicesActionTypes
) => {
  switch (action.type) {
    case INVOICES_SWITCH_VIEW:
      return {
        ...state,
        [state.currentView]: action.clearPrevView ? [] : state[state.currentView],
        currentView: action.view ? InvoiceView[action.view] : InvoiceView.DEFAULT
      }

    case INVOICES_CLEAR_VIEW:
      return {
        ...state,
        [state.currentView]: []
      }

    case INVOICES_FETCH_SUCCESS: {
      // not for DetailPage
      if (!('skip' in action.params)) {
        return state
      }
      // if not Detailpage
      const collectionIds = action.payload.map((item) => item.invoiceId)
      return {
        ...state,
        [state.currentView]:
          action.params.skip > 0
            ? concatIds(state[state.currentView], collectionIds)
            : collectionIds
      }
    }

    case INVOICES_CLEAR:
      return {
        ...initialState
      }

    default:
      return state
  }
}

export interface InvoicesState {
  invoices: InvoicesStateType
  deliveries: DeliveriesStateType
  view: InvoicePageState
}
export default combineReducers<InvoicesState>({
  invoices,
  deliveries,
  view
})
