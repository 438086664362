import {HCThemeType} from '@hconnect/uikit/src/lib/HCTheme.types'
import {makeStyles} from '@material-ui/core'

export const usePayNowStyles = makeStyles((theme: HCThemeType) => ({
  page: {
    minHeight: theme.spacing(96)
  },
  h2: {
    fontSize: 25,
    letterSpacing: theme.spacing(0.3125)
  },
  h3: {
    fontSize: 21,
    fontWeight: 500
  },
  h3_warning: {
    fontSize: 21,
    fontWeight: 500,
    color: 'rgb(207, 151, 14)'
  },
  small: {
    fontSize: 14,
    lineHeight: '1em'
  },
  paper: {
    width: theme.spacing(43),
    minHeight: theme.spacing(41),
    backgroundColor: theme.palette.grey['50'],
    borderRadius: 3
  },
  paperWide: {
    width: '100%',
    minHeight: theme.spacing(20.5),
    backgroundColor: theme.palette.grey['50'],
    borderRadius: 3
  },
  fontSizeLarge: {
    fontSize: 40
  },
  subtitle: {
    maxWidth: theme.spacing(44.5)
  },
  formLabel: {
    color: 'rgb(0, 72, 98)',
    fontSize: 21,
    fontWeight: 700
  },
  formSubLabel: {},
  amountFormContainer: {
    marginTop: theme.spacing(9),
    width: theme.spacing(32.5)
  },
  amountFormControl: {
    border: '1px solid rgb(231, 231, 231)',
    background: 'rgb(247, 247, 247)',
    borderRadius: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    fontWeight: 800,
    height: theme.spacing(7),
    fontSize: 16,
    '&:hover': {
      border: '1px solid rgb(231, 231, 231)'
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        borderBottom: 'none'
      }
    }
  },
  textInputLight: {
    border: '1px solid rgb(231, 231, 231)',
    background: 'rgb(247, 247, 247)',
    borderRadius: theme.spacing(0.5),
    fontWeight: 500,
    height: theme.spacing(7),
    fontSize: 16,
    '&:hover': {
      border: '1px solid rgb(231, 231, 231)',
      background: 'rgb(247, 247, 247)'
    },
    '&:focus': {
      background: 'rgb(247, 247, 247)'
    },
    '& .MuiInput-underline, & .MuiInput-underline:hover': {
      '&::after,&::before': {
        borderBottom: 'none'
      }
    }
  },
  inputLabel: {
    fontWeight: 400,
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(2),
    color: 'rgb(120, 153, 165)'
  },
  textLabelLight: {
    fontWeight: 400,
    color: 'rgb(120, 153, 165)'
  },
  radio: {
    boxShadow: 'none',
    border: 'none',
    '&:hover': {
      boxShadow: 'none',
      border: 'none'
    }
  },
  greenText: {
    color: theme.palette.success.main
  },
  scrollableBox: {
    maxHeight: 100,
    maxWidth: 120,
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  invoices: {
    borderLeftColor: theme.palette.info.main,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    '& .MuiCardContent-root': {
      '&:last-child': {
        paddingBottom: 16
      }
    }
  },
  invoicesError: {
    borderLeftColor: theme.palette.error.main,
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    '& .MuiCardContent-root': {
      '&:last-child': {
        paddingBottom: 16
      }
    }
  },
  textLabelDark: {
    color: 'rgba(0, 55, 77, 0.6)',
    fontSize: 14,
    '&:focused,&:active,&:hover': {
      color: 'rgba(0, 55, 77, 0.6)'
    }
  },
  textInputDark: {
    textDecoration: 'none',
    fontWeight: 500,
    color: 'rgba(0, 55, 77, 0.96)',
    fontSize: 16
  }
}))
