/* eslint-disable complexity */
import {Page, Typography, useTranslation} from '@hconnect/uikit'
import {Box, Button, Grid, Paper, Link} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import CancelRoundedIcon from '@material-ui/icons/CancelRounded'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import React from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {useResponsiveBreakpoints} from '../../../../Molecules/Responsive.utils'

import {Features} from '../../../../Organisms/Features'
import {CreatePaymentResponse} from '../../../../Organisms/Payments'
import {usePayNowStyles} from '../PayNow.styles'

interface Props {
  pollingData?: CreatePaymentResponse
  pollingError?: boolean
  isFetching: boolean
  documentNumber?: string
  documentId?: string
  confirmationError?: string
  paymentIsSuccessful: boolean | 'inApproval' | undefined
  paymentConfirmationMail?: string
  payerId?: string
}

export const PayNowConfirmation: React.FC<Props> = ({
  confirmationError,
  pollingData,
  isFetching,
  documentNumber,
  documentId,
  paymentIsSuccessful,
  paymentConfirmationMail,
  payerId,
  pollingError
}) => {
  const {h2, h3, greenText, paperWide} = usePayNowStyles()
  const {smallScreen} = useResponsiveBreakpoints()
  const {t} = useTranslation()
  const reviewTransactionsLink = `/finance/transactions${payerId ? `?payerId=${payerId}` : ''}`

  if (!pollingError && (isFetching || (pollingData && pollingData.status === 'pending'))) {
    return (
      <Page variant="sheet" data-test-id="pay-now-page-confirmation">
        <CircularProgress
          style={{display: 'block', margin: '0 auto'}}
          data-test-id="payment-confirmation-loader"
        />
      </Page>
    )
  }

  return (
    <Page variant="sheet" data-test-id="pay-now-page-confirmation">
      <Box height={766}>
        <Box data-test-id="confirmation-button">
          <Box
            data-test-id="confirmation-title"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            ml={smallScreen ? 0.5 : 7}
            mr={smallScreen ? 0.5 : 7}
          >
            <Box>
              <Typography variant="h2" classes={{h2}}>
                {paymentIsSuccessful
                  ? t('lumpSumPayment.confirmation.successful')
                  : t('lumpSumPayment.confirmation.notSuccessful')}
              </Typography>
            </Box>
            <Box data-test-id="confirmation-goto-payments">
              <Features name="Payments">
                <Link to={reviewTransactionsLink} component={RouterLink}>
                  <Button color="primary" data-test-id="confirmation-goto-payments-btn">
                    {t('lumpSumPayment.confirmation.reviewTransactions')}
                  </Button>
                </Link>
              </Features>
            </Box>
          </Box>
        </Box>
        <Box mt={8}>
          <Grid container>
            <Grid item md={3} />
            <Grid item md={6}>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <Paper elevation={0} classes={{root: paperWide}}>
                  <Box data-test-id="confirmation-content" height={325} p={1.5}>
                    <Box
                      fontSize={48}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      pt={3.75}
                    >
                      <>
                        {paymentIsSuccessful ? (
                          <CheckCircleIcon
                            data-test-id="confirmation-success"
                            htmlColor="#31ca72"
                            fontSize="inherit"
                          />
                        ) : (
                          <CancelRoundedIcon
                            data-test-id="confirmation-failed"
                            width={48}
                            color="error"
                            fontSize="inherit"
                          />
                        )}
                      </>
                      <Box mt={2.5}>
                        <Typography
                          variant="h3"
                          color="textPrimary"
                          classes={{h3, colorTextPrimary: greenText}}
                        >
                          {paymentIsSuccessful ? t('lumpSumPayment.confirmation.status') : ''}
                        </Typography>
                      </Box>
                      <Box mt={3} data-test-id="confirmation-message">
                        {paymentIsSuccessful ? (
                          <Box display="flex" flexDirection="column" p={1}>
                            <Typography component="div" align="left">
                              {t('lumpSumPayment.confirmation.intro', {
                                paymentId:
                                  paymentIsSuccessful === 'inApproval'
                                    ? documentId
                                    : documentNumber,
                                email: paymentConfirmationMail || ''
                              })}
                            </Typography>
                            <Box m={1} />
                            <Typography
                              variant="caption"
                              color="secondary"
                              component="div"
                              align="center"
                            >
                              {t('lumpSumPayment.confirmation.disclaimer', {
                                email: paymentConfirmationMail || ''
                              })}
                            </Typography>
                          </Box>
                        ) : null}
                        {!paymentIsSuccessful && confirmationError ? (
                          <Typography>
                            {t('lumpSumPayment.confirmation.error', {error: confirmationError})}
                          </Typography>
                        ) : null}
                      </Box>
                      <Features name="Payments">
                        <Box mt={2}>
                          <Typography>
                            {t('lumpSumPayment.confirmation.followUp')}
                            <Link
                              color="primary"
                              role="link"
                              to={reviewTransactionsLink}
                              component={RouterLink}
                            >
                              {t('lumpSumPayment.confirmation.reviewTransactions')}
                            </Link>
                          </Typography>
                        </Box>
                      </Features>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Grid>
            <Grid item md={3} />
          </Grid>
        </Box>
      </Box>
    </Page>
  )
}
