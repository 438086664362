import {withRoute} from '@hconnect/uikit'

import SalesConditionsPage from './SalesConditions'

export const ROUTE = '/sales-conditions'

export default withRoute({
  path: ROUTE,
  label: 'Terms and Conditions of Sale',
  hideInMainNav: true,
  routeProps: {exact: true}
})(SalesConditionsPage)
