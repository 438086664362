import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import React from 'react'

import {api} from '../../../../App.global'
import {addSettlementError, CreatePaymentResponse} from '../../../../Organisms/Payments'
import {store} from '../../../../Root.store'
import {PaymentRequestStatus} from '../PayNow.types'

export const usePaymentResult = (
  setPaymentRequest: React.Dispatch<React.SetStateAction<boolean>>,
  paymentStatus?: PaymentRequestStatus,
  id?: string
) => {
  return useQuery(
    ['payment-result', id],
    async () => {
      const res: AxiosResponse<
        CreatePaymentResponse & {documentNumber: string} & {errorMessage: string} & {
          inApproval: boolean
        } & {isRolledBack: boolean}
      > = await api.get(`/paymentRequests/result/${encodeURIComponent(id!)}`)
      return res.data
    },
    {
      enabled: !!id && !!paymentStatus && ['done', 'failed'].includes(paymentStatus),
      onSuccess: (result) => {
        if (result && result.errorMessage && !result.documentNumber && !result.inApproval) {
          store.dispatch(addSettlementError())
        }
        setPaymentRequest(false)
      }
    }
  )
}
