import CloseIcon from '@mui/icons-material/Close'
import {Box, Card, Stack, Typography} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'

import {TotalAmountCriteria} from '../hooks'
import {useExcludedInvoiceNumber} from '../hooks/useExcludedInvoiceNumber'
import {useExcludedInvoices} from '../hooks/useExcludedInvoices'
import {useStyles} from '../Invoice.styles'
import {Invoice} from '../Invoice.types'
import {excludedInvoicesByReason, excludedInvoicesTotalAmount} from '../Invoice.utils'

interface ExcludedInvoicesAsideProps {
  t: TFunction
  totalAmountCriteria: TotalAmountCriteria
  close: () => void
  invoices: Invoice[]
  language: string
}

export const ExcludedInvoicesAside: React.FC<ExcludedInvoicesAsideProps> = ({
  t,
  totalAmountCriteria,
  close,
  invoices,
  language
}: ExcludedInvoicesAsideProps) => {
  const currency = invoices.length > 0 ? invoices[0].invoiceCurrency : ''
  const {invoiceNumber} = useExcludedInvoiceNumber()
  const {excludedInvoices} = useExcludedInvoices(invoices, totalAmountCriteria)
  const {classes} = useStyles()

  const totalAmount = excludedInvoicesTotalAmount(excludedInvoices)
  const formattedTotalAmount = currencyFormatter.format(totalAmount, {
    code: currency,
    locale: language
  })

  const excludedInvoicesGroups = excludedInvoicesByReason(excludedInvoices)

  return (
    <Box p={4} data-test-id="excluded-invoices-aside">
      <Stack direction="row" spacing={'8px'} alignItems="baseline">
        <Box>
          <CloseIcon
            sx={{fontSize: '18px', cursor: 'pointer'}}
            onClick={close}
            data-test-id="excluded-invoices-aside-btn-close"
          />
        </Box>
        <Typography
          color="textPrimary"
          variant="h4"
          style={{fontWeight: 600, paddingBottom: '30px'}}
        >
          {t('invoice.singleInvoicePayment.excludedInvoicesListTitle')}
        </Typography>
      </Stack>
      <Card
        variant="outlined"
        sx={{padding: '12px 16px 12px 16px', borderLeft: '4px solid #54708C'}}
        data-test-id="excluded-invoices-aside-summary"
      >
        {t('invoice.singleInvoicePayment.excludedInvoicesListDescription', {
          amount: formattedTotalAmount
        })}
      </Card>

      {Object.keys(excludedInvoicesGroups).map((reason) => (
        <Box
          sx={{marginTop: '24px'}}
          key={`reason-${reason}`}
          data-test-id="excluded-invoices-group"
        >
          <Typography variant="h6" sx={{paddingBottom: '16px'}}>
            {t(`invoice.ineligibleInvoicesGroup.${reason}`)}
          </Typography>
          <Box>
            {excludedInvoicesGroups[reason].map((excludedInvoice) => (
              <Card
                key={`invoice-${excludedInvoice.invoiceId}`}
                variant="outlined"
                classes={{
                  root:
                    reason === 'excluded'
                      ? classes.invoiceExcludedAside
                      : classes.invoiceIncludedAside
                }}
                sx={{
                  padding: '14px 16px',
                  borderRadius: '0px',
                  borderTop: 'none',
                  borderRight: 'none',
                  borderBottomWidth: '2px'
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{fonSize: '18px', fontWeight: '600'}}
                >
                  <Box>{invoiceNumber(excludedInvoice, invoices)}</Box>
                  <Box sx={{textDecoration: 'line-through'}}>
                    {currencyFormatter.format(excludedInvoice.grossAmount, {
                      code: currency,
                      locale: language
                    })}
                  </Box>
                </Stack>
              </Card>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  )
}
