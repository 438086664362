import {DelegoCreditCard} from '../../../../Organisms/Payments'

export const createMaskedCardNumber = (token: string | undefined) => {
  if (!token) {
    return '-'
  }
  const last4Digits = token.split('-')[1]
  return `************${last4Digits}`
}

export const isCardSimilarToOthers = (cards: DelegoCreditCard[], cardToCheck: DelegoCreditCard) =>
  cards.find(
    (card) =>
      card.token !== cardToCheck.token &&
      card.token.slice(-4) === cardToCheck.token.slice(-4) &&
      card.cardType === cardToCheck.cardType &&
      card.expiryDate === cardToCheck.expiryDate &&
      card.holderName === cardToCheck.holderName
  )

const sdkPerCountry = {
  'US-QA': 'https://lehigh-qrl-web.delego-cloud.com/DelegoRP/sdk/sdk.bundle.js',
  US: 'https://lehigh-prs-web.delego-cloud.com/DelegoRP/sdk/sdk.bundle.js',
  'AU-QA': 'https://hanson-acq-rp.delego-cloud.com/DelegoRP/sdk/sdk.bundle.js',
  // TODO: update the prod link if it's needed
  AU: 'https://hanson-acp-rp.delego-cloud.com/DelegoRP/sdk/sdk.bundle.js'
}

export const loadSDK = (country, callback) => {
  const existingScript = document.getElementById('delegoSDK')
  if (!existingScript) {
    const stage = process.env.REACT_APP_STAGE === 'prod' ? country : `${country}-QA`
    const script = document.createElement('script')
    script.src = sdkPerCountry[stage]
    script.crossOrigin = 'true'
    script.type = 'text/javascript'
    script.id = 'delegoSDK'
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}

const customFields = {}

interface BofaProps {
  amount: number
  customerReference: string
  customerAccountReference: string
}
export interface BofaEvent {
  token: string
  digiSign: string
  customer_account_reference: string
  customer_reference: string
}

const toAmountString = (amount: number) =>
  amount
    .toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    .replace(',', '')

export const loadBofaSDK = (callback: () => void) => {
  const existingScript = document.getElementById('orbipay-checkout-script')
  if (!existingScript) {
    const clientKey = process.env.REACT_APP_STAGE === 'prod' ? '6630916219' : '0647652802'

    const script = document.createElement('script')
    script.src =
      process.env.REACT_APP_STAGE === 'prod'
        ? 'https://jsco.billerpayments.com/app/opco/v5/scripts/checkoutofsc.js'
        : 'https://sbjsco.billerpayments.com/app/opco/v5/scripts/checkoutofsc.js'
    script.id = 'orbipay-checkout-script'
    script.setAttribute('data-prevent_posting', 'true')

    document.body.appendChild(script)

    script.onload = () => {
      ;(window as any).OrbipayCheckoutSimple.setClientKey(clientKey)
      ;(window as any).OrbipayCheckoutSimple.setApiEvent('create_payment')
      ;(window as any).OrbipayCheckoutSimple.setFormWidth('100%')
      ;(window as any).OrbipayCheckoutSimple.setFormHeight('100%')
      ;(window as any).OrbipayCheckoutSimple.setCustomFields(customFields)
      if (callback) callback()
    }
  }

  if (existingScript && callback) callback()
}

export const setBofaProps = (props: BofaProps) => {
  const checkout = (window as any).OrbipayCheckoutSimple

  if (checkout) {
    checkout.setAmount(toAmountString(props.amount))
    checkout.setCustomerReference(props.customerReference)
    checkout.setCustomerAccountReference(props.customerAccountReference)
  }
}
