import {withRouteRequiringPermission} from '../../../Molecules/PermissionBoundary'

import {InviteOverviewPage} from './InviteOverviewPage'

export const ROUTE = '/invitations'

export const InvitationRoute = withRouteRequiringPermission({
  path: ROUTE,
  label: 'Invitations',
  labelKey: 'invitations.navigationLabel',
  routeProps: {exact: true},
  order: 1000,
  rolesDisabled: ['GLOBAL_ADMIN'],
  featureToggleName: ['BusinessOwnerInviteUser', 'SalesAgentInviteUser'],
  excludeFeatureToggleName: 'UseHaulierView'
})(InviteOverviewPage)
