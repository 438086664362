import {DelegoCreditCard, PaymentResponse} from '../../../../Organisms/Payments'

export const getExpiryDateMasked = (expiryDate: string) => {
  if (expiryDate) {
    const [year, month] = expiryDate.split('-')
    return `${month}/${year.slice(2)}`
  }
  return ''
}

export const normalizeExpiryDate = (value: string) => {
  return value
    .replace(
      /[^0-9]/g,
      '' // To allow only numbers
    )
    .replace(
      /^([2-9])$/g,
      '0$1' // To handle 3 > 03
    )
    .replace(
      /^(1{1})([3-9]{1})$/g,
      '0$1/$2' // 13 > 01/3
    )
    .replace(
      /^0{1,}/g,
      '0' // To handle 00 > 0
    )
    .replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g,
      '$1/$2' // To handle 113 > 11/3
    )
}

export const normalizeCardNumber = (value: string) => {
  return (
    value
      .replace(/\s/g, '')
      .match(/.{1,4}/g)
      ?.join(' ')
      .substr(0, 19) || ''
  )
}

export const mapDFCardToDelego = (selectedCard: DelegoCreditCard | null): PaymentResponse => {
  const [year, month] = selectedCard?.expiryDate.split('-') || []
  return {
    ...selectedCard,
    cardholderName: selectedCard?.holderName || '',
    cardExpiration: {
      year: +year,
      month: +month
    }
  }
}
