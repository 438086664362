import {trackEvent} from '@hconnect/common/logging/Analytics'
import {dateFormatter, TableColumnType} from '@hconnect/uikit'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Box, Button} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'
import {v4 as uuidV4} from 'uuid'

import {Entity, EntityPage, getDocumentFileName} from '../../../Organisms/Documents'
import {LinkMedia} from '../Invoices/Delivery.types'

import {Statement} from './Statements.types'

export interface Analytics {
  userId: string | undefined
  userMail: string | null | undefined
  userCountry: string | null | undefined
  userRoles: any[] | undefined
  userIsInternal: boolean | undefined
  customerId: string | undefined
  customerName: string | undefined
}
const findStatementDocument = (statement: Statement) => {
  if (statement && 'links' in statement && Array.isArray(statement.links)) {
    return statement.links.find((link) => link.rel === 'documents' && link.type === 'statement')
  }
  return undefined
}

interface DownloadStatementPDFType {
  statement: Statement
  t: TFunction
  analytics: Analytics
  downloadDocuments: (jobId: string, documentId: string, fileName: string) => void
}
export const DownloadStatementPDF: React.FC<DownloadStatementPDFType> = ({
  statement,
  t,
  analytics,
  downloadDocuments
}) => {
  const {date} = statement
  const textStatement = t('statements.documentLabel')
  const statementPdf = findStatementDocument(statement)
  const documentId = statementPdf ? statementPdf.href.split('/')[1] : undefined
  const fileName = statementPdf
    ? getDocumentFileName(EntityPage.statements, Entity.statement, statementPdf.media, t, {
        entityNumber: date.toString()
      })
    : `${Entity.statement}.${LinkMedia.Pdf}`

  return documentId ? (
    <Box>
      <Button
        data-test-id="qr-code-download"
        variant="text"
        size="small"
        color="primary"
        style={{justifyContent: 'flex-start'}}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault()
          e.stopPropagation()
          const jobId: string = uuidV4()
          trackEvent('hubDownloadSingle', {
            product: 'hub',
            jobId: jobId,
            downloadedItem: 'statement',
            linkToDownloadedItem: statementPdf?.href,
            customerId: analytics.customerId,
            customerName: analytics.customerName,
            userIsInternal: analytics.userIsInternal,
            entryPoint: 'Statements List',
            issuedDate: statement.date,
            userId: analytics.userId,
            userCountry: analytics.userCountry,
            userRoles: analytics.userRoles
          })
          downloadDocuments(jobId, documentId, fileName)
        }}
        startIcon={<PictureAsPdfIcon />}
      >
        {t('order.orderDetails.downloadPdf')}
      </Button>
    </Box>
  ) : (
    <Box>{textStatement}</Box>
  )
}
interface StatementColumnsType {
  t: TFunction
  language: string
  downloadDocuments: (jobId: string, documentId: string, fileName: string) => void
  analytics: Analytics
}

export const StatementsColumns = ({
  t,
  language,
  downloadDocuments,
  analytics
}: StatementColumnsType) => {
  const columnPool: {[key: string]: TableColumnType} = {
    date: {
      field: 'date',
      headerName: t('statements.date'),
      sortable: true,
      headerAlign: 'left',
      align: 'left',
      flex: 2,
      renderCell: (item: Statement) => <Box>{dateFormatter(item.date, language)}</Box>
    },
    files: {
      field: 'files',
      headerName: t('statements.files'),
      headerAlign: 'center',
      flex: 1,
      align: 'center',
      renderCell: (item) => (
        <DownloadStatementPDF
          t={t}
          analytics={analytics}
          downloadDocuments={downloadDocuments}
          statement={item}
        />
      )
    }
  }

  return [columnPool.date, columnPool.files]
}
