import {Typography} from '@hconnect/uikit'
import AddCircleOutline from '@mui/icons-material/AddCircleOutline'
import {Box} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {Invoice, ServicesOrSurcharge, ServicesOrSurcharges} from '../Invoice.types'

interface IconProps {
  serviceOrSurcharge: ServicesOrSurcharges
  t: TFunction
}
export const ServiceOrSurchargeIcon: React.FC<IconProps> = ({serviceOrSurcharge, t}) => (
  <Typography color="secondary" variant="body2">
    <Box display="flex" flexDirection="row" alignItems="center">
      <AddCircleOutline style={{fontSize: 14, marginRight: 2}} />
      {t(`invoice.servicesOrSurcharges.${serviceOrSurcharge}`)}
    </Box>
  </Typography>
)

interface Type {
  invoice: Invoice
  t: TFunction
}

export const InvoiceType: React.FC<Type> = ({invoice, t}) => (
  <Box display="flex" flexDirection="column" justifyContent="flex-start">
    <Box>{t(`invoice.types.${invoice.type}`)}</Box>
    {invoice.serviceItems.length > 0 ? (
      <ServiceOrSurchargeIcon serviceOrSurcharge={ServicesOrSurcharge.Service} t={t} />
    ) : null}
    {invoice.surchargeItems.length > 0 ? (
      <ServiceOrSurchargeIcon
        serviceOrSurcharge={ServicesOrSurcharge.ServicesOrSurchargesOnly}
        t={t}
      />
    ) : null}
  </Box>
)
