import {useLegalDocument} from '@hconnect/common/hooks/legal/useLegalDocuments'
import {useSelector} from 'react-redux'
import {RouteComponentProps, withRouter} from 'react-router-dom'

import {AppState} from '../../Root.store'

import LegalPage from './LegalPage'

interface ILegalDocumentPages {
  legalDocumentType: 'dpp' | 'tc' | 'imprint'
}
const LegalDocumentPages: React.FC<ILegalDocumentPages & RouteComponentProps> = ({
  legalDocumentType
}) => {
  const user = useSelector((state: AppState) => state?.userProfile?.userProfile)

  const country = user?.country?.toLowerCase()
  const defaultLocale = user?.defaultLocale?.toLowerCase()?.split('-')?.[0]

  const {queryInfo} = useLegalDocument(legalDocumentType, country, defaultLocale)

  return (
    <LegalPage content={queryInfo.data} error={queryInfo.error} isFetching={queryInfo.isLoading} />
  )
}

export default withRouter(LegalDocumentPages)
