/* eslint-disable complexity */
import {dateFormatter} from '@hconnect/uikit'
import CloseIcon from '@mui/icons-material/Close'
import {Box, Grid, IconButton} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CustomHeader} from '../../../../../Molecules/CustomHeader'
import {FieldValue} from '../../../../../Molecules/FieldDetails'
import {useFeaturesState} from '../../../../../Organisms/Features'
import {QuantityUomToSymbolComponent} from '../../../../Order/Order.components'
import {Delivery, DeliveryWithLineItem} from '../../Delivery.types'
import {
  AsideToggleTypes,
  DeliveryWithRelatedLineItem,
  FinanceAnalytics,
  Invoice
} from '../../Invoice.types'
import {formatNumber} from '../InvoiceDetails.utils'

import {InvoiceDeliveriesItemsList} from './InvoiceDeliveriesItemsList'

import {DeliveryMaterialDescription, ServiceSurchargeItemsList, useIconStyles} from '.'

interface InvoiceDeliveriesItemsProps {
  item: any
  deliveries?: Delivery[]
  invoice?: Invoice
  analytics: FinanceAnalytics
  close: () => void
  surchargeRelatedDeliveries?: DeliveryWithRelatedLineItem[]
}
export const InvoiceDeliveriesItems: React.FC<InvoiceDeliveriesItemsProps> = ({
  item,
  deliveries,
  invoice,
  analytics,
  close,
  surchargeRelatedDeliveries
}) => {
  const {classes} = useIconStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {getFeature, getResolutionByNameAndConstraint, normalized} = useFeaturesState()
  const hideAggregatedQuantity = getFeature('HideAggregatedQuantity')

  const toggles: AsideToggleTypes = {
    isCustomerInvoiceNumber: getFeature('CustomerInvoiceNumber'),
    showState: getFeature('ShowState'),
    isCustomerDeliveryNumber: getFeature('CustomerDeliveryNumber'),
    useTruckLicensePlate: getFeature('TruckLicensePlate'),
    isInvoiceDetailsDisabled: (invoiceId: string) =>
      getResolutionByNameAndConstraint(
        'InvoiceDetails',
        normalized,
        'connectorId'
      ).disabled.includes(invoiceId.split('.')[0]) || !getFeature('Finance')
  }

  if (!invoice || !deliveries || !item) return null

  const isInvoiceType = invoice.type === 'invoice'

  return (
    <Box data-test-id="invoice-deliveries-items">
      <CustomHeader title={t('invoiceDetail.invoiceDeliveriesItems')}>
        <IconButton
          className={classes.icon}
          size="large"
          onClick={() => close()}
          data-test-id="invoice-delivery-close"
          sx={{px: 2, py: 0, mt: -2}}
        >
          <CloseIcon />
        </IconButton>
      </CustomHeader>

      <Grid container>
        {!hideAggregatedQuantity && invoice.type === 'invoice' && (
          <Grid item xs={12}>
            <FieldValue
              label={t('invoiceDetail.lineItems.dateShipped')}
              value={item?.shippingDate ? dateFormatter(item?.shippingDate, language) : '-'}
              dataTestId="invoice-delivery-shipping-date"
              gutter
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <FieldValue
            label={t('invoiceDetail.lineItems.material')}
            value={
              <DeliveryMaterialDescription
                delivery={item?.lineItems[0] as DeliveryWithLineItem}
                t={t}
              />
            }
            dataTestId="invoice-delivery-material"
            gutter
          />
        </Grid>

        {!hideAggregatedQuantity && invoice.type === 'invoice' && (
          <Grid item xs={6}>
            <FieldValue
              label={t('invoiceDetail.lineItems.quantity')}
              value={
                <>
                  {formatNumber(item?.quantity?.loadQuantity, language)}{' '}
                  {item?.quantity?.loadQuantityUom && (
                    <QuantityUomToSymbolComponent uom={item?.quantity?.loadQuantityUom} />
                  )}
                </>
              }
              dataTestId="invoice-delivery-quantity"
              gutter
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FieldValue
            label={t('invoiceDetail.lineItems.netAmount')}
            value={currencyFormatter.format((item?.netAmount as number) || 0, {
              code: invoice?.invoiceCurrency,
              locale: language
            })}
            dataTestId="invoice-delivery-subtotal"
            gutter
          />
        </Grid>
      </Grid>
      {item?.materialItems?.length > 0 && isInvoiceType && (
        <InvoiceDeliveriesItemsList
          deliveries={[...item.materialItems]}
          toggles={toggles}
          analytics={analytics}
          customerId={analytics.customerId || ''}
          currency={invoice.invoiceCurrency}
          language={language}
          t={t}
        />
      )}
      {item?.serviceItems?.length > 0 && isInvoiceType && (
        <ServiceSurchargeItemsList
          items={item.serviceItems}
          toggles={toggles}
          analytics={analytics}
          t={t}
          deliveries={deliveries || []}
          type="services"
        />
      )}
      {item?.surchargeItems?.length > 0 && isInvoiceType && (
        <ServiceSurchargeItemsList
          items={item.surchargeItems}
          toggles={toggles}
          analytics={analytics}
          t={t}
          deliveries={deliveries || []}
          surchargeRelatedDeliveries={surchargeRelatedDeliveries}
          type="surcharges"
        />
      )}
    </Box>
  )
}
