import {Card, CardContent, Typography} from '@mui/material'
import {ReactNode} from 'react'

interface PaymentDetailsAlertCardProps {
  text: string
  children?: ReactNode
}

export const PaymentDetailsAlertCard: React.FC<PaymentDetailsAlertCardProps> = ({
  text,
  children,
  ...props
}) => {
  return (
    <Card variant="outlined" sx={{borderLeft: '4px solid #EE8B00'}} {...props}>
      <CardContent
        sx={{
          padding: '12px 16px',
          '&:last-child': {pb: '12px'}
        }}
      >
        <Typography
          color="#435A70"
          fontWeight={400}
          fontSize={16}
          lineHeight="22px"
          data-test-id="payment-details-alert-card-text"
        >
          {text}
        </Typography>
        {children}
      </CardContent>
    </Card>
  )
}
