import {Input, withStyles} from '@material-ui/core'
import currencyFormatter, {Currency} from 'currency-formatter'
import React from 'react'
import {Control, Controller, UseFormSetValue, Validate} from 'react-hook-form'
import NumberFormat from 'react-number-format'

const HCInput = withStyles({
  root: {
    fontWeight: 500
  }
})(Input)

interface FormType {
  amount: string
  paymentMethodField: string
}
const ONE_SUFFIX_ZERO = '0'
const TWO_SUFFIX_ZERO = '00'

export const addZerosToQueryParamSum = (amount: string, currency: string): string => {
  const {symbol, spaceBetweenAmountAndSymbol, decimalSeparator, symbolOnLeft} =
    currencyFormatter.findCurrency(currency) as Currency
  const start = symbolOnLeft
  const adornment = start
    ? `${symbol}${spaceBetweenAmountAndSymbol ? ' ' : ''}`
    : `${spaceBetweenAmountAndSymbol ? ' ' : ''}${symbol}`
  return addZeros(amount, decimalSeparator, adornment, symbolOnLeft)
}

export const addZeros = (
  amountValue: string,
  decimalSeparator: string,
  adornment: string,
  symbolOnLeft: boolean
): string => {
  const parsed = amountValue.split(adornment)
  const decimalValue = symbolOnLeft && parsed.length > 1 ? parsed[1] : parsed[0]
  const existingDecimals = decimalValue ? decimalValue.split(decimalSeparator)[1] : TWO_SUFFIX_ZERO
  if (existingDecimals) {
    if (existingDecimals.length === 1) {
      return symbolOnLeft
        ? `${adornment}${decimalValue}${ONE_SUFFIX_ZERO}`
        : `${decimalValue}${ONE_SUFFIX_ZERO}${adornment}`
    }
    return amountValue
  }
  return symbolOnLeft
    ? `${adornment}${decimalValue}${decimalSeparator}${TWO_SUFFIX_ZERO}`
    : `${decimalValue}${decimalSeparator}${TWO_SUFFIX_ZERO}${adornment}`
}

interface FieldProps {
  name: any
  control: Control<FormType>
  setValue: UseFormSetValue<FormType>
  required: boolean | string
  currency: string
  amountValue: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate?: Validate<any, any> | Record<string, Validate<any, any>> | undefined
}
export const ControlledAmountCurrencyField: React.FC<FieldProps> = ({
  name,
  control,
  required,
  currency,
  setValue,
  amountValue,
  validate,
  ...otherProps
}) => {
  const currencyInfo = currencyFormatter.findCurrency(currency) as Currency
  const {symbol, spaceBetweenAmountAndSymbol, decimalSeparator, thousandsSeparator, symbolOnLeft} =
    currencyInfo
  const start = symbolOnLeft
  const adornment = start
    ? `${symbol}${spaceBetweenAmountAndSymbol ? ' ' : ''}`
    : `${spaceBetweenAmountAndSymbol ? ' ' : ''}${symbol}`

  return (
    <Controller
      render={({field}) => (
        <NumberFormat
          {...field}
          fixedDecimalScale
          customInput={HCInput}
          allowEmptyFormatting
          allowLeadingZeros={false}
          allowNegative={false}
          decimalSeparator={decimalSeparator}
          thousandSeparator={thousandsSeparator}
          allowedDecimalSeparators={['.', ',']}
          {...(start ? {prefix: adornment} : {suffix: adornment})}
          onBlur={() =>
            setValue('amount', addZeros(amountValue, decimalSeparator, adornment, symbolOnLeft))
          }
        />
      )}
      rules={{
        required,
        validate
      }}
      name={name}
      control={control}
      defaultValue=""
      {...otherProps}
    />
  )
}
