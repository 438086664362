import {useMutation, useQueryClient} from '@tanstack/react-query'

import {api} from '../../../../App.global'
import {BofAPaymentRequestType, CreatePaymentResponse} from '../../../../Organisms/Payments'

export const useBofaPayment = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (request: BofAPaymentRequestType) =>
      api.post<CreatePaymentResponse>('/paymentrequests', request),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries({queryKey: ['invoices']})
      }
    }
  )
}
