import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {FinalAmount} from '../PayNow.types'

interface UsePaymentCalculationsType {
  payerId?: string
  countryCode?: string
  amount?: number
  creditCardType?: string
  enabled: boolean
}

export const usePaymentCalculations = ({
  payerId,
  countryCode,
  amount,
  creditCardType,
  enabled
}: UsePaymentCalculationsType) => {
  return useQuery<FinalAmount, any>(
    ['payment-calculations', {payerId, countryCode, amount, creditCardType}],
    async () => {
      const res: AxiosResponse<FinalAmount> = await api.get(
        '/paymentRequests/calculations/finalAmount',
        {
          params: {
            payerId,
            countryCode,
            amount,
            creditCardType
          }
        }
      )
      return res.data
    },
    {
      enabled: !!payerId && !!countryCode && !!amount && !!creditCardType && enabled,
      retry: false
    }
  )
}
