import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Invoice} from '../Invoice.types'

import {AccountParams} from './useAccountParams'

export interface UseInvoiceParams {
  accountParams: AccountParams | undefined
  invoiceId: string
  invoice: Invoice
}

export const useInvoice = ({accountParams, invoiceId, invoice}: UseInvoiceParams) =>
  useQuery(
    [
      'invoice',
      {
        accountParams,
        invoiceId,
        invoice
      }
    ],
    async () => {
      if (invoice) {
        return {invoice}
      }

      const params = {
        expand: 'itemBreakdown',
        ...accountParams
      }
      const res: AxiosResponse<Invoice> = await api.get(`/invoices/${invoiceId}`, {
        params
      })
      return {invoice: res.data}
    },
    {
      enabled: Boolean(
        accountParams !== undefined &&
          (accountParams.customerId || accountParams.payerId) &&
          invoiceId
      )
    }
  )
