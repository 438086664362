import {Typography} from '@hconnect/uikit'
import {Box, Button} from '@material-ui/core'
import React from 'react'

interface Type {
  cancelAction: () => any
  confirmAction: () => any
  title: string
  cancelLabel: string
  confirmLabel: string
  show: boolean
}

export const OverlayDialog: React.FC<Type> = ({
  cancelAction,
  confirmAction,
  title,
  cancelLabel,
  confirmLabel,
  show = false
}) => {
  if (!show) {
    return null
  }
  return (
    <Box
      sx={{
        position: 'absolute',
        bgcolor: 'rgba(0, 0, 0, .54)',
        bottom: 0,
        left: 0,
        height: '100%',
        width: '100%'
      }}
      data-test-id="overlay-in-overlay"
    >
      <Box display="flex" flexDirection="column" height="100%">
        <Box flex={2} overflow="auto"></Box>
        <Box
          bgcolor="rgb(255, 255, 255)"
          flex={1}
          overflow="auto"
          display="flex"
          flexDirection="column"
        >
          <Box data-test-id="overlay-title" mt={1.75} p={2}>
            <Typography variant="body2">{title}</Typography>
          </Box>
          <Box data-test-id="overlay-actions">
            <Box
              data-test-id="overlay-buttons"
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="center"
              padding={2}
            >
              <Box mr={1}>
                <Button data-test-id="overlay-action-cancel" color="default" onClick={cancelAction}>
                  {cancelLabel}
                </Button>
              </Box>
              <Box>
                <Button
                  data-test-id="overlay-action-confirm"
                  color="primary"
                  onClick={confirmAction}
                >
                  {confirmLabel}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
