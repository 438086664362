import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Box, Link} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'
import {useDispatch} from 'react-redux'
import {v4 as uuidV4} from 'uuid'

import {
  downloadDocument,
  Entity,
  EntityPage,
  getDocumentFileName
} from '../../../../../Organisms/Documents'
import {QuantityUomToSymbolComponent} from '../../../../Order/Order.components'
import {findDocuments} from '../../../../Order/Order.utils'
import {Delivery, LinkMedia} from '../../Delivery.types'
import {BreakdownLineItem, DeliveryWithRelatedLineItem, FinanceAnalytics} from '../../Invoice.types'

interface BaseServiceSurchargeItem {
  item: BreakdownLineItem
  t: TFunction
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ServiceDescription: React.FC<BaseServiceSurchargeItem> = ({item, t}) => {
  return <Box> {item.description} </Box>
}

type ServiceQuantityType = BaseServiceSurchargeItem
export const ServiceQuantity: React.FC<ServiceQuantityType> = ({item}) => (
  <Box>
    {item.quantity} <QuantityUomToSymbolComponent uom={item.quantityUom} />
  </Box>
)

interface SurchargeRelationType extends BaseServiceSurchargeItem {
  delivery?: Delivery
  isCustomerDeliveryNumber: boolean
  analytics: FinanceAnalytics
  surchargeRelatedDeliveries?: DeliveryWithRelatedLineItem[]
}
export const SurchargeRelation: React.FC<SurchargeRelationType> = ({
  item,
  t,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delivery,
  isCustomerDeliveryNumber,
  analytics,
  surchargeRelatedDeliveries
}) => {
  const dispatch = useDispatch()
  const deliveryForSurcharge = surchargeRelatedDeliveries?.find(
    (deliveryWithLineItem) => deliveryWithLineItem?.relatedLineItem === item.relatedLineItem
  )
  const {delivery: del} = deliveryForSurcharge || {}
  if (!del) {
    return null
  }
  const {deliveryNote, proofOfDelivery} = findDocuments(del)
  const ticketLink = proofOfDelivery || deliveryNote
  const {deliveryNumber, customerDeliveryNumber} = del
  const ticketNumber = isCustomerDeliveryNumber
    ? customerDeliveryNumber || deliveryNumber
    : deliveryNumber
  const ticketBOLNumber = ticketNumber ? deliveryNumber : ''
  const documentId = ticketLink ? ticketLink.href.split('/')[1] : ''
  const fileName = ticketLink
    ? getDocumentFileName(EntityPage.invoices, Entity.delivery, ticketLink?.media, t, {
        entityNumber: ticketBOLNumber
      })
    : `${Entity.delivery}.${LinkMedia.Pdf}`
  return (
    <>
      {ticketLink ? (
        <Box>
          {ticketBOLNumber ? `${t('invoice.expand.relationDelivery')}: ` : ''}
          <Link
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              const jobId: string = uuidV4()
              trackEvent('hubDownloadSingle', {
                product: 'hub',
                jobId: jobId,
                downloadedItem: 'Invoice',
                linkToDownloadedItem: ticketLink.href,
                loadQty: item.quantity,
                loadQtyUoM: item.quantityUom,
                ...analytics
              })
              dispatch(downloadDocument(jobId, documentId, fileName))
            }}
            href="#"
          >
            {ticketBOLNumber}
          </Link>
        </Box>
      ) : (
        <Box>
          {ticketBOLNumber ? `${t('invoice.expand.relationDelivery')} ${ticketBOLNumber}` : ''}
        </Box>
      )}
    </>
  )
}
