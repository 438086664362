import {useAuth} from '@hconnect/common/authentication/context'
import React from 'react'

import {InviteCreatePage} from '../../../Organisms/Invite/Create/CreateInvitePage'

export const CreateInvitePage: React.FC = () => {
  const {decodedToken} = useAuth()
  const loggedInUserId = decodedToken?.user_id || ''
  return <InviteCreatePage loggedInUserId={loggedInUserId} />
}
