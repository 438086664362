import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Payer} from '../../../../Organisms/Payers'

export const usePayers = (payerIds: string[]) => {
  return useQuery(
    ['payers-all', {payerIds}],
    async () => {
      const res: AxiosResponse<Payer[]> = await api.get('/payers')
      return res.data.filter((payer) => payerIds.includes(payer.payerId))
    },
    {
      enabled: payerIds.length > 0
    }
  )
}
