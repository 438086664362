import {ExcludedInvoice, TotalAmountCriteria, useTotalAmount} from './useTotalAmount'
import {Invoice} from '../Invoice.types'
import { mapExcludedInvoices } from '../Invoice.utils'
import { useMemo } from 'react'

export const useExcludedInvoices = (
  selectedInvoices: Invoice[],
  totalAmountCriteria: TotalAmountCriteria
) => {
  const {data: totalAmountData} = useTotalAmount(totalAmountCriteria, false)

  const excludedInvoices = useMemo((): ExcludedInvoice[] => {
    const selectedExcludedInvoices = mapExcludedInvoices(selectedInvoices)
    return selectedExcludedInvoices.concat(totalAmountData?.excludedInvoices ?? [])
  }, [selectedInvoices, totalAmountData])

  return {
    excludedInvoices
  }
}
