export enum PaymentAnalyticsEvents {
  HUB_PAYMENT_OPENED = 'hubPaymentOpened',
  HUB_PAYMENT_POSTING = 'hubPaymentPosting',
  HUB_PAYMENT_ISSUED = 'hubPaymentIssued',
  HUB_PAYMENT_VERIFY = 'hubPaymentVerify',
  HUB_PAYMENT_VERIFY_BACK = 'hubPaymentVerifyBack',
  HUB_PAYMENT_VERIFY_CHANGE_METHOD = 'hubPaymentVerifyChangeMethod',
  HUB_PAYMENT_POSTED = 'hubPaymentPosted',
  HUB_PAYMENT_POSTED_SUCCESS = 'hubPaymentPostedSuccess',
  HUB_PAYMENT_POSTED_FAILED = 'hubPaymentPostedFailed',
  HUB_PAYMENT_AUTHORIZE_SUCCESS = 'hubPaymentAuthorizeSuccess',
  HUB_PAYMENT_AUTHORIZE_FAIL = 'hubPaymentAuthorizeFail',
  HUB_PAYMENT_AUTHORIZATION_ERROR = 'hubPaymentAuthorizationError',
  HUB_PAYMENT_SETTLEMENT_ERROR = 'hubPaymentSettlementError',
  HUB_PAYMENT_METHOD_ADDED = 'hubPaymentMethodAdded',
  HUB_PAYMENT_METHOD_EDITED = 'hubPaymentMethodEdited',
  HUB_PAYMENT_PAYMENT_METHOD_CHANGED = 'hubPaymentPaymentMethodChanged',
  HUB_PAYMENT_TOKENIZE_CREATED = 'hubPaymentTokenizeCreated',
  HUB_PAYMENT_TOKENIZE_FAILED = 'hubPaymentTokenizeFailed',
  HUB_PAYMENT_PAYER_CHANGED = 'hubPaymentPayerChanged'
}

export enum PaymentProviders {
  BOFA = 'BankOfAmerica',
  DELEGO = 'Delego'
}

export enum PaymentMethods {
  BOFA = 'BankTransfer',
  DELEGO = 'CreditCard'
}
