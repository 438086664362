import {dateFormatter} from '@hconnect/uikit'
import {Box} from '@mui/material'
import React from 'react'

import {Invoice} from '../Invoice.types'

interface Type {
  invoice: Invoice
  language: string
}
export const InvoiceDate: React.FC<Type> = ({invoice, language}) => (
  <Box>{invoice.invoiceDate ? dateFormatter(invoice.invoiceDate, language) : ''}</Box>
)
