/* eslint-disable complexity */
import {Divider, Page, TextButton, Typography, useTranslation} from '@hconnect/uikit'
import {
  Box,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Paper,
  Card,
  CardContent,
  FormHelperText
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import RefreshIcon from '@material-ui/icons/Refresh'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {Control, Controller, FieldErrorsImpl, UseFormSetValue} from 'react-hook-form'
import {Trans} from 'react-i18next'

import IconSecurePayment from '../../../../Assets/icon-secure-payment.svg'
import {useResponsiveBreakpoints} from '../../../../Molecules/Responsive.utils'
import StatusTooltip from '../../../../Molecules/StatusTooltip'
import {delegoCheckout} from '../../../../Organisms/Delego'
import {Payer, PayersDropdown} from '../../../../Organisms/Payers'
import {Company, DelegoCreditCard, PaymentOptionsActionsType} from '../../../../Organisms/Payments'
import {createMaskedCardNumber} from '../common'
import {AddCreditCard, ControlledAmountCurrencyField, ModalContainer} from '../components'
import {ControlledTextField} from '../components/ControlledTextField'
import {usePayNowStyles} from '../PayNow.styles'
import {PayNowFormState, PayNowPageDispatch} from '../PayNow.types'
import '../../../../Organisms/Delego/DelegoCustomStyle.css'
import {PaymentAnalyticsBase} from '../PayNow'

import {trackEvent} from '@hconnect/common/logging/Analytics'

import {PaymentAnalyticsEvents, PaymentMethods, PaymentProviders} from '../common/constants'

interface Props {
  amountValue: string
  decimalValue: number
  initialDecimalValue: number
  resetAmount: () => void
  control: Control<PayNowFormState>
  setValue: UseFormSetValue<PayNowFormState>
  selectedPayer: Payer | null
  setPageState: React.Dispatch<PayNowPageDispatch>
  selectedCard: DelegoCreditCard | null
  isSelectingAccount: boolean
  selectedCompany?: Company
  isSelecting: boolean
  disabledVerifyButton: boolean
  analytics: PaymentAnalyticsBase
  clearCardCache: () => PaymentOptionsActionsType
  payers: Payer[]
  setPayer: (payer: Payer) => void
  paymentMethod?: DelegoCreditCard
  errorCards: boolean
  addCardRequested: boolean
  cardsAreSimilar: boolean
  setCardsAreSimilar: React.Dispatch<React.SetStateAction<boolean>>
  surchargeValue?: string
  surchargeRatesEnabled: boolean
  invoiceNumbers?: string[]
  customerReference?: string
  errors: FieldErrorsImpl<{
    amount: string
    paymentMethodField: string
    customerReference: string
  }>
  maxLimitValidationEnabled?: boolean
  singlePaymentMaxAmount?: number
}

export const PayNowMethodClosed: React.FC<Props> = ({
  amountValue,
  decimalValue,
  initialDecimalValue,
  resetAmount,
  control,
  selectedPayer,
  setPageState,
  selectedCard,
  isSelectingAccount,
  selectedCompany,
  setValue,
  isSelecting,
  disabledVerifyButton,
  analytics,
  clearCardCache,
  payers,
  setPayer,
  addCardRequested,
  paymentMethod,
  errorCards,
  cardsAreSimilar,
  setCardsAreSimilar,
  surchargeValue,
  surchargeRatesEnabled,
  invoiceNumbers,
  customerReference,
  errors,
  maxLimitValidationEnabled = false,
  singlePaymentMaxAmount = undefined
}) => {
  const {amountFormControl, page, h2, h3, h3_warning, inputLabel, paper, invoices, invoicesError} =
    usePayNowStyles()
  const {t} = useTranslation()
  const [iFrameClosed, setIFrameClosed] = React.useState<boolean>(false)
  const origin = window.location.origin
  const listenIFrame = (event: MessageEvent) => {
    if (event.origin !== origin) {
      return
    }
    switch (event.data) {
      case 'iFrameClose':
        setIFrameClosed(true)
        break
      default:
        return
    }
  }
  ;(window as any).addEventListener('message', listenIFrame, false)
  const [modalState, setModalState] = React.useState<boolean>(false)
  const openModal = () => setModalState(true)
  const closeModal = () => {
    setModalState(false)
    clearCardCache()
    setIFrameClosed(false)
    setCardsAreSimilar(false)
  }
  const {smallScreen} = useResponsiveBreakpoints()

  const currency = selectedCompany?.defaultCurrency || 'USD'
  const isAmountCorrect = decimalValue >= initialDecimalValue
  return (
    <Page variant="sheet">
      <ModalContainer
        open={modalState}
        onClose={closeModal}
        iFrameClosed={iFrameClosed}
        closeModal={closeModal}
        payerId={selectedPayer?.payerId || ''}
        paymentMethod={paymentMethod}
        errorCards={errorCards}
        addCardRequested={addCardRequested}
        cardsAreSimilar={cardsAreSimilar}
        setCardsAreSimilar={setCardsAreSimilar}
        countryCode={selectedCompany?.countryCode || 'US'}
        analytics={analytics}
      />
      <Grid container data-test-id="pay-now-page">
        <Grid item md={7}>
          <Box
            display="flex"
            flexDirection="column"
            className={page}
            data-test-id="pay-now-container-left"
            ml={11}
          >
            <Box data-test-id="pay-now-title" display="flex" flexDirection="column">
              <Typography
                variant="h2"
                classes={{
                  root: h2
                }}
              >
                {t('lumpSumPayment.title')}
              </Typography>
              <Box mt={1.5} />
              <Typography>{t('lumpSumPayment.subtitle')}</Typography>
            </Box>
            <Box data-test-id="pay-now-amount-reference-row" mt={4} ml={-1} mr={-1}>
              <Grid container>
                <Grid item md={6}>
                  <Box
                    data-test-id="pay-now-amount-form-control"
                    display="flex"
                    flexDirection="column"
                    p={1}
                  >
                    <Box>
                      <FormControl className={amountFormControl} fullWidth>
                        <InputLabel
                          focused={false}
                          htmlFor="amount-field"
                          classes={{
                            root: inputLabel
                          }}
                        >
                          {t('lumpSumPayment.amount')}
                        </InputLabel>
                        <ControlledAmountCurrencyField
                          name="amount"
                          control={control}
                          required={true}
                          currency={currency}
                          setValue={setValue}
                          amountValue={amountValue}
                          {...(maxLimitValidationEnabled && singlePaymentMaxAmount
                            ? {
                                validate: (value) => {
                                  const tempDecimalValue = currencyFormatter.unformat(value, {
                                    code: selectedCompany?.defaultCurrency
                                  })

                                  return tempDecimalValue <= singlePaymentMaxAmount
                                }
                              }
                            : {})}
                        />
                        {errors.amount?.type === 'validate' && singlePaymentMaxAmount ? (
                          <Box mt={1.5} width="100%" data-test-id="over-limit-message">
                            <FormHelperText
                              error={errors.amount.type === 'validate'}
                              component="div"
                              style={{width: '100%', marginLeft: 0}}
                            >
                              {t('lumpSumPayment.validation.overLimit', {
                                limit: currencyFormatter.format(singlePaymentMaxAmount, {
                                  code: selectedCompany?.defaultCurrency
                                })
                              })}
                            </FormHelperText>
                          </Box>
                        ) : null}
                      </FormControl>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  {surchargeRatesEnabled ? (
                    <Box
                      data-test-id="pay-now-customer-reference"
                      display="flex"
                      flexDirection="column"
                      p={1}
                    >
                      <Box>
                        <FormControl fullWidth>
                          <Controller
                            rules={{maxLength: 50}}
                            render={({field, fieldState}) => (
                              <>
                                <ControlledTextField
                                  {...field}
                                  readOnly={false}
                                  label={t('lumpSumPayment.customerReference')}
                                  value={customerReference}
                                  useLightTheme={true}
                                  error={!!fieldState.error}
                                  inputProps={{maxLength: 50}}
                                />
                                {!!fieldState.error && (
                                  <Typography variant="caption" component="span" color="error">
                                    {t('lumpSumPayment.maxCustomerReferenceLength')}
                                  </Typography>
                                )}
                              </>
                            )}
                            control={control}
                            name="customerReference"
                          />
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
            {invoiceNumbers?.length && surchargeRatesEnabled ? (
              <Box data-test-id="pay-now-selected-invoices" mt={1}>
                <Card
                  variant="elevation"
                  elevation={2}
                  className={isAmountCorrect ? invoices : invoicesError}
                >
                  <CardContent>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                      <Typography style={{alignSelf: 'center'}}>
                        {isAmountCorrect
                          ? t('lumpSumPayment.calculationBase')
                          : t('lumpSumPayment.calculationBaseError')}
                        &nbsp;
                        <Box component="span" fontWeight={500}>
                          {invoiceNumbers?.join(', ')}
                        </Box>
                      </Typography>
                      {!isAmountCorrect ? (
                        <Button
                          data-test-id="pay-now-reset-amount-button"
                          color="primary"
                          variant="text"
                          startIcon={<RefreshIcon />}
                          onClick={resetAmount}
                        >
                          {t('lumpSumPayment.resetAmount')}
                        </Button>
                      ) : null}
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            ) : null}

            <Box
              data-test-id="pay-now-select-account-control"
              mt={9}
              display="flex"
              flexDirection="column"
            >
              <Box mb={2.5}>
                <Grid container>
                  <Grid item md={6}>
                    <Typography variant="h3" classes={{root: h3}}>
                      {t('lumpSumPayment.selectedAccount')}
                    </Typography>
                    {isSelectingAccount ? (
                      <Box mt={2.5} display="flex" flexDirection="column">
                        <Box width={364}>
                          <PayersDropdown
                            onLight
                            onChange={(value: Payer) => {
                              trackEvent(
                                PaymentAnalyticsEvents.HUB_PAYMENT_PAYER_CHANGED,
                                analytics as any
                              )
                              setPayer(value)
                            }}
                            payers={payers}
                            selectedPayerId={selectedPayer?.payerId || ''}
                          />
                        </Box>
                      </Box>
                    ) : null}
                  </Grid>
                  <Grid item md={6}>
                    {!isSelectingAccount ? (
                      <TextButton
                        data-test-id="pay-now-change-account-button"
                        onClick={() => setPageState({account: 'opened', cards: 'closed'})}
                        color="secondary"
                        variant="text"
                        startIcon={<EditIcon />}
                      >
                        {t('lumpSumPayment.changeAccount')}
                      </TextButton>
                    ) : (
                      <Box
                        border="1px solid rgb(178, 195, 201)"
                        borderRadius={3}
                        width={452}
                        pt={6}
                        pb={6}
                        pl={8}
                        pr={8}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <Box width={316}>
                          <Button
                            data-test-id="pay-now-select-account-button"
                            onClick={() => setPageState({account: 'closed', cards: 'closed'})}
                            color="primary"
                            fullWidth
                          >
                            {t('lumpSumPayment.useAccount')}
                          </Button>
                        </Box>
                        <Box width={316} mt={2}>
                          <Typography variant="body2">
                            <Trans
                              i18nKey="lumpSumPayment.accountMessage"
                              values={{
                                payerName: selectedPayer?.payerName
                              }}
                              components={[
                                <strong key={`payer-${selectedPayer?.payerId}`}>
                                  {selectedPayer?.payerName}
                                </strong>
                              ]}
                            />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
              {!isSelectingAccount ? (
                <Box data-test-id="pay-now-account-list" display="flex" flexDirection="column">
                  <Box
                    mt={2.5}
                    data-test-id="pay-now-payer-info"
                    display="flex"
                    flexDirection="column"
                  >
                    <Typography data-test-id="payer-info-payer-label">Payer Account</Typography>
                    <Box display="flex" flexDirection="row">
                      <Typography customVariant="bold" data-test-id="payer-info-payer-name">
                        {selectedPayer?.payerName || ''}
                      </Typography>
                      &nbsp;
                      <Typography variant="body2" data-test-id="payer-info-payer-number">
                        {selectedPayer?.payerNumber || ''}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
            {!isSelectingAccount ? (
              <Box
                data-test-id="pay-now-select-credit-card"
                mt={9}
                display="flex"
                flexDirection="column"
              >
                <Box mb={2.5}>
                  <Grid container>
                    <Grid item md={6}>
                      <Typography variant="h3" classes={{root: selectedCard ? h3 : h3_warning}}>
                        {selectedCard ? 'Selected payment method' : 'Add payment method'}
                      </Typography>
                    </Grid>
                    <Grid item md={6}>
                      {selectedCard ? (
                        <TextButton
                          data-test-id="pay-now-change-method-button"
                          onClick={() => setPageState({account: 'closed', cards: 'opened'})}
                          color="secondary"
                          variant="text"
                          startIcon={<EditIcon />}
                        >
                          {t('lumpSumPayment.changePayment')}
                        </TextButton>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
                {selectedCard ? (
                  <Box width={260}>
                    <Box data-test-id="pay-now-cards-list" display="flex" flexDirection="column">
                      <Box data-test-id="pay-now-payment-info">
                        <Typography data-test-id="payment-info-payment-type">
                          {t('lumpSumPayment.creditCard')}
                        </Typography>
                        <Typography data-test-id="payment-info-payment-number">
                          {t(`lumpSumPayment.${selectedCard?.cardType}`)}&nbsp;
                          {createMaskedCardNumber(selectedCard?.token)}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box mt={1.5} display="flex" flexDirection="column">
                    <AddCreditCard
                      openModal={openModal}
                      delegoCheckout={delegoCheckout}
                      country={selectedCompany?.countryCode || 'US'}
                      companyCode={selectedCompany?.companyCode}
                      analytics={analytics}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        </Grid>
        {!isSelecting ? (
          <Grid item md={1}>
            <Box height="100%" p={4} data-test-id="pay-now-container-middle">
              <Divider color="soft" orientation="vertical" />
            </Box>
          </Grid>
        ) : null}
        {!isSelecting ? (
          <Grid item md={4}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              data-test-id="pay-now-container-right"
            >
              <Box data-test-id="pay-now-verify-button" width={288}>
                <Button
                  onClick={() => {
                    trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_VERIFY, {
                      ...analytics,
                      product: 'hub',
                      analyticsId: analytics.analyticsId,
                      payerId: analytics.payerId,
                      cardType: selectedCard?.cardType || '',
                      amount: decimalValue,
                      country: analytics.country,
                      currency,
                      paymentMethod: PaymentMethods.DELEGO,
                      paymentProvider: PaymentProviders.DELEGO
                    })
                    setPageState({
                      amount: 'closed',
                      account: 'closed',
                      cards: 'closed',
                      verify: 'opened'
                    })
                  }}
                  color="primary"
                  fullWidth
                  disabled={disabledVerifyButton}
                >
                  {t('lumpSumPayment.verify')}
                </Button>
              </Box>
              <Box mt={8} display="flex" flexDirection="column" alignItems="center" width={288}>
                <Typography noWrap={false} align="center" variant="h3" classes={{root: h3}}>
                  {!decimalValue
                    ? t('lumpSumPayment.noAmountValidation')
                    : t('lumpSumPayment.amountValidated')}
                </Typography>
                {!decimalValue ? null : (
                  <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography data-test-id="pay-now-amount-deposit" variant="h1">
                      {amountValue}
                    </Typography>
                    <Box my={1} />
                    {surchargeValue && surchargeRatesEnabled ? (
                      <Typography
                        data-test-id="pay-now-amount-surcharge"
                        variant="body1"
                        color="primary"
                      >
                        &#43; {surchargeValue}
                      </Typography>
                    ) : null}
                    {surchargeValue && surchargeRatesEnabled ? (
                      <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography data-test-id="pay-now-amount-tooltip-label" variant="body2">
                          {t('lumpSumPayment.creditCardFee')}
                        </Typography>
                        <Box mx={0.25} />
                        <StatusTooltip
                          data-test-id="pay-now-amount-tooltip"
                          title={t('lumpSumPayment.creditCardFeeTooltip')}
                          placement="right"
                        >
                          <InfoIcon htmlColor="inherit" fontSize="inherit" />
                        </StatusTooltip>
                      </Box>
                    ) : null}
                  </Box>
                )}
              </Box>
              <Box data-test-id="pay-now-secure" mt={6}>
                <Paper
                  elevation={0}
                  square
                  style={{width: !smallScreen ? '100%' : ''}}
                  classes={{
                    root: paper
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    data-test-id="pay-now-paper-secure-content"
                    alignItems="center"
                    pt={3}
                    pl={6}
                    pr={6}
                  >
                    <Box data-test-id="pay-now-paper-secure-icon">
                      <img src={IconSecurePayment} height="44px" width="36px" alt="" />
                    </Box>
                    <Box data-test-id="pay-now-paper-secure-subtitle" mt={2.5}>
                      <Typography customVariant="bold" paragraph>
                        {t('lumpSumPayment.secureTextTitle')}
                      </Typography>
                    </Box>
                    <Box data-test-id="pay-now-paper-secure-text">
                      <Typography>{t('lumpSumPayment.secureTextIntro')}</Typography>
                      <Box component="span">
                        <Typography>{t('lumpSumPayment.secureTextFollowUp')}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Page>
  )
}
