import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {Button, Chip, Grid, Stack} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'

import {ExcludedInvoice} from '../hooks'
import {useExcludedInvoiceNumber} from '../hooks/useExcludedInvoiceNumber'
import {Invoice} from '../Invoice.types'
import {excludedInvoicesTotalAmount} from '../Invoice.utils'

import {PaymentDetailsAlertCard} from './PaymentDetailsAlertCard'

interface ExcludedInvoiceDetailsCardProps {
  t: TFunction
  excludedInvoices: ExcludedInvoice[]
  showExcludedInvoices: () => void
  currency: string
  language: string
  invoices: Invoice[]
}

export const ExcludedInvoiceDetailsCard: React.FC<ExcludedInvoiceDetailsCardProps> = ({
  t,
  excludedInvoices,
  showExcludedInvoices,
  currency,
  language,
  invoices
}: ExcludedInvoiceDetailsCardProps) => {
  const {invoiceNumber} = useExcludedInvoiceNumber()

  const totalAmount = excludedInvoicesTotalAmount(excludedInvoices)
  const formattedTotalAmount = currencyFormatter.format(totalAmount, {
    code: currency,
    locale: language
  })

  const chip = (invoiceId: string) => (
    <Chip
      label={invoiceId}
      sx={{backgroundColor: 'rgba(237, 195, 88, 0.20)', color: '#B66A00'}}
      data-test-id="excluded-invoice-chip"
    />
  )

  return (
    <PaymentDetailsAlertCard
      text={t('invoice.singleInvoicePayment.excludedInvoiceDetailsMessage', {
        amount: formattedTotalAmount
      })}
      data-test-id="excluded-invoice-details-alert-card"
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        sx={{marginTop: '30px', marginBottom: '12px'}}
      >
        <Grid item xs={9}>
          <Stack direction="row" gap="8px" flexWrap="wrap">
            {excludedInvoices.length > 0 ? chip(invoiceNumber(excludedInvoices[0], invoices)) : ''}
            {excludedInvoices.length > 1 ? chip(invoiceNumber(excludedInvoices[1], invoices)) : ''}
            {excludedInvoices.length > 2 ? chip(`+ ${excludedInvoices.length - 2}`) : ''}
          </Stack>
        </Grid>
        <Grid item xs={3} justifyContent="flex-end">
          <Stack justifyContent="flex-end" direction="row">
            <Button
              data-test-id="excluded-invoices-btn-view-all"
              onClick={showExcludedInvoices}
              variant="text"
              endIcon={<OpenInNewIcon sx={{fontSize: '16px !important'}} />}
              sx={{
                backgroundColor: 'transparent',
                fontSize: '12px',
                padding: '0px',
                '&:hover': {backgroundColor: 'transparent'}
              }}
            >
              {t('invoice.singleInvoicePayment.viewAllExcludedInvoices')}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </PaymentDetailsAlertCard>
  )
}
