import {connect} from 'react-redux'

import {sheetRequest} from '../../../AsyncJobQueue/AsyncJobQueue.action'
import {CheckboxIndetermiateType} from '../../../Molecules/CheckboxDropdown'
import {downloadDocument} from '../../../Organisms/Documents'
import {errorsClear, selectErrorByKey} from '../../../Organisms/Errors'
import {
  checkAllDeliveries,
  checkAllInvoices,
  loadMore,
  selectInvoices,
  setExpanded,
  setSortKey,
  toggleCheckTableRow,
  toggleSwitchMultipleDownload
} from '../../../Organisms/Invoices'
import {setGlobalResultsPerPage} from '../../../Organisms/ResultsPerPage'
import {AppState} from '../../../Root.store'

import {AccountParams} from './hooks'
import InvoicePage from './Invoice'
import {bulkDownload, fetchInvoices} from './Invoice.action'
import {
  selectDateFilter,
  setDateFilter,
  setDefaultDateFilter,
  setOpenInvoicesFilter
} from './Invoice.filters'
import {
  selectAllChecked,
  selectInvoiceRowsChecked,
  selectViewCurrent,
  selectViewCurrentInvoices
} from './Invoice.selectors'
import {BulkDownloadArgs} from './Invoice.types'

const mapStateToProps = (state: AppState) => ({
  items: selectViewCurrentInvoices(state),
  view: selectViewCurrent(state),
  expandedItems: selectInvoices(state).expandedIds,
  filters: selectInvoices(state).filters,
  defaultFilters: selectInvoices(state).defaultFilters,
  dateFilter: selectDateFilter(state),
  sortOrder: selectInvoices(state).sortOrder,
  isFetching: selectInvoices(state).isFetching,
  skip: selectInvoices(state).skip,
  limit: selectInvoices(state).limit,
  showLoadMore: selectInvoices(state).showLoadMore,
  error: selectErrorByKey(state, 'invoice'),
  isMultipleDownloadActive: selectInvoices(state).isMultipleDownloadActive,
  isAllChecked: selectAllChecked(state),
  checkedInvoiceTableRows: selectInvoiceRowsChecked(state),
  checkedDeliveryIds: selectInvoices(state).uncheckedDeliveryIds,
  totalCount: selectInvoices(state).totalCount
})

const mapDispatchToProps = (dispatch) => ({
  setDefaultDateFilter: (props) => dispatch(setDefaultDateFilter(props)),
  setDateFilter: (props) => dispatch(setDateFilter(props)),
  setOpenInvoicesFilter: (props) => dispatch(setOpenInvoicesFilter(props)),
  fetchInvoices: (params: AccountParams, isPumpServiceEnabled: boolean, analyticsId: string) =>
    dispatch(fetchInvoices(params, isPumpServiceEnabled, analyticsId)),
  setSortKey: (sortKey: string) => dispatch(setSortKey(sortKey)),
  loadMore: () => dispatch(loadMore()),
  downloadDocument: (jobId: string, documentId: string, fileName: string) =>
    dispatch(downloadDocument(jobId, documentId, fileName)),
  setExpanded: (invoiceId: string, expanded: boolean) => dispatch(setExpanded(invoiceId, expanded)),
  bulkDownload: (args?: BulkDownloadArgs) => dispatch(bulkDownload(args)),
  sheetRequest: (args) => dispatch(sheetRequest(args)),
  errorsClear: () => dispatch(errorsClear()),
  toggleIsMultipleDownloadActive: () => dispatch(toggleSwitchMultipleDownload()),
  toggleCheckTableRow: (rowId: string, checked: CheckboxIndetermiateType) =>
    dispatch(toggleCheckTableRow(rowId, checked)),
  checkAllInvoices: () => dispatch(checkAllInvoices()),
  checkAllDeliveries: () => dispatch(checkAllDeliveries()),
  setGlobalResultsPerPage: (amount: number) => dispatch(setGlobalResultsPerPage(amount))
})

// @ts-ignore FIXME
export default connect(mapStateToProps, mapDispatchToProps)(InvoicePage)
