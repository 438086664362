import CloseIcon from '@mui/icons-material/Close'
import {Box, IconButton} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {TotalAmountCriteria} from '../hooks'
import {Invoice} from '../Invoice.types'
import {useIconStyles} from '../InvoiceDetail/components'

import {DownloadOptionsCard} from './DownloadOptionsCard'
import {PaymentDetailsCard} from './PaymentDetailsCard'

interface SelectedInvoicesAsideProps {
  invoices: Invoice[]
  language: string
  t: TFunction
  close: () => void
  bulkDownload: (
    invoiceIds: string[],
    deliveryIds: string[],
    surchargeItems: {invoiceId: string; deliveryIds: string[]}[],
    combined: boolean
  ) => void
  showExcludedInvoices: () => void
  totalAmountCriteria: TotalAmountCriteria
  allInvoicesSelected: boolean
  paymentsEnabled: boolean
  payerId?: string
  payerNumber?: string
  customerId: string
  countryCode: string
  setRowsSelectable: (selectable: boolean) => void
}

export const SelectedInvoicesAside: React.FC<SelectedInvoicesAsideProps> = ({
  invoices,
  language,
  t,
  close,
  bulkDownload,
  showExcludedInvoices,
  totalAmountCriteria,
  allInvoicesSelected,
  paymentsEnabled,
  payerId,
  payerNumber,
  customerId,
  countryCode,
  setRowsSelectable
}) => {
  const {classes} = useIconStyles()
  return (
    <Box p={4}>
      <CustomHeader title={t('invoice.selectedInvoices')}>
        <IconButton
          className={classes.icon}
          size="large"
          onClick={() => close()}
          data-test-id="selected-invoices-close"
        >
          <CloseIcon />
        </IconButton>
      </CustomHeader>

      {paymentsEnabled && payerId && (
        <>
          <PaymentDetailsCard
            totalAmountCriteria={totalAmountCriteria}
            language={language}
            t={t}
            currency={invoices.length > 0 ? invoices[0].invoiceCurrency : ''}
            allInvoicesSelected={allInvoicesSelected}
            payerId={payerId}
            payerNumber={payerNumber}
            customerId={customerId}
            countryCode={countryCode}
            setRowsSelectable={setRowsSelectable}
            showExcludedInvoices={showExcludedInvoices}
            invoices={invoices}
          />
          <Box mt={3} />
        </>
      )}

      <DownloadOptionsCard selectedInvoices={invoices} bulkDownload={bulkDownload} t={t} />
    </Box>
  )
}
