import {Typography} from '@hconnect/uikit'
import {Divider} from '@material-ui/core'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import {Box, Button} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'

import {FieldValue} from '../../../../Molecules/FieldDetails'
import {Invoice} from '../../Invoices/Invoice.types'

export const InvoiceToPay: React.FC<{
  invoice: Invoice
  t: TFunction
  language: string
  showDivider: boolean
  onClick: () => void
}> = ({invoice, t, language, showDivider, onClick}) => {
  return (
    <>
      <Box display="flex">
        <Box flexGrow={1} sx={{my: 2}}>
          <FieldValue
            label={t('paymentConfirmation.upComingNext.invoice')}
            dataTestId="ticket-number-field"
            value={<Typography color="primary">{invoice.invoiceNumber}</Typography>}
            gutter
          />
          <FieldValue
            label={t('paymentConfirmation.upComingNext.amount')}
            dataTestId="ticket-number-field"
            value={
              <>
                <Typography customVariant="bold">
                  {currencyFormatter.format(invoice.invoiceGrossValue, {
                    code: invoice.invoiceCurrency,
                    locale: language
                  })}
                </Typography>
                <Typography>
                  {t('paymentConfirmation.upComingNext.invoiceTaxAmount', {
                    amount: currencyFormatter.format(invoice.invoiceTaxValue, {
                      code: invoice.invoiceCurrency,
                      locale: language
                    })
                  })}
                </Typography>
              </>
            }
          />
        </Box>
        <Box alignSelf="center">
          <Button
            startIcon={<ArrowForwardIcon />}
            variant="contained"
            onClick={onClick}
            data-test-id={`pay-now-button-${invoice.invoiceId}`}
          >
            {t('paymentConfirmation.upComingNext.payNow')}
          </Button>
        </Box>
      </Box>
      {showDivider && <Divider />}
    </>
  )
}
