import React, {useEffect} from 'react'
import {RouteComponentProps, withRouter} from 'react-router-dom'

import LegalPage from '../LegalPage'
import {Content} from '../types'

interface Props {
  error: unknown
  fetchSalesConditions: (country: string, language: string | undefined | null) => void
  isFetching: boolean
  sale_conditions: Content | null
  country: string | undefined | null
  language: string | undefined | null
}

const SalesConditionsPage: React.FC<Props & RouteComponentProps> = ({
  error,
  fetchSalesConditions,
  isFetching,
  sale_conditions,
  country,
  language
}) => {
  useEffect(() => {
    if (country) {
      fetchSalesConditions(country, language)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country, language])

  return <LegalPage content={sale_conditions?.body} error={error} isFetching={isFetching} />
}

export default withRouter(SalesConditionsPage)
