export const STATEMENTS_SWITCH_VIEW = 'STATEMENTS_SWITCH_VIEW'
export const STATEMENTS_CLEAR_VIEW = 'STATEMENTS_CLEAR_VIEW'

export enum StatementsView {
  DEFAULT = 'DEFAULT'
}

export type StatementsViews = keyof typeof StatementsView

export interface StatementsSwitchView {
  type: typeof STATEMENTS_SWITCH_VIEW
  view: StatementsViews
  clearPrevView?: boolean
}

export interface StatementsClearCurrentView {
  type: typeof STATEMENTS_CLEAR_VIEW
}

export type StatementsPageActionTypes = StatementsSwitchView | StatementsClearCurrentView

export interface StatementsPageState {
  currentView: StatementsViews
  [StatementsView.DEFAULT]: string[]
}
