import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import React from 'react'

import {api} from '../../../../App.global'
import {CreatePaymentResponse} from '../../../../Organisms/Payments'

export const useStatusPolling = (
  interval: number,
  setRefetchInterval: React.Dispatch<React.SetStateAction<number>>,
  id?: string
) => {
  return useQuery(
    ['payment-polling', id],
    async () => {
      const res: AxiosResponse<CreatePaymentResponse> = await api.get(
        // using non-null assertion operator because
        // react-query is not automatically applied that id is not undefined
        `/paymentRequests/status/${encodeURIComponent(id!)}`
      )
      return res.data
    },
    {
      enabled: !!id,
      onSuccess: (result) =>
        (result.status === 'done' || result.status === 'failed' || result.error) &&
        setRefetchInterval(0),
      onError: () => setRefetchInterval(0),
      refetchInterval: interval
    }
  )
}
