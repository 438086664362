import {TextButton, Typography} from '@hconnect/uikit'
import {Box, CircularProgress, Grid} from '@material-ui/core'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import {useIsMutating, UseMutationResult} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import classNames from 'classnames'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Dispatch} from 'redux'

import {addCardToTheStore, DelegoCreditCard} from '../../../../Organisms/Payments'
import {createMaskedCardNumber} from '../common'
import {usePayNowStyles} from '../PayNow.styles'

interface Props {
  paymentMethod: DelegoCreditCard
  dispatch: Dispatch<any>
  setEditMode: (condition: boolean) => unknown
  setIFrameClosed: (condition: boolean) => unknown
  openModal: () => void
  isSimilarCard: DelegoCreditCard | undefined
  deleteCreditCard: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    {
      card: DelegoCreditCard
    },
    unknown
  >
}
export const PaymentMethodInfo: React.FC<Props> = ({
  deleteCreditCard,
  dispatch,
  openModal,
  paymentMethod,
  setEditMode,
  setIFrameClosed,
  isSimilarCard
}) => {
  const {t} = useTranslation()
  const {small, h3_warning} = usePayNowStyles()
  const cardLoading = useIsMutating()
  const [deleteInProgress, setDeleteInProgress] = React.useState<boolean>(false)

  return (
    <Box display="flex" flexDirection="row" fontSize={40} alignItems="center">
      <CreditCardIcon htmlColor="rgb(0, 81, 109)" fontSize="inherit" />
      <Box display="flex" flexDirection="row" alignItems="flex-start" ml={1.25} mt={2}>
        <Grid container>
          <Grid item md={6}>
            <Box display="flex" flexDirection="column" style={{gap: 3}} minWidth={225}>
              <Typography customVariant="bold" variant="body2" classes={{root: small}}>
                {t('lumpSumPayment.creditCard')}
              </Typography>
              <Typography customVariant="bold" variant="body2" classes={{root: small}}>
                {t(`lumpSumPayment.${paymentMethod.cardType}`)}&nbsp;
                {createMaskedCardNumber(paymentMethod.token)}
              </Typography>
              <Typography customVariant="bold" variant="body2" classes={{root: small}}>
                {t('lumpSumPayment.expiryDate')}&nbsp;
                {paymentMethod.expiryDate.split('-').slice(0, 2).join('/')}
              </Typography>
              {isSimilarCard ? (
                <Typography
                  variant="body2"
                  classes={{root: classNames(small, h3_warning)}}
                  data-test-id={`similar-card-${paymentMethod.token}`}
                >
                  {t('lumpSumPayment.sameCardHighlight')}
                </Typography>
              ) : null}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box display="flex" flexDirection="column" ml={3.5} width="100%">
              <Typography variant="body2" classes={{root: small}}>
                {t('lumpSumPayment.cardHolder')}&nbsp;
                {paymentMethod.holderName}
              </Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <TextButton
                  data-test-id={`update-payment-method-${paymentMethod.token}`}
                  size="small"
                  color="secondary"
                  variant="text"
                  startIcon={<EditIcon />}
                  onClick={async () => {
                    await dispatch(addCardToTheStore(paymentMethod))
                    setEditMode(true)
                    setIFrameClosed(true)
                    openModal()
                  }}
                >
                  {t('lumpSumPayment.update')}
                </TextButton>
                <TextButton
                  data-test-id={`delete-payment-method-${paymentMethod.token}`}
                  size="small"
                  disabled={deleteInProgress}
                  color="secondary"
                  variant="text"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setDeleteInProgress(true)
                    return deleteCreditCard.mutate({card: paymentMethod})
                  }}
                  style={{minWidth: 120}}
                >
                  {cardLoading && deleteInProgress ? (
                    <CircularProgress size="small" style={{width: 15, height: 15}} />
                  ) : (
                    t('lumpSumPayment.delete')
                  )}
                </TextButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
