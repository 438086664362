/* eslint-disable complexity */
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Page, TextButton, Typography} from '@hconnect/uikit'
import {Box, Button, Grid, Paper, CircularProgress} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import InfoIcon from '@material-ui/icons/Info'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {Payer} from '../../../../Organisms/Payers'
import {AuthorizationResponse, Company, DelegoCreditCard} from '../../../../Organisms/Payments'
import {createMaskedCardNumber} from '../common'
import {PaymentAnalyticsEvents} from '../common/constants'
import {InComponentAlert} from '../components'
import {usePaymentCalculations} from '../hooks'
import {usePayNowStyles} from '../PayNow.styles'
import {PayNowPageDispatch} from '../PayNow.types'

interface Props {
  decimalValue?: number
  selectedPayer: Payer | null
  choosenCard?: DelegoCreditCard
  setPageState: React.Dispatch<PayNowPageDispatch>
  handleAuthorization: (surchargeAmount?: number, summary?: number) => void
  authorized: boolean
  authorization?: AuthorizationResponse
  authorizationError?: boolean
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  authorizationErrorType?: Error | string | unknown
  isSettlementError?: boolean
  isRolledBack?: boolean
  loading: boolean
  handleCloseAlert: () => void
  handleCloseSettlementError: () => void
  analytics: {
    product: 'hub'
    entryPoint: 'payNowTab' | 'invoices'
    analyticsId: string
    payerId: string
    userId: string
    country: string
    creditCardIssuer: string
    creditCardToken: string
  }
  paymentConfirmationMail?: string
  surchargeRatesEnabled: boolean
  countryCode?: string
  currency: string
  initialAmountValue: string
  company?: Company
  pollingError?: boolean
  createPaymentError?: any
  singlePaymentMaxAmount?: number
}

export const PayNowVerify: React.FC<Props> = ({
  decimalValue,
  selectedPayer,
  choosenCard,
  setPageState,
  handleAuthorization,
  handleCloseSettlementError,
  authorized,
  authorization,
  authorizationError,
  authorizationErrorType,
  isSettlementError,
  isRolledBack,
  loading,
  handleCloseAlert,
  analytics,
  paymentConfirmationMail,
  surchargeRatesEnabled,
  countryCode,
  currency,
  initialAmountValue,
  company,
  pollingError,
  createPaymentError,
  singlePaymentMaxAmount = undefined
}) => {
  const {h2, paperWide} = usePayNowStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const [amountValue, setAmountValue] = React.useState<string>(initialAmountValue)
  const [surchargeValue, setSurchargeValue] = React.useState<string | undefined>(undefined)
  const [totalValue, setTotalValue] = React.useState<string>(initialAmountValue)

  const {
    data: finalAmount,
    error: paymentCalculationsError,
    isFetching: paymentCalculationsFetching
  } = usePaymentCalculations({
    payerId: selectedPayer?.payerId,
    countryCode,
    amount: decimalValue,
    creditCardType: choosenCard?.cardType,
    enabled: surchargeRatesEnabled
  })
  const {raw, surcharge, summary} = finalAmount || {}
  const isPaymentsCalculationsLoading = paymentCalculationsFetching && surchargeRatesEnabled

  const overLimitError =
    paymentCalculationsError?.response?.data?.status === 400
      ? paymentCalculationsError?.response?.data?.detail
      : undefined

  React.useEffect(() => {
    handleCloseAlert()
  }, [])

  React.useEffect(() => {
    if (raw && surcharge && summary) {
      const amountValueTemp = currencyFormatter.format(raw, {
        code: currency,
        locale: language
      })
      const surchargeValueTemp = currencyFormatter.format(surcharge, {
        code: currency,
        locale: language
      })
      const totalValueTemp = currencyFormatter.format(summary, {
        code: currency,
        locale: language
      })
      setAmountValue(amountValueTemp)
      setSurchargeValue(surchargeValueTemp)
      setTotalValue(totalValueTemp)
    }
  }, [raw, surcharge, summary])

  return (
    <Page variant="sheet" data-test-id="pay-now-page-verify">
      <div
        id="emptydelegohost"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '0px',
          width: 0,
          display: 'none',
          border: 'none'
        }}
      ></div>
      <Box
        ml={11}
        data-test-id="pay-now-verify-container"
        display="flex"
        flexDirection="column"
        minHeight={766}
      >
        <Box data-test-id="pay-now-verify-back-button">
          <TextButton
            onClick={() => {
              trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_VERIFY_BACK, analytics)
              handleCloseSettlementError()
              setPageState({
                amount: 'opened',
                account: 'closed',
                cards: 'closed',
                verify: 'closed',
                confirmation: 'closed'
              })
            }}
            variant="text"
            startIcon={<KeyboardBackspaceIcon />}
          >
            {t('lumpSumPayment.back')}
          </TextButton>
        </Box>
        <Box mt={3.375} data-test-id="pay-now-verify-title">
          <Typography
            variant="h2"
            classes={{
              root: h2
            }}
          >
            {t('lumpSumPayment.verifyTitle')}
          </Typography>
        </Box>
        {paymentCalculationsError && surchargeRatesEnabled ? (
          <InComponentAlert
            onClose={handleCloseAlert}
            t={t}
            analytics={analytics}
            message={
              overLimitError && singlePaymentMaxAmount
                ? t('lumpSumPayment.validation.overLimit', {
                    limit: currencyFormatter.format(singlePaymentMaxAmount, {
                      code: currency,
                      locale: language
                    })
                  })
                : undefined
            }
          />
        ) : null}
        {authorizationError ? (
          <InComponentAlert
            authorizationErrorType={authorizationErrorType}
            onClose={handleCloseAlert}
            t={t}
            analytics={analytics}
          />
        ) : isSettlementError || createPaymentError ? (
          <InComponentAlert
            hasSettlementFailed={isSettlementError}
            isRolledBack={isRolledBack}
            onClose={handleCloseSettlementError}
            t={t}
            analytics={analytics}
          />
        ) : null}
        <Box mt={3.75} pr={11} data-test-id="pay-now-verify-paper">
          <Paper elevation={0} classes={{root: paperWide}}>
            <Box data-test-id="pay-now-overview-grid-verify">
              <Grid container>
                <Grid item md={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    p={3.75}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography customColor="textPrimarySoft" variant="body1">
                        {t('lumpSumPayment.amount')}
                      </Typography>
                      <Box my={0.25} />
                      <Typography customVariant="bold" data-test-id="amount-verify">
                        {!decimalValue ? t('lumpSumPayment.noSpecifiedAmount') : amountValue}
                      </Typography>
                      <Box my={0.25} />
                      {surchargeValue && surchargeRatesEnabled ? (
                        <Typography
                          data-test-id="pay-now-amount-surcharge"
                          variant="body2"
                          color="primary"
                        >
                          &#43; {surchargeValue}
                        </Typography>
                      ) : null}
                      <Box my={0.25} />
                      {surchargeValue && surchargeRatesEnabled ? (
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          color="#628390"
                          fontSize="12px"
                        >
                          <InfoIcon color="inherit" fontSize="inherit" />
                          <Box mx={0.25} />
                          <Typography
                            data-test-id="pay-now-verify-surcharge-tooltip-label"
                            variant="caption"
                            customColor="textPrimarySoft"
                          >
                            {t('lumpSumPayment.creditCardFeeTooltip')}
                          </Typography>
                        </Box>
                      ) : null}
                    </Box>
                    <Box display="flex" flexDirection="column" mt={3}>
                      <Typography customColor="textPrimarySoft" variant="body1">
                        {t('lumpSumPayment.payerAccount')}
                      </Typography>
                      <Box my={0.25} />
                      <Typography customVariant="bold" data-test-id="payer-verify">
                        {selectedPayer?.payerName}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={8}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    p={3.75}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography customColor="textPrimarySoft" variant="body1">
                        {t('lumpSumPayment.creditCard')}
                      </Typography>
                      <Box my={0.25} />
                      <Typography customVariant="bold" data-test-id="card-verify">
                        {choosenCard?.cardType} {createMaskedCardNumber(choosenCard?.token)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
        <Box
          data-test-id="pay-now-verify-buttons"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3.75}
          pr={11}
        >
          <TextButton
            data-test-id="pay-now-change-method-button"
            onClick={() => {
              trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_VERIFY_CHANGE_METHOD, analytics)
              handleCloseSettlementError()
              setPageState({
                account: 'closed',
                cards: 'opened',
                verify: 'closed',
                confirmation: 'closed'
              })
            }}
            color="secondary"
            variant="text"
            startIcon={<EditIcon />}
            style={{marginBottom: 'auto'}}
          >
            {t('lumpSumPayment.changePayment')}
          </TextButton>
          <Box minWidth={420} width="min-content">
            <Box minWidth={191}>
              <Button
                data-test-id="pay-now-authorize-button"
                color="primary"
                fullWidth
                onClick={() => handleAuthorization(surcharge, summary)}
                disabled={
                  authorizationError ||
                  loading ||
                  isPaymentsCalculationsLoading ||
                  (surchargeRatesEnabled && !!paymentCalculationsError)
                }
              >
                {!pollingError &&
                !createPaymentError &&
                (loading || isPaymentsCalculationsLoading) ? (
                  <CircularProgress size="small" style={{width: 18, height: 18}} />
                ) : (
                  <>{t('lumpSumPayment.pay', {amount: totalValue})}</>
                )}
              </Button>
              {authorized &&
              authorization &&
              authorization.authorized === false &&
              authorization.responseDescription ? (
                <Typography variant="caption" color="error">
                  {authorization?.responseDescription || 'Unknown error'}
                </Typography>
              ) : null}
            </Box>
            <Box mt={3.5} data-test-id="pay-now-disclaimer">
              <Typography variant="caption" color="secondary">
                {t('lumpSumPayment.disclaimer', {
                  buttonLabel: t('lumpSumPayment.pay', {amount: totalValue}).toUpperCase(),
                  emailAddress: paymentConfirmationMail || '',
                  companyName: company?.companyName || ''
                })}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Page>
  )
}
