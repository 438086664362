import {MdRenderer, Page, Subheader, TitleNav} from '@hconnect/uikit'
import {CircularProgress, Grid} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useQueryParam} from '../../Hooks/useQueryParam'
import NotYetAvailable from '../Error/NotYetAvailable'

interface LegalPageType {
  error: unknown
  isFetching: boolean
  content?: string | null
}

const useStyles = makeStyles((theme) => ({
  title: {
    margin: `${theme.spacing(4)}px 0`
  },
  content: {
    margin: `${theme.spacing(2)}px 0`
  }
}))

const LegalPage: React.FC<LegalPageType> = ({error, isFetching, content}) => {
  const classes = useStyles()
  const {t} = useTranslation()
  const queryParam = useQueryParam()
  const noNav = queryParam.has('noNav') ? queryParam.get('noNav') === 'true' : false

  if (isFetching) {
    return <CircularProgress data-test-id="legal-page-loader" />
  }

  if (!content || error) {
    return <NotYetAvailable />
  }

  const goBack = () => window.history.back()

  return (
    <>
      <Subheader>
        {!noNav ? <TitleNav aria-label="Back button" title={t('back')} onClick={goBack} /> : null}
      </Subheader>
      <Page variant="sheet">
        <Grid container spacing={8}>
          <Grid item xs />
          <Grid item xs={8}>
            <MdRenderer styles={classes} body={content} />
          </Grid>
          <Grid item xs />
        </Grid>
      </Page>
    </>
  )
}

export default LegalPage
