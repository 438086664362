import {TextButton} from '@hconnect/uikit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Box, Card, CardContent, Grid} from '@mui/material'
import classNames from 'classnames'
import {TFunction} from 'i18next'
import React, {useState} from 'react'

import {FieldValue} from '../../../../../Molecules/FieldDetails'
import {Features} from '../../../../../Organisms/Features'
import {Delivery} from '../../Delivery.types'
import {
  AsideToggleTypes,
  BreakdownLineItem,
  DeliveryWithRelatedLineItem,
  FinanceAnalytics
} from '../../Invoice.types'
import {findDeliveryFromRelatedSurcharge} from '../../Invoice.utils'

import {useDeliveryLineItemStyles} from './styles'
import {ServiceDescription, ServiceQuantity, SurchargeRelation} from './SurchargeServiceFields'

interface BaseServiceSurchargeItemType {
  item: BreakdownLineItem
  viewAll: boolean
  analytics: FinanceAnalytics
  toggles: AsideToggleTypes
  t: TFunction
}
interface SurchargeItemCardType extends BaseServiceSurchargeItemType {
  deliveries: Delivery[]
  surchargeRelatedDeliveries?: DeliveryWithRelatedLineItem[]
}
export const SurchargeItemCard: React.FC<SurchargeItemCardType> = ({
  item,
  viewAll,
  toggles,
  analytics,
  t,
  deliveries,
  surchargeRelatedDeliveries
}) => {
  const {classes} = useDeliveryLineItemStyles()
  const delivery = findDeliveryFromRelatedSurcharge(deliveries, item)
  return (
    <Card
      variant="outlined"
      className={classNames([
        classes.card,
        {
          [classes.linearOverlay]: !viewAll
        }
      ])}
      style={{backgroundColor: '#f7f7f7'}}
    >
      <CardContent style={{paddingBottom: 16}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Grid container>
            <Grid item xs={6}>
              <Box data-test-id="surcharge">
                <FieldValue
                  label={t('invoice.expand.surchargeDescription')}
                  dataTestId="surcharge-description"
                  value={<ServiceDescription item={item} t={t} />}
                  gutter
                />
              </Box>
            </Grid>
            <Features name="SurchargeServiceQuantity">
              <Grid item xs={6}>
                <Box data-test-id="surcharge-quantity">
                  <FieldValue
                    label={t('invoice.expand.quantity')}
                    dataTestId="surcharge-quantity-field"
                    value={<ServiceQuantity item={item} t={t} />}
                  />
                </Box>
              </Grid>
            </Features>
            <Features name="SurchargeRelation">
              <Grid item xs={6}>
                <Box data-test-id="surcharge-relation">
                  <FieldValue
                    label={t('invoice.expand.relation')}
                    dataTestId="surcharge-relation-field"
                    value={
                      <SurchargeRelation
                        delivery={delivery}
                        surchargeRelatedDeliveries={surchargeRelatedDeliveries}
                        t={t}
                        isCustomerDeliveryNumber={toggles.isCustomerDeliveryNumber}
                        analytics={analytics}
                        item={item}
                      />
                    }
                  />
                </Box>
              </Grid>
            </Features>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}
type ServiceItemCardType = BaseServiceSurchargeItemType
export const ServiceItemCard: React.FC<ServiceItemCardType> = ({
  item,
  viewAll,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toggles,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  analytics,
  t
}) => {
  const {classes} = useDeliveryLineItemStyles()
  return (
    <Card
      variant="outlined"
      className={classNames([
        classes.card,
        {
          [classes.linearOverlay]: !viewAll
        }
      ])}
      style={{backgroundColor: '#f7f7f7'}}
    >
      <CardContent style={{paddingBottom: 16}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Grid container>
            <Grid item xs={6}>
              <Box data-test-id="service">
                <FieldValue
                  label={t('invoice.expand.serviceDescription')}
                  dataTestId="service-description"
                  value={<ServiceDescription item={item} t={t} />}
                  gutter
                />
              </Box>
            </Grid>
            <Features name="SurchargeServiceQuantity">
              <Grid item xs={6}>
                <Box data-test-id="service-quantity">
                  <FieldValue
                    label={t('invoice.expand.quantity')}
                    dataTestId="service-quantity-field"
                    value={<ServiceQuantity item={item} t={t} />}
                  />
                </Box>
              </Grid>
            </Features>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

interface SurchargeServiceItemsCardListType {
  t: TFunction
  items: BreakdownLineItem[]
  analytics: FinanceAnalytics
  toggles: AsideToggleTypes
  viewAll: boolean
  deliveries: Delivery[]
  type: 'surcharges' | 'services'
  surchargeRelatedDeliveries?: DeliveryWithRelatedLineItem[]
}
export const SurchargeServiceItemsCardList: React.FC<SurchargeServiceItemsCardListType> = ({
  t,
  items,
  toggles,
  analytics,
  viewAll,
  deliveries,
  type,
  surchargeRelatedDeliveries
}) => {
  return (
    <Box data-test-id="surcharges-services-list" my={1.75}>
      {items.map((item) =>
        type === 'services' ? (
          <ServiceItemCard
            key={`service-${item.itemNumber}`}
            item={item}
            viewAll={viewAll}
            toggles={toggles}
            analytics={analytics}
            t={t}
          />
        ) : (
          <SurchargeItemCard
            key={`surcharge-${item.itemNumber}`}
            item={item}
            viewAll={viewAll}
            toggles={toggles}
            analytics={analytics}
            deliveries={deliveries}
            surchargeRelatedDeliveries={surchargeRelatedDeliveries}
            t={t}
          />
        )
      )}
    </Box>
  )
}
interface ListType {
  items: BreakdownLineItem[]
  t: TFunction
  analytics: FinanceAnalytics
  toggles: AsideToggleTypes
  deliveries: Delivery[]
  type: 'services' | 'surcharges'
  surchargeRelatedDeliveries?: DeliveryWithRelatedLineItem[]
}
export const ServiceSurchargeItemsList: React.FC<ListType> = ({
  items,
  t,
  analytics,
  toggles,
  deliveries,
  type,
  surchargeRelatedDeliveries
}) => {
  const [viewAll, setViewAll] = useState<boolean>(items?.length === 1)

  return (
    <Box display="flex" flexDirection="column" pt={1}>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            dataTestId="service-list-nr-items"
            label={t(`invoice.expand.${type}`)}
            value={t(`invoice.expand.numberOf${type}`, {
              numberOfItems: items.length
            })}
          />
        </Grid>
        <Grid item xs={6}>
          {items.length > 1 && (
            <TextButton
              endIcon={<ArrowDropDownIcon />}
              data-test-id="deliveries-view-all"
              color="secondary"
              variant="text"
              onClick={() => setViewAll(!viewAll)}
            >
              {t('order.orderDetails.viewAllDeliveries', {
                numberOfDeliveries: items.length
              })}
            </TextButton>
          )}
        </Grid>
      </Grid>
      <SurchargeServiceItemsCardList
        t={t}
        toggles={toggles}
        analytics={analytics}
        items={viewAll ? items : items.slice(0, 1)}
        viewAll={viewAll || items?.length === 1}
        deliveries={deliveries}
        surchargeRelatedDeliveries={surchargeRelatedDeliveries}
        type={type}
      />
    </Box>
  )
}
