import {withRoute} from '@hconnect/uikit'

import LegalDocumentPages from './LegalDocumentPages'

export const ROUTE = '/terms'
const TermsPage = () => <LegalDocumentPages legalDocumentType="tc" />

export default withRoute({
  path: ROUTE,
  label: 'Terms',
  hideInMainNav: true,
  routeProps: {exact: true}
})(TermsPage)
