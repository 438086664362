import React from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {ReceiveInvite} from '../../../Organisms/Invite/Receive/ReceiveInvitePage'
import {fetchPermissions} from '../../../Permissions/permissions.actions'
import {AppState} from '../../../Root.store'

import {fetchCustomers} from './../../../Organisms/Customers'
import {useFeaturesState} from './../../../Organisms/Features'

export const ReceiveInvitePage: React.FC = () => {
  const loggedInUserId = useSelector<AppState, string>(
    (state) => state.userProfile.userProfile?.user_id || ''
  )

  const userCountry = useSelector((state: AppState) => state.userProfile.userProfile?.country || '')

  const dispatch = useDispatch()
  const {getFeature} = useFeaturesState()
  const useHaulierView = getFeature('UseHaulierView')

  const handleFetchPermissions = () => {
    dispatch(fetchPermissions(loggedInUserId))
  }

  const handleFetchCustomers = () => {
    dispatch(fetchCustomers(useHaulierView,loggedInUserId))
  }

  return (
    <ReceiveInvite
      onFetchPermissions={handleFetchPermissions}
      onFetchCustomers={handleFetchCustomers}
      userId={loggedInUserId}
      userCountryId={userCountry}
    />
  )
}
