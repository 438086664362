import {TableColumnType} from '@hconnect/uikit'

import {
  InvoiceAmountValue,
  InvoiceDate,
  InvoiceNumber,
  InvoiceStatus,
  InvoiceType as InvoiceTypeComponent
} from './components'
import {FinanceAnalytics, Invoice} from './Invoice.types'

interface InvoiceColumnsType {
  t: any
  downloadDocument: (jobId: string, documentId: string, fileName: string) => void
  language: string
  isCustomerInvoiceNumber: boolean
  analytics: FinanceAnalytics
  isInvoiceStatusEnabled: boolean
}
export const InvoiceColumns = ({
  t,
  downloadDocument,
  language,
  isCustomerInvoiceNumber,
  analytics,
  isInvoiceStatusEnabled
}: InvoiceColumnsType) => {
  const columnsPool: {[key: string]: TableColumnType} = {
    invoiceDate: {
      field: 'invoiceDate',
      headerName: t('invoice.date'),
      sortable: true,
      flex: 2,
      renderCell: (item: Invoice) => <InvoiceDate invoice={item} language={language} />
    },
    invoiceNumber: {
      field: 'invoiceNumber',
      headerName: t('invoice.number'),
      flex: 3,
      renderCell: (item: Invoice) => (
        <InvoiceNumber
          invoice={item}
          t={t}
          isCustomerInvoiceNumber={isCustomerInvoiceNumber}
          downloadDocument={downloadDocument}
          analytics={analytics}
        />
      )
    },
    invoiceType: {
      field: 'invoiceType',
      headerName: t('invoice.type'),
      align: 'left',
      flex: 3,
      renderCell: (item: Invoice) => <InvoiceTypeComponent invoice={item} t={t} />
    },
    invoiceNetValue: {
      field: 'invoiceNetValue',
      headerName: t('invoice.netvalue'),
      headerAlign: 'right',
      align: 'right',
      flex: 2,
      renderCell: (item: Invoice) => (
        <InvoiceAmountValue
          amount={item.invoiceNetValue}
          currency={item.invoiceCurrency}
          language={language}
        />
      )
    },
    invoiceGrossValue: {
      field: 'invoiceGrossValue',
      headerName: t('invoice.grossValue'),
      headerAlign: 'right',
      align: 'right',
      flex: 2,
      renderCell: (item: Invoice) => (
        <InvoiceAmountValue
          amount={item.invoiceGrossValue}
          currency={item.invoiceCurrency}
          language={language}
        />
      )
    },
    invoiceStatus: {
      field: 'invoiceStatus',
      headerName: t('invoice.status'),
      headerAlign: 'center',
      align: 'center',
      flex: 2,
      renderCell: (item: Invoice) => <InvoiceStatus invoice={item} t={t} />
    }
  }

  return [
    columnsPool.invoiceDate,
    columnsPool.invoiceNumber,
    columnsPool.invoiceType,
    columnsPool.invoiceNetValue,
    columnsPool.invoiceGrossValue,
    ...(isInvoiceStatusEnabled ? [columnsPool.invoiceStatus] : [])
  ]
}
