import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Invoice} from '../../Invoices/Invoice.types'

export interface UseInvoicesParams {
  customerId?: string
  payerId?: string
  limit: number
  startDate: string
  endDate: string
  expand: string
  status: string
}

export const useInvoices = (params: UseInvoicesParams) =>
  useQuery(
    ['invoices', {params}],
    async () => {
      const res: AxiosResponse<Invoice[]> = await api.get('/invoices', {
        params
      })
      return {invoices: res.data}
    },
    {
      enabled: Boolean(params.customerId || params.payerId)
    }
  )
