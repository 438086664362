import {TextButton} from '@hconnect/uikit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Box, Card, CardContent, Grid} from '@mui/material'
import classNames from 'classnames'
import currencyFormatter from 'currency-formatter'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {FieldValue} from '../../../../../Molecules/FieldDetails'
import {LineItem} from '../../Invoice.types'

import {useLineItemListStyles} from './styles'

interface LineItemCardProps {
  lineItem: LineItem
  viewAll: boolean
  currency: string | undefined
}
export const LineItemCard: React.FC<LineItemCardProps> = ({lineItem, currency, viewAll}) => {
  const {classes} = useLineItemListStyles()

  const {netAmount, taxAmount, grossAmount} = lineItem
  const {
    t,
    i18n: {language}
  } = useTranslation()

  return (
    <Card
      variant="outlined"
      className={classNames([
        classes.card,
        {
          [classes.linearOverlay]: !viewAll
        }
      ])}
      style={{backgroundColor: '#f7f7f7'}}
    >
      <CardContent style={{paddingBottom: 16}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Grid container data-test-id="line-item-card">
            <Grid container justifyContent="right">
              <FieldValue label={t('invoice.lineItemCard.netAmount')} dataTestId="net-amount">
                <div className={classes.alignRight}>{`${currencyFormatter.format(netAmount, {
                  code: currency,
                  locale: language
                })}`}</div>
              </FieldValue>
            </Grid>
            <Grid container justifyContent="right">
              <FieldValue label={t('invoice.lineItemCard.taxAmount')} dataTestId="tax-amount">
                <div className={classes.alignRight}>{`${currencyFormatter.format(taxAmount, {
                  code: currency,
                  locale: language
                })}`}</div>
              </FieldValue>
            </Grid>
            <Grid container justifyContent="right">
              <FieldValue label={t('invoice.lineItemCard.grossAmount')} dataTestId="gross-amount">
                <div className={classes.alignRight}>{`${currencyFormatter.format(grossAmount || 0, {
                  code: currency,
                  locale: language
                })}`}</div>
              </FieldValue>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

interface LineItemCardListProps {
  lineItems: LineItem[]
  viewAll?: boolean
  currency: string | undefined
}
export const LineItemCardList: React.FC<LineItemCardListProps> = ({
  lineItems,
  currency,
  viewAll = false
}) => {
  return (
    <Box data-test-id="credit-debit-note-list" my={1.75}>
      {lineItems.map((lineItem, index) => (
        <LineItemCard
          key={lineItem.itemNumber || index}
          lineItem={lineItem}
          viewAll={viewAll}
          currency={currency}
        />
      ))}
    </Box>
  )
}

interface LineItemListProp {
  lineItems: LineItem[]
  currency: string | undefined
}
export const LineItemList: React.FC<LineItemListProp> = ({lineItems, currency}) => {
  const {t} = useTranslation()
  const [viewAll, setViewAll] = useState<boolean>(lineItems?.length === 1)

  return (
    <Box display="flex" flexDirection="column" pt={1}>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            dataTestId="line-items-for-credit-debit-invoice"
            label={t('invoice.lineItemCard.lineItems')}
            value={t('invoice.lineItemCard.numberOfLineItems', {
              numberOfLineItems: lineItems.length
            })}
          />
        </Grid>
        <Grid item xs={6}>
          {lineItems.length > 1 && (
            <TextButton
              endIcon={<ArrowDropDownIcon />}
              data-test-id="deliveries-view-all"
              color="secondary"
              variant="text"
              onClick={() => setViewAll(!viewAll)}
            >
              {t('invoice.lineItemCard.viewAllLineItems', {
                numberOfLineItems: lineItems.length
              })}
            </TextButton>
          )}
        </Grid>
      </Grid>
      <LineItemCardList
        lineItems={viewAll ? lineItems : lineItems.slice(0, 1)}
        viewAll={viewAll}
        currency={currency}
      />
    </Box>
  )
}
