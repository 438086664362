export const INVOICES_SWITCH_VIEW = 'INVOICES/SWITCH_VIEW'
export const INVOICES_CLEAR_VIEW = 'INVOICES/CLEAR_VIEW'

export enum LookupSelectItem {
  INVOICE = 'INVOICE',
  TICKET = 'TICKET',
  BOL = 'BOL',
  PO = 'PO'
}

export enum LookupEntityParam {
  INVOICE = 'invoiceNumber',
  TICKET = 'invoiceId',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  BOL = 'invoiceId',
  PO = 'poNumber'
}

export enum InvoiceView {
  DEFAULT = 'DEFAULT',
  LOOKUP = 'LOOKUP'
}

export type InvoiceViews = keyof typeof InvoiceView
export type LookupSelectItems = keyof typeof LookupSelectItem

export interface SwitchView {
  type: typeof INVOICES_SWITCH_VIEW
  view: InvoiceViews
  clearPrevView: any
}

export interface ClearCurrentView {
  type: typeof INVOICES_CLEAR_VIEW
}

export type InvoicePageActionTypes = SwitchView | ClearCurrentView

export interface InvoicePageState {
  currentView: InvoiceViews
  [InvoiceView.DEFAULT]: string[]
  [InvoiceView.LOOKUP]: string[]
}
