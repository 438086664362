import {withRoute} from '@hconnect/uikit'

import LegalDocumentPages from './LegalDocumentPages'

export const ROUTE = '/disclaimer'
const DisclaimerPage = () => <LegalDocumentPages legalDocumentType="imprint" />

export default withRoute({
  path: ROUTE,
  label: 'Disclaimer',
  hideInMainNav: true,
  routeProps: {exact: true}
})(DisclaimerPage)
