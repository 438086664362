import {useQuery} from '@tanstack/react-query'
import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Statement} from '../Statements.types'
export interface UseStatementsType {
  payerId: string | undefined
  startDate: string
  endDate: string
  sortedBy: string
  enabled: boolean
}
export const useStatements = ({
  payerId,
  startDate,
  endDate,
  sortedBy,
  enabled
}: UseStatementsType) => {
  return useQuery(
    ['statements', {payerId, startDate, endDate, sortedBy}],
    async () => {
      const params = {
        payerId,
        startDate,
        endDate,
        sortedBy
      }
      const requestConfig: AxiosRequestConfig = {
        params
      }
      const res: AxiosResponse<Statement[]> = await api.get('/statements', requestConfig)
      return {
        total: res.headers['total-count'] ? +res.headers['total-count'] : 0,
        statements: res.data
      }
    },
    {
      enabled: !!payerId && !!startDate && !!endDate && !!sortedBy && enabled
    }
  )
}
