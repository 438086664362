import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {PaymentOption} from '../../../../Organisms/Payments'

export const usePaymentOptions = (payerId: string | undefined, countryCode?: string | null) => {
  return useQuery(
    ['payment-options', {payerId}],
    async () => {
      const res: AxiosResponse<PaymentOption> = await api.get('/paymentRequests/options', {
        params: {
          payerId,
          countryCode
        }
      })
      return res.data
    },
    {
      enabled: !!payerId && !!countryCode
    }
  )
}
