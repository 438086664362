import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
  invoiceIncluded: {
    '&:not(:hover)': {
      background: 'rgba(237, 195, 88, 0.20) !important'
    }
  },
  invoiceExcluded: {
    '&:not(:hover)': {
      background: 'rgba(1, 132, 68, 0.20) !important'
    }
  },
  invoiceIncludedAside: {
    background: 'rgba(237, 195, 88, 0.20)'
  },
  invoiceExcludedAside: {
    background: 'rgba(1, 132, 68, 0.20)'
  }
}))
