import {Page, TextButton, Typography, useTranslation} from '@hconnect/uikit'
import {Box, Button, Grid, FormControlLabel, Radio, RadioGroup} from '@material-ui/core'
import PlusIcon from '@material-ui/icons/Add'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import {UseMutationResult} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'
import React from 'react'
import {Control, Controller} from 'react-hook-form'
import {Trans} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {delegoCheckout} from '../../../../Organisms/Delego'
import {Payer} from '../../../../Organisms/Payers'
import {
  DelegoCreditCard,
  PaymentOption,
  PaymentOptionsActionsType
} from '../../../../Organisms/Payments'
import {createMaskedCardNumber, isCardSimilarToOthers} from '../common'
import {usePayNowStyles} from '../PayNow.styles'
import {PayNowFormState, PayNowPageDispatch} from '../PayNow.types'

import '../../../../Organisms/Delego/DelegoCustomStyle.css'
import {AddCreditCard, ModalContainer, PaymentMethodInfo} from '../components'

import {trackEvent} from '@hconnect/common/logging/Analytics'

import {PaymentAnalyticsEvents} from '../common/constants'

interface Props {
  amountValue: string
  decimalValue: number
  control: Control<PayNowFormState>
  options?: PaymentOption
  selectedPayer: Payer | null
  setPageState: React.Dispatch<PayNowPageDispatch>
  paymentMethod: string
  choosenCard?: DelegoCreditCard
  deleteCreditCard: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    {
      card: DelegoCreditCard
    },
    unknown
  >
  selectedCard: DelegoCreditCard | null
  paymentMethodAdded?: DelegoCreditCard
  errorCards: boolean
  addCardRequested: boolean
  cardsAreSimilar: boolean
  setCardsAreSimilar: React.Dispatch<React.SetStateAction<boolean>>
  clearCardCache: () => PaymentOptionsActionsType
  country: string
  companyCode?: string
  analytics: any
}
export const PayNowMethodOpen: React.FC<Props> = ({
  amountValue,
  decimalValue,
  choosenCard,
  control,
  options,
  paymentMethod,
  selectedPayer,
  setPageState,
  deleteCreditCard,
  selectedCard,
  clearCardCache,
  addCardRequested,
  paymentMethodAdded,
  errorCards,
  cardsAreSimilar,
  setCardsAreSimilar,
  country,
  companyCode,
  analytics
}) => {
  const dispatch = useDispatch()
  const [iFrameClosed, setIFrameClosed] = React.useState<boolean>(false)
  const origin = window.location.origin
  const listenIFrame = (event: MessageEvent) => {
    if (event.origin !== origin) {
      return
    }
    switch (event.data) {
      case 'iFrameClose':
        setIFrameClosed(true)
        break
      default:
        return
    }
  }
  ;(window as any).addEventListener('message', listenIFrame, false)
  const {h2, h3, h3_warning, page, radio} = usePayNowStyles()
  const {t} = useTranslation()
  const [modalState, setModalState] = React.useState<boolean>(false)
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const openModal = () => setModalState(true)
  const handleClose = () => {
    setModalState(false)
    setIFrameClosed(false)
    clearCardCache()
    setEditMode(false)
    setCardsAreSimilar(false)
  }

  const paymentMethodAvailable = options && options.creditCards && options.creditCards.length > 0
  return (
    <Page variant="sheet" data-test-id="pay-now-change-payment-page">
      <ModalContainer
        open={modalState}
        onClose={handleClose}
        iFrameClosed={iFrameClosed}
        closeModal={handleClose}
        editMode={editMode}
        payerId={selectedPayer?.payerId || ''}
        paymentMethod={paymentMethodAdded}
        errorCards={errorCards}
        addCardRequested={addCardRequested}
        cardsAreSimilar={cardsAreSimilar}
        setCardsAreSimilar={setCardsAreSimilar}
        countryCode={country}
        analytics={analytics}
      />
      <Box
        display="flex"
        flexDirection="column"
        className={page}
        data-test-id="pay-now-container-left"
      >
        <Box ml={10.5} mb={4}>
          <TextButton
            data-test-id="pay-now-back"
            color="secondary"
            variant="text"
            startIcon={<KeyboardBackspaceIcon />}
            onClick={() => setPageState({account: 'closed', cards: 'closed', verify: 'closed'})}
          >
            {t('lumpSumPayment.back')}
          </TextButton>
        </Box>
        <Box data-test-id="pay-now-title" ml={11} display="flex" flexDirection="column">
          <Typography
            variant="h2"
            classes={{
              root: h2
            }}
          >
            {t('lumpSumPayment.title')}
          </Typography>
          <Box mt={1.5} />
          <Typography>{t('lumpSumPayment.subtitle')}</Typography>
        </Box>
        <Box data-test-id="pay-now-overview-grid" ml={11} mt={3.75}>
          <Grid container>
            <Grid item md={7}>
              <Grid container>
                <Grid item md={6}>
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column">
                      <Typography>{t('lumpSumPayment.amount')}</Typography>
                      <Typography customVariant="bold" data-test-id="amount-customer-name">
                        {!decimalValue ? t('lumpSumPayment.noSpecifiedAmount') : amountValue}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Box display="flex" flexDirection="column">
                    <Typography>{t('lumpSumPayment.payerAccount')}</Typography>
                    <Box display="flex" flexDirection="row">
                      <Typography customVariant="bold">{selectedPayer?.payerName}</Typography>
                      &nbsp;
                      <Typography
                        variant="body1"
                        color="secondary"
                        data-test-id="pay-now-overview-payer-number"
                      >
                        {selectedPayer?.payerNumber}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={5} />
          </Grid>
        </Box>
        <Box data-test-id="pay-now-payment-method-selector" ml={11} mt={7.5}>
          <Grid container>
            <Grid item md={7}>
              {paymentMethodAvailable ? (
                <Box display="flex" flexDirection="column">
                  <Box mb={2.5}>
                    <Typography variant="h3" classes={{root: h3}}>
                      {t('lumpSumPayment.whichPayment')}
                    </Typography>
                  </Box>
                  <Box ml={-1.25}>
                    <Controller
                      render={({field}) => (
                        <RadioGroup {...field} aria-label="credit-cards">
                          {options &&
                            options.creditCards.length &&
                            options.creditCards.map((paymentMethod) => {
                              return (
                                <FormControlLabel
                                  key={paymentMethod.token}
                                  value={paymentMethod.token}
                                  checked={
                                    choosenCard
                                      ? choosenCard.token === paymentMethod.token
                                      : selectedCard
                                      ? selectedCard.token === paymentMethod.token
                                      : paymentMethod.isDefault
                                  }
                                  control={
                                    <Radio
                                      data-test-id={`change-account-${paymentMethod.token}`}
                                      icon={
                                        <RadioButtonUncheckedIcon
                                          fontSize="inherit"
                                          htmlColor="rgb(0, 81, 109)"
                                        />
                                      }
                                      checkedIcon={
                                        <RadioButtonCheckedIcon
                                          fontSize="inherit"
                                          htmlColor="rgb(0, 81, 109)"
                                        />
                                      }
                                      classes={{root: radio}}
                                    />
                                  }
                                  style={{marginBottom: 40}}
                                  label={
                                    <PaymentMethodInfo
                                      isSimilarCard={isCardSimilarToOthers(
                                        options.creditCards,
                                        paymentMethod
                                      )}
                                      dispatch={dispatch}
                                      openModal={openModal}
                                      deleteCreditCard={deleteCreditCard}
                                      setEditMode={setEditMode}
                                      setIFrameClosed={setIFrameClosed}
                                      paymentMethod={paymentMethod}
                                    />
                                  }
                                />
                              )
                            })}
                        </RadioGroup>
                      )}
                      control={control}
                      name="paymentMethodField"
                    />
                  </Box>
                </Box>
              ) : (
                <Box display="flex" flexDirection="column">
                  <Box>
                    <Typography variant="h3" classes={{root: h3_warning}}>
                      Add payment method
                    </Typography>
                  </Box>
                  <Box mt={3}>
                    <AddCreditCard
                      openModal={openModal}
                      delegoCheckout={delegoCheckout}
                      country={country}
                      analytics={analytics}
                      companyCode={companyCode}
                    />
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item md={5}>
              {paymentMethodAvailable ? (
                <Box
                  mt={0.5}
                  border="1px solid rgb(178, 195, 201)"
                  borderRadius={3}
                  width={398}
                  pt={6}
                  pb={6}
                  pl={8}
                  pr={8}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box data-test-id="pay-now-choosen-card-info" width={318}>
                    <Button
                      data-test-id="pay-now-select-method-button"
                      onClick={() => {
                        trackEvent(
                          PaymentAnalyticsEvents.HUB_PAYMENT_PAYMENT_METHOD_CHANGED,
                          analytics
                        )
                        setPageState({cards: 'closed'})
                      }}
                      color="primary"
                      fullWidth
                      disabled={!paymentMethod && !selectedCard}
                    >
                      {t('lumpSumPayment.usePayment')}
                    </Button>
                  </Box>
                  <Box width={318} data-test-id="pay-now-choosen-card-info-text" mt={2.5}>
                    {choosenCard ? (
                      <Typography>
                        <Trans
                          i18nKey="lumpSumPayment.paymentMessage"
                          values={{
                            creditCard: `${t(
                              `lumpSumPayment.${choosenCard?.cardType}`
                            )} ${createMaskedCardNumber(choosenCard?.token)}`
                          }}
                          components={[
                            <b key={choosenCard?.token}>
                              `${choosenCard?.cardType} $
                              {createMaskedCardNumber(choosenCard?.token)}`
                            </b>
                          ]}
                        />
                      </Typography>
                    ) : (
                      <Typography>{t('lumpSumPayment.choosePayment')}</Typography>
                    )}
                  </Box>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
        {paymentMethodAvailable ? (
          <Box
            data-test-id="pay-now-add-payment-method"
            ml={11}
            mt={9}
            display="flex"
            flexDirection="column"
          >
            <Box mb={2.5}>
              <Typography variant="h3" classes={{root: h3}}>
                {t('lumpSumPayment.anotherPayment')}
              </Typography>
            </Box>
            <Box>
              <TextButton
                data-test-id="pay-now-add-payment-button"
                color="secondary"
                variant="text"
                startIcon={<PlusIcon />}
                onClick={async () => {
                  await openModal()
                  delegoCheckout({noDelegoUi: false, country, sorg: companyCode, analytics})
                }}
              >
                {t('lumpSumPayment.addPayment')}
              </TextButton>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Page>
  )
}
