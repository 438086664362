import {TextButton} from '@hconnect/uikit'
import {Typography} from '@material-ui/core'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Box, Card, CardContent, Grid} from '@mui/material'
import classNames from 'classnames'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'
import React, {useEffect, useState} from 'react'
import {makeStyles} from 'tss-react/mui'

import {FieldValue} from '../../../../../Molecules/FieldDetails'
import {DeliveryTicketNumber, DeliveryVehicle} from '../../../../Order/components/DeliveryFields'
import {QuantityUomToSymbolComponent} from '../../../../Order/Order.components'
import {Delivery} from '../../Delivery.types'
import {AsideToggleTypes, FinanceAnalytics} from '../../Invoice.types'

import {DeliveryPONumber} from '.'

const useStyles = makeStyles()((theme) => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(2),
    backgroundColor: '#f7f7f7'
  },
  linearOverlay: {
    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)'
  }
}))
interface InvoiceDeliveryCardProps {
  delivery: any
  id: number
  toggles: AsideToggleTypes
  analytics: FinanceAnalytics
  customerId: string
  t: TFunction
  language: string
  currency: string
  viewAll: boolean
}

const InvoiceDeliveryCard: React.FC<InvoiceDeliveryCardProps> = ({
  delivery,
  id,
  toggles,
  analytics,
  customerId,
  t,
  language,
  currency,
  viewAll
}) => {
  const {classes} = useStyles()
  const modeOfTransportLabel =
    (delivery as Delivery).modeOfTransport !== undefined
      ? (delivery as Delivery).modeOfTransport
      : 'others'
  return (
    <Card
      variant="outlined"
      className={classNames([
        classes.card,
        {
          [classes.linearOverlay]: !viewAll
        }
      ])}
      data-test-id={`invoice-delivery-${id}-card`}
    >
      <CardContent style={{paddingBottom: 16}}>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              color="primary"
              style={{textTransform: 'uppercase', fontWeight: 700, fontSize: 12, marginBottom: 16}}
            >
              {t('invoice.expand.relationDelivery')} {id}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box data-test-id="ticket-number">
              <FieldValue
                label={t('order.expand.deliveryNumber')}
                dataTestId={`invoice-delivery-${id}-ticket-number`}
                value={
                  <DeliveryTicketNumber
                    delivery={delivery as Delivery}
                    toggles={toggles as any}
                    customerId={customerId}
                    analytics={analytics}
                  />
                }
                gutter
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box data-test-id="ticket-ponumber">
              <FieldValue
                label={t('invoice.expand.orderPoNumber')}
                dataTestId={`invoice-delivery-${id}-po-number`}
                gutter
                value={<DeliveryPONumber delivery={delivery} t={t} />}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <FieldValue
              label={t(`order.expand.modeoftransport.${modeOfTransportLabel}`)}
              dataTestId={`invoice-delivery-${id}-vehicle`}
              gutter
              value={
                <DeliveryVehicle
                  delivery={delivery as Delivery}
                  customerId={customerId}
                  toggles={toggles as any}
                  analytics={analytics}
                />
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FieldValue
              label={t('invoice.expand.quantity')}
              value={
                <Box>
                  {(delivery as Delivery).loadQuantity || delivery.quantity}
                  {(delivery as Delivery).loadQuantityUom || delivery.quantityUom ? (
                    <>
                      {' '}
                      <QuantityUomToSymbolComponent
                        uom={(delivery as Delivery).loadQuantityUom || delivery.quantityUom || ''}
                      />
                    </>
                  ) : null}
                </Box>
              }
              dataTestId={`invoice-delivery-${id}-quantity`}
            />
          </Grid>
          <Grid item xs={6}>
            <FieldValue
              label={t('invoiceDetail.lineItems.expand.unitPrice')}
              value={currencyFormatter.format((delivery.unitPrice as number) || 0, {
                code: currency,
                locale: language
              })}
              dataTestId={`invoice-delivery-${id}-rate`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

interface InvoiceDeliveriesItemsListProps {
  deliveries: any[]
  toggles: AsideToggleTypes
  analytics: FinanceAnalytics
  customerId: string
  t: TFunction
  language: string
  currency: string
}

export const InvoiceDeliveriesItemsList: React.FC<InvoiceDeliveriesItemsListProps> = ({
  deliveries,
  toggles,
  analytics,
  customerId,
  t,
  language,
  currency
}) => {
  const [viewAll, setViewAll] = useState<boolean>(deliveries?.length === 1)
  useEffect(() => setViewAll(deliveries?.length === 1), [deliveries])

  if (!deliveries || deliveries.length === 0) return null

  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            label={t('order.orderDetails.deliveryItems')}
            value={t('order.orderDetails.numberOfDeliveries', {
              numberOfDeliveries: deliveries.length
            })}
            dataTestId="invoice-delivery-items-count"
            gutter
          />
        </Grid>
        <Grid item xs={6}>
          {deliveries.length > 1 && (
            <TextButton
              endIcon={<ArrowDropDownIcon />}
              data-test-id="invoice-delivery-view-all"
              color="secondary"
              variant="text"
              onClick={() => setViewAll(!viewAll)}
            >
              {t('order.orderDetails.viewAllDeliveries', {
                numberOfDeliveries: deliveries.length
              })}
            </TextButton>
          )}
        </Grid>
      </Grid>
      {deliveries.slice(0, viewAll ? deliveries.length : 1).map((delivery, i) => (
        <InvoiceDeliveryCard
          key={delivery.deliveryNumber}
          delivery={delivery}
          analytics={analytics}
          currency={currency}
          customerId={customerId}
          id={i + 1}
          language={language}
          t={t}
          toggles={toggles}
          viewAll={viewAll}
        />
      ))}
    </>
  )
}
