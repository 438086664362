import {withRouteRequiringPermission} from '../../../Molecules/PermissionBoundary'
import {PermissionTypes} from '../../../Permissions'

import {PaymentConfirmationPage} from './PaymentConfirmation'

export const ROUTE = '/paymentConfirmation'

export default withRouteRequiringPermission({
  path: ROUTE,
  hideInMainNav: true,
  label: 'Payment confirmation',
  order: 21,
  routeProps: {exact: true},
  permissions: [PermissionTypes.CHANGE_FINANCE],
  featureToggleName: 'BofaPayments',
  excludeFeatureToggleName: 'UseHaulierView'
})(PaymentConfirmationPage)
