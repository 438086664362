import React from 'react'

import {Features} from '../../../../../Organisms/Features'
import {Delivery} from '../../Delivery.types'
import {DeliveryWithRelatedLineItem, FinanceAnalytics, Invoice} from '../../Invoice.types'
import {InvoiceDeliveriesItems} from '../components/InvoiceDeliveriesItems'
import {LineItemList} from '../components/LineItemList'

interface InvoiceItemsType {
  invoice: Invoice | undefined
  surchargeRelatedDeliveries: DeliveryWithRelatedLineItem[]
  selectedRow: any
  deliveries: Delivery[] | undefined
  analytics: FinanceAnalytics
  loading?: boolean
  close: () => void
}

export const InvoiceItems: React.FC<InvoiceItemsType> = ({
  invoice,
  surchargeRelatedDeliveries,
  selectedRow,
  deliveries,
  analytics,
  close
}) => {
  return (
    <>
      <InvoiceDeliveriesItems
        item={selectedRow}
        surchargeRelatedDeliveries={surchargeRelatedDeliveries}
        deliveries={deliveries}
        invoice={invoice}
        analytics={analytics}
        close={close}
      />
      <Features name="EnableLineItemCardsForCreditAndDebitNotes">
        {invoice?.type !== 'invoice' && (
          <LineItemList
            lineItems={selectedRow?.lineItems || []}
            currency={invoice?.invoiceCurrency}
          />
        )}
      </Features>
    </>
  )
}
