import {
  ErrorText,
  PrimaryCell,
  Typography,
  ResponsiveTable,
  dateFormatter,
  TableColumnType
} from '@hconnect/uikit'
import {Box} from '@material-ui/core'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {CustomHeader} from '../../../../Molecules/CustomHeader'
import MaterialDescription from '../../../../Molecules/MaterialDescription'
import {useFeaturesState} from '../../../../Organisms/Features'
import {QuantityUomToSymbolComponent} from '../../../Order/Order.components'
import {ServiceOrSurchargeIcon} from '../components'
import {Invoice, ServicesOrSurcharge} from '../Invoice.types'

import {InvoiceItemContracts} from './components/InvoiceDetailFields'
import {formatNumber, NoCustomerReferenceKey} from './InvoiceDetails.utils'

interface InvoiceItemsTableProps {
  invoice: Invoice
  isLoading: boolean
  data: any[]
  selectedRow: any
  setSelectedRow: (row: any) => void
}
const InvoiceItemsTable: React.FC<InvoiceItemsTableProps> = ({
  invoice,
  data,
  isLoading,
  selectedRow,
  setSelectedRow
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {getFeature} = useFeaturesState()

  const hideAggregatedQuantity = getFeature('HideAggregatedQuantity')
  const servicesOrSurchargesLabel = getFeature('ServicesOrSurchargesLabel')
  const columnsPool: {[field: string]: TableColumnType} = {
    shippingDate: {
      field: 'shippingDate',
      headerName: t('invoiceDetail.lineItems.dateShipped'),
      flex: 2,
      renderCell: (item) => (item.shippingDate ? dateFormatter(item.shippingDate, language) : '-')
    },
    customerReference: {
      field: 'customerReference',
      headerName: t('invoiceDetail.lineItems.customerReference'),
      flex: 2,
      renderCell: (item) =>
        item.customerReference !== NoCustomerReferenceKey ? item.customerReference : '-'
    },
    material: {
      field: 'material',
      headerName: t('invoiceDetail.lineItems.material'),
      flex: 3,
      renderCell: (item) => {
        const {customerMaterialDescription, materialDescription} = item?.lineItems[0] || {}

        if (item.materials.size === 0) {
          return '-'
        }
        return (
          <MaterialDescription
            materialDescription={materialDescription}
            customerMaterialDescription={customerMaterialDescription}
          />
        )
      }
    },
    quantity: {
      field: 'quantity',
      headerName: t('invoiceDetail.lineItems.quantity'),
      flex: 1,
      renderCell: (item) => {
        if (!item.quantity) {
          return '-'
        }
        return (
          <>
            {formatNumber(item.quantity.loadQuantity, language)}{' '}
            <QuantityUomToSymbolComponent uom={item.quantity.loadQuantityUom} />
          </>
        )
      }
    },
    contract: {
      field: 'contract',
      headerName: t('invoiceDetail.lineItems.contract'),
      flex: 1,
      renderCell: (item) => InvoiceItemContracts(item, t)
    },
    order: {
      field: 'order',
      headerName: t('order.orderNumber'),
      flex: 1,
      renderCell: (item) => {
        if (item.orderNumbers.size === 0) {
          return (
            <>
              <PrimaryCell>{item.customerReference || '-'}</PrimaryCell>
              <Typography color="secondary" variant="subtitle1">
                -
              </Typography>
            </>
          )
        }
        if (item.orderNumbers.size === 1) {
          return (
            <>
              <PrimaryCell>{item.customerReference || '-'}</PrimaryCell>
              <Typography color="secondary" variant="subtitle1">
                {[...item.orderNumbers.values()][0]}
              </Typography>
            </>
          )
        }
        return (
          <>
            <PrimaryCell>{item.customerReference || '-'}</PrimaryCell>
            <Typography color="secondary" variant="subtitle1">
              {t('order.multipleMaterials')}
            </Typography>
          </>
        )
      }
    },
    netAmount: {
      field: 'netAmount',
      headerName: t('invoiceDetail.lineItems.netAmount'),
      flex: 2,
      renderCell: (item) => (
        <>
          {currencyFormatter.format(item.netAmount, {
            code: invoice.invoiceCurrency,
            locale: language
          })}
        </>
      )
    },
    servicesOrSurcharges: {
      field: 'servicesOrSurcharges',
      headerName: t('invoiceDetail.lineItems.charges'),
      flex: 1,
      renderCell: (item) => {
        if (item.serviceItems.length === 0 && item.surchargeItems.length === 0) {
          return null
        }
        return (
          <Box display="flex" flexDirection="column" justifyContent="flex-start">
            {item.serviceItems.length > 0 ? (
              <ServiceOrSurchargeIcon serviceOrSurcharge={ServicesOrSurcharge.Service} t={t} />
            ) : null}
            {item.surchargeItems.length > 0 ? (
              <ServiceOrSurchargeIcon
                serviceOrSurcharge={ServicesOrSurcharge.ServicesOrSurchargesOnly}
                t={t}
              />
            ) : null}
          </Box>
        )
      }
    }
  }

  const columns = [
    ...(hideAggregatedQuantity && invoice.type !== 'invoice' ? [] : [columnsPool.shippingDate]),
    columnsPool.material,
    ...(hideAggregatedQuantity && invoice.type !== 'invoice' ? [] : [columnsPool.quantity]),
    columnsPool.contract,
    ...(servicesOrSurchargesLabel ? [columnsPool.servicesOrSurcharges] : []),
    columnsPool.netAmount
  ]

  const getEmptyMessage = (loading: boolean) =>
    loading ? t('loading') : <ErrorText label={t('invoiceDetail.noData')} />

  return (
    <Box mb={6}>
      <CustomHeader title={t('invoiceDetail.itemsGroupedByMaterial')} />
      <ResponsiveTable
        columns={columns}
        loading={isLoading}
        rows={data}
        keyField="name"
        emptyMessage={getEmptyMessage(isLoading)}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSort={(e, sortKey) => {}}
        onRowClick={(row) => setSelectedRow(row)}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isRowSelectable={(row) => true}
        rowDetailsAvailable
        rowDetailsClosed={selectedRow === undefined}
        showEmptyMessage={!invoice.isFinanceAccountingInvoice}
      />
    </Box>
  )
}

export default InvoiceItemsTable
