import {dateFormatter, GridRowsProp} from '@hconnect/uikit'
import {BreakPoint} from '@hconnect/uikit/src/lib/Hooks/useBreakPoints'
import {Overlay} from '@hconnect/uikit/src/lib/Shell/Overlay'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import SaveAltIcon from '@mui/icons-material/SaveAlt'
import {Box, Card, CardContent, Grid, IconButton} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'

import {PageNames} from '../../../../common/constants'
import {ViewDetailsButton} from '../../../../Molecules/Buttons/ViewDetailsButton'
import {
  CustomerFeedbackBanner,
  CustomerFeedbackPanel
} from '../../../../Molecules/CustomerFeedbackProgramme'
import {CustomSkeleton} from '../../../../Molecules/CustomSkeleton'
import {C60Lead} from '../../../../Molecules/LeadBanners'
import {useResponsiveGridStyles} from '../../../../Molecules/Responsive.utils'
import {Features} from '../../../../Organisms/Features'
import {AppState} from '../../../../Root.store'
import {FieldValue} from '../../../Order/components'
import {AsideToggleTypes, FinanceAnalytics, Invoice} from '../Invoice.types'
import {useIconStyles} from '../InvoiceDetail/components'

import {InvoiceAmountValue} from './InvoiceAmountValue'
import {InvoiceStatus} from './InvoiceStatus'

interface InvoiceCardType {
  invoice: Invoice
  viewInvoiceDetailsPage: (event, row: Invoice) => void
  toggles: AsideToggleTypes
  download: (invoice) => void
}

export const InvoiceCard: React.FC<InvoiceCardType> = ({
  invoice,
  viewInvoiceDetailsPage,
  download,
  toggles
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const {classes} = useIconStyles()
  const invoiceNumber = (invoice: Invoice) =>
    toggles.isCustomerInvoiceNumber ? invoice.customerInvoiceNumber : invoice.invoiceNumber

  return (
    <Card
      sx={{
        marginBottom: 2
      }}
      variant="outlined"
      data-test-id={`invoice-card-${invoice.invoiceId}`}
    >
      <CardContent sx={{paddingTop: 3}}>
        <Grid container>
          <Grid item xs={6} sm={6} mb={1}>
            <InvoiceStatus invoice={invoice} t={t} />
          </Grid>
          <Grid item xs={6} sm={6} mb={1}>
            <Box display="flex" justifyContent="flex-end" alignItems="center" mt={-2}>
              <IconButton
                className={classes.icon}
                size="large"
                onClick={() => download(invoice)}
                data-test-id="invoice-full-details"
              >
                <SaveAltIcon />
              </IconButton>
              <OpenInFullIcon
                onClick={(event) =>
                  viewInvoiceDetailsPage && viewInvoiceDetailsPage(event, invoice)
                }
                data-test-id={`invoice-card-expand-icon-${invoice.invoiceId}`}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FieldValue
              dataTestId="invoice-issue-date"
              label={t(`invoiceDetail.invoiceDate.${invoice.type}`)}
              value={invoice.invoiceDate ? dateFormatter(invoice.invoiceDate, language) : undefined}
              gutter
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FieldValue
              dataTestId="invoice-number"
              label={t(`invoiceDetail.invoiceNumber.${invoice.type}`)}
              value={invoiceNumber(invoice)}
              gutter
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FieldValue
              dataTestId="invoice-type"
              label={t(`invoiceDetail.invoiceNumber.${invoice.type}`)}
              noLabel
              value={t(`invoice.types.${invoice.type}`)}
              gutter
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FieldValue
              dataTestId="invoice-net-value"
              label={t('invoice.netvalue')}
              value={
                <InvoiceAmountValue
                  amount={invoice.invoiceNetValue}
                  currency={invoice.invoiceCurrency}
                  language={language}
                />
              }
              gutter
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <FieldValue
              dataTestId="invoice-gross-value"
              label={t('invoice.grossValue')}
              value={
                <InvoiceAmountValue
                  amount={invoice.invoiceGrossValue}
                  currency={invoice.invoiceCurrency}
                  language={language}
                />
              }
              gutter
            />
          </Grid>
          <Box width="100%">
            <ViewDetailsButton
              onClick={(event) => viewInvoiceDetailsPage && viewInvoiceDetailsPage(event, invoice)}
              dataTestId={`invoice-card-button-${invoice.invoiceId}`}
              value={t('viewDetails')}
            />
          </Box>
        </Grid>
      </CardContent>
    </Card>
  )
}

interface Type {
  loading?: boolean
  rows: GridRowsProp<Invoice>
  keyField: string
  viewInvoiceDetailsPage: (event, row: Invoice) => void
  countryCode: string
  fullScreen: boolean
  customerId: string
  screenSize: BreakPoint
  analytics: FinanceAnalytics
  toggles: AsideToggleTypes
  download: (invoice: Invoice) => void
}
export const InvoicesCardList: React.FC<Type> = ({
  rows,
  loading,
  keyField,
  viewInvoiceDetailsPage,
  countryCode,
  screenSize,
  fullScreen,
  customerId,
  analytics,
  download,
  toggles
}) => {
  const [showCustomerPanel, setShowCustomerPanel] = React.useState<boolean>(false)
  const {classes: responsiveClasses} = useResponsiveGridStyles()
  const totalGrossValue = useSelector<AppState, number | undefined>(
    (state) => state.finance.invoices.totalGrossValue
  )

  if (loading) {
    return <CustomSkeleton />
  }

  const banners = (
    <>
      <Features name="C60LeadWidget">
        <C60Lead
          countryCode={countryCode}
          fullScreen={fullScreen}
          customerId={customerId}
          analytics={analytics}
          isMediumScreen={screenSize === 'md'}
          screenSize={screenSize}
          contextualC60Widget
          totalGrossValue={totalGrossValue}
          entryPoint={PageNames.FINANCE_PAGE}
        />
        <Box mt={2} />
      </Features>
      <CustomerFeedbackBanner
        setKnowMore={(val: boolean) => setShowCustomerPanel(val)}
        entryPoint={PageNames.FINANCE_PAGE}
      />
      <Box mt={2} />
    </>
  )

  return (
    <>
      <Box height="100%" width="100%">
        {rows.length === 0
          ? banners
          : rows.map((row, index) => (
              <React.Fragment key={row[keyField]}>
                <InvoiceCard
                  invoice={row}
                  viewInvoiceDetailsPage={viewInvoiceDetailsPage}
                  download={download}
                  toggles={toggles}
                />
                {index === 0 && banners}
              </React.Fragment>
            ))}
      </Box>
      <Overlay
        data-test-id="customerFeedbackBannerOverlay"
        isVisible={showCustomerPanel}
        overlayClassName={responsiveClasses.overlay}
        contentClassName={responsiveClasses.overlayContent}
      >
        <CustomerFeedbackPanel
          close={() => setShowCustomerPanel(false)}
          entryPoint={PageNames.FINANCE_PAGE}
        />
      </Overlay>
    </>
  )
}
