import {useQuery} from '@tanstack/react-query'
import {AxiosRequestConfig, AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Payment} from '../Payments.types'

export const DefaultLimit = 10

export const usePayments = (
  payerId: string | undefined,
  startDate: string,
  endDate: string,
  limit: number,
  sortedBy: string,
  enabled: boolean
) => {
  return useQuery(
    ['payments', {payerId, startDate, endDate, sortedBy, limit}],
    async () => {
      const params = {
        payerId,
        startDate,
        endDate,
        sortedBy,
        limit
      }
      const requestConfig: AxiosRequestConfig = {
        params
      }
      const res: AxiosResponse<Payment[]> = await api.get('/payments', requestConfig)
      return {
        total: res.headers['total-count'] ? +res.headers['total-count'] : 0,
        payerPayments: res.data
      }
    },
    {
      enabled: !!payerId && !!startDate && !!endDate && !!sortedBy && !!limit && enabled,
      keepPreviousData: limit !== DefaultLimit,
      staleTime: 1000
    }
  )
}
