import {dateFormatter, TextButton} from '@hconnect/uikit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import {Box, Card, CardContent, Grid, Skeleton} from '@mui/material'
import classNames from 'classnames'
import currencyFormatter from 'currency-formatter'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {CustomHeader} from '../../../../../Molecules/CustomHeader'
import {FieldValue} from '../../../../../Molecules/FieldDetails'
import {DeliveryWithLineItem} from '../../Delivery.types'

import {DeliveryMaterialDescription} from './DeliveryLineItemFields'
import {InvoiceItemContracts, InvoiceItemQuantity} from './InvoiceDetailFields'
import {ViewDetailsButton} from '../../../../../Molecules/Buttons/ViewDetailsButton'

interface InvoiceItemCardProps {
  item: any
  currency: string
  id: number
  viewInvoiceItemDetails: (item: any) => void
}

const InvoiceItemCard: React.FC<InvoiceItemCardProps> = ({
  item,
  currency,
  id,
  viewInvoiceItemDetails
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  if (item === undefined) return null

  return (
    <Grid container>
      <Grid item xs={12} justifyContent="space-between" display="flex">
        <FieldValue
          label={t('invoiceDetail.lineItems.dateShipped')}
          dataTestId={`invoice-items-${id}-shipping-date`}
          value={item?.shippingDate ? dateFormatter(item.shippingDate, language) : '-'}
          gutter
        />
        <OpenInFullIcon
          onClick={() => viewInvoiceItemDetails && viewInvoiceItemDetails(item)}
          data-test-id={`invoice-items-${id}-card-expand-icon`}
        />
      </Grid>
      {item?.materials?.size > 0 && (
        <Grid item xs={12}>
          <FieldValue
            label={t('invoiceDetail.lineItems.material')}
            dataTestId={`invoice-items-${id}-material`}
            value={
              <DeliveryMaterialDescription
                delivery={[...item.materials.values()][0] as DeliveryWithLineItem}
                t={t}
              />
            }
            gutter
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <FieldValue
          dataTestId={`invoice-items-${id}-contract`}
          label={t('invoiceDetail.lineItems.contract')}
          value={InvoiceItemContracts(item, t)}
          gutter
        />
      </Grid>
      <Grid item xs={6}>
        <FieldValue
          label={t('invoiceDetail.lineItems.quantity')}
          dataTestId={`invoice-items-${id}-quantity`}
          value={<InvoiceItemQuantity item={item} language={language} />}
        />
      </Grid>
      <Grid item xs={6}>
        <FieldValue
          label={t('invoiceDetail.lineItems.netAmount')}
          dataTestId={`invoice-items-${id}-netAmount`}
          value={currencyFormatter.format(item.netAmount, {
            code: currency,
            locale: language
          })}
        />
      </Grid>
      <Grid item xs={12} mt={2} mb={1}>
        <ViewDetailsButton
          onClick={() => viewInvoiceItemDetails && viewInvoiceItemDetails(item)}
          dataTestId={`invoice-items-${id}-card-button`}
          value={t('viewDetails')}
        />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles()((theme) => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  cardContent: {
    padding: theme.spacing(2)
  },
  linearOverlay: {
    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.1) 100%)'
  }
}))

interface InvoiceItemsByMaterialListProps {
  data: any[]
  currency: string
  loading?: boolean
  viewInvoiceItemDetails: (item: any) => void
}

export const InvoiceItemsByMaterialList: React.FC<InvoiceItemsByMaterialListProps> = ({
  data,
  currency,
  loading,
  viewInvoiceItemDetails
}) => {
  const {t} = useTranslation()
  const {classes} = useStyles()

  const [viewAll, setViewAll] = useState<boolean>(data?.length === 1)
  useEffect(() => setViewAll(data?.length === 1), [data])

  return (
    <Box mb={6} data-test-id="invoice-items-by-material-list">
      <CustomHeader title={t('invoiceDetail.itemsGroupedByMaterial')}>
        {data?.length > 1 && (
          <TextButton
            endIcon={<ArrowDropDownIcon />}
            data-test-id="invoice-items-view-all"
            color="secondary"
            variant="text"
            onClick={() => setViewAll(!viewAll)}
            disabled={loading}
          >
            {t('order.orderDetails.viewAllDeliveries', {
              numberOfDeliveries: data.length
            })}
          </TextButton>
        )}
      </CustomHeader>
      {loading && <Skeleton height="300px" width="100%" variant="rectangular" />}
      {!loading &&
        data?.length > 0 &&
        data.slice(0, viewAll ? data.length : 1).map((item, i) => (
          <Card
            variant="outlined"
            key={item.netAmount}
            className={classNames([
              classes.card,
              {
                [classes.linearOverlay]: !viewAll
              }
            ])}
            style={{backgroundColor: '#fff'}}
            data-test-id={`invoice-items-${i + 1}-card`}
          >
            <CardContent className={classes.cardContent}>
              <InvoiceItemCard
                item={item}
                currency={currency}
                id={i + 1}
                viewInvoiceItemDetails={viewInvoiceItemDetails}
              />
            </CardContent>
          </Card>
        ))}
    </Box>
  )
}
