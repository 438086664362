import {Typography} from '@hconnect/uikit'
import {Box, Button, Grid, Paper} from '@material-ui/core'
import BankTransferIcon from '@material-ui/icons/AccountBalance'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {usePayNowStyles} from '../PayNow.styles'

interface Props {
  openModal: () => void
  delegoCheckout: ({
    noDelegoUi,
    amount,
    currencyCode,
    country,
    analytics,
    sorg
  }: {
    noDelegoUi?: boolean
    amount?: number
    currencyCode?: string
    country?: string
    analytics: any
    sorg?: string
  }) => void
  country
  analytics: any
  companyCode?: string
}
export const AddCreditCard: React.FC<Props> = ({
  delegoCheckout,
  openModal,
  country,
  analytics,
  companyCode
}) => {
  const {t} = useTranslation()
  const {fontSizeLarge, paperWide} = usePayNowStyles()
  return (
    <Paper
      elevation={0}
      classes={{
        root: paperWide
      }}
    >
      <Box pt={3} pl={2.5} pr={2.5} pb={2.25}>
        <Grid container>
          <Grid item md={5}>
            <Box
              display="flex"
              flexDirection="column"
              data-test-id="pay-now-paper-content"
              alignItems="center"
              justifyContent="space-between"
              flex={1}
            >
              <Box
                data-test-id="pay-now-paper-content-icons"
                display="flex"
                flexDirection="row"
                justifyContent="center"
                width="100%"
                mb={3.75}
              >
                <Box mr={1}>
                  <BankTransferIcon
                    htmlColor="#b2c3c9"
                    fontSize="large"
                    classes={{
                      fontSizeLarge
                    }}
                  />
                </Box>
                <Box ml={1}>
                  <CreditCardIcon
                    htmlColor="#b2c3c9"
                    fontSize="large"
                    classes={{
                      fontSizeLarge
                    }}
                  />
                </Box>
              </Box>
              <Box data-test-id="pay-now-paper-content-button">
                <Button
                  color="primary"
                  fullWidth
                  onClick={async () => {
                    await openModal()
                    delegoCheckout({noDelegoUi: false, country, sorg: companyCode, analytics})
                  }}
                >
                  {t('lumpSumPayment.addPayment')}
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item md={2} />
          <Grid item md={5}>
            <Typography paragraph>{t('lumpSumPayment.addPaymentMessageFirst')}</Typography>
            <Typography paragraph>{t('lumpSumPayment.addPaymentMessageSecond')}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
