import {withRoute} from '@hconnect/uikit'

import LegalDocumentPages from './LegalDocumentPages'

export const ROUTE = '/privacy'
const PrivacyPage = () => <LegalDocumentPages legalDocumentType="dpp" />

export default withRoute({
  path: ROUTE,
  label: 'Privacy',
  hideInMainNav: true,
  routeProps: {exact: true}
})(PrivacyPage)
