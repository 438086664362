/* eslint-disable complexity */
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Modal} from '@hconnect/uikit'
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'
import {Box, Button, CircularProgress, Skeleton, Typography} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import {TFunction} from 'i18next'
import {useEffect, useState, useRef, useCallback} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {v4 as uuidV4} from 'uuid'

import BofaBanner from '../../../../Assets/payments/bofaBanner.svg'
import {useBranding} from '../../../../Organisms/Branding'
import {useFeaturesState} from '../../../../Organisms/Features'
import {BofAPaymentRequestType} from '../../../../Organisms/Payments'
import {AppState} from '../../../../Root.store'
import {UserProfileState} from '../../../../UserProfile/UserProfile.reducer'
import {ROUTE as PaymentConfirmationRoute} from '../../PaymentConfirmation'
import {BofaEvent, loadBofaSDK, setBofaProps} from '../../PayNow/common'
import {
  PaymentAnalyticsEvents,
  PaymentMethods,
  PaymentProviders
} from '../../PayNow/common/constants'
import {usePaymentOptions} from '../../PayNow/hooks'
import {
  ExcludedInvoice,
  TotalAmount,
  TotalAmountCriteria,
  useOptimisticTotalAmount,
  useTotalAmount
} from '../hooks'
import {useBofaPayment} from '../hooks/useBofaPayment'
import {Invoice, PaymentExcludeReason} from '../Invoice.types'
import {mapExcludedInvoices} from '../Invoice.utils'

import {ExcludedInvoiceDetailsCard} from './ExcludedInvoiceDetailsCard'
import {PaymentDetailsAlertCard} from './PaymentDetailsAlertCard'

interface PaymentDetailsCardProps {
  totalAmountCriteria: TotalAmountCriteria
  currency: string
  language: string
  t: TFunction
  allInvoicesSelected: boolean
  payerId?: string
  payerNumber?: string
  customerId: string
  countryCode: string
  setRowsSelectable: (selectable: boolean) => void
  showExcludedInvoices: () => void
  invoices: Invoice[]
}
interface PaymentTrackingEvents {
  paymentProvider?: string
  paymentId?: string
  entryPoint?: string
}

export const PaymentDetailsCard: React.FC<PaymentDetailsCardProps> = ({
  totalAmountCriteria,
  currency,
  language,
  t,
  allInvoicesSelected,
  payerId,
  payerNumber,
  customerId,
  countryCode,
  setRowsSelectable,
  showExcludedInvoices,
  invoices
}: PaymentDetailsCardProps) => {
  const history = useHistory()
  const {getFeature} = useFeaturesState()
  const isBofAPaymentEnabled = getFeature('BofaPayments')
  const clearInvoicesEnabled = getFeature('ClearInvoices')
  const {userProfile} = useSelector<AppState, UserProfileState>((state) => state.userProfile)
  const {mutate: createBofaPayment, isLoading: isPaymentFetching} = useBofaPayment()
  const analyticsId = useRef<string>(uuidV4())
  const {contactEmail} = useBranding()
  const [loadedScript, setLoadedScript] = useState(false)
  const [modalOpened, setModalOpened] = useState(false)
  const [bofAEvent, setBofAEvent] = useState<BofaEvent | undefined>(undefined)
  const {data: totalAmountData, isLoading: isTotalAmountLoading} = useTotalAmount(
    totalAmountCriteria,
    true
  )
  const optimisticTotalAmount = useOptimisticTotalAmount(totalAmountCriteria)
  const totalAmount: TotalAmount | undefined = isTotalAmountLoading
    ? optimisticTotalAmount
    : totalAmountData

  const selectedExcludedInvoices = mapExcludedInvoices(invoices)
  const excludedInvoices: ExcludedInvoice[] = selectedExcludedInvoices.concat(
    totalAmount?.excludedInvoices ?? []
  )

  const {data: options, isFetching: isPaymentOptionsFetching} = usePaymentOptions(
    payerId,
    countryCode
  )
  const invoicesClearingLimitExceeded = totalAmount?.excludedInvoices?.filter(
    (invoice) => invoice.reason === PaymentExcludeReason.ClearingLimitExceeded
  )
  const clearingLimit = options?.options?.clearingLimit

  const defaultCompany = options?.companies?.length ? options.companies[0] : undefined
  const selectedCompany = useRef(defaultCompany)
  selectedCompany.current =
    countryCode === 'US'
      ? options?.companies?.find((listedCompany) => listedCompany.companyCode === 'US92')
      : defaultCompany

  const trackEventsForPayment = useCallback(
    (eventName: string, eventData: PaymentTrackingEvents) => {
      const commonAnalyticsData = {
        product: 'hub',
        payerId: payerId || '',
        analyticsId: analyticsId.current || '',
        countryCode: countryCode || 'US',
        companyId: selectedCompany.current?.companyCode || '',
        currency: currency,
        amount: (totalAmount?.totalGrossAmount || 0).toString(),
        customerId,
        paymentMethod: isBofAPaymentEnabled ? PaymentMethods.BOFA : PaymentMethods.DELEGO
      }

      trackEvent(eventName, {
        ...commonAnalyticsData,
        ...eventData
      })
    },
    [
      payerId,
      countryCode,
      currency,
      totalAmount?.totalGrossAmount,
      customerId,
      isBofAPaymentEnabled
    ]
  )

  useEffect(() => {
    if (!loadedScript) {
      if (isBofAPaymentEnabled) {
        loadBofaSDK(() => {
          ;(window as any).addEventListener('checkout-event', (event: any) => {
            setBofAEvent(event.detail)
            trackEventsForPayment(PaymentAnalyticsEvents.HUB_PAYMENT_POSTING, {
              paymentProvider: PaymentProviders.BOFA
            })
          })
          setLoadedScript(true)
        })
      } else {
        setLoadedScript(true)
      }
    }
  }, [loadedScript, isBofAPaymentEnabled, trackEventsForPayment])

  const navigateToConfirmation = (paymentId: string | undefined, isPaymentCreated: boolean) => {
    const paymentProvider = isBofAPaymentEnabled ? PaymentProviders.BOFA : PaymentProviders.DELEGO

    if (paymentId && isPaymentCreated) {
      // track success of payment event
      trackEventsForPayment(PaymentAnalyticsEvents.HUB_PAYMENT_POSTED_SUCCESS, {
        paymentProvider,
        paymentId: paymentId
      })
    } else {
      // track failure of payment event
      trackEventsForPayment(PaymentAnalyticsEvents.HUB_PAYMENT_POSTED_FAILED, {paymentProvider})
    }
    history.push(
      `${PaymentConfirmationRoute}?payerId=${payerId}&paymentId=${paymentId}&isPaymentCreated=${isPaymentCreated}`
    )
  }

  useEffect(() => {
    if (!bofAEvent || !userProfile || !totalAmount || !selectedCompany.current) return
    const request: BofAPaymentRequestType = {
      currency: selectedCompany.current?.defaultCurrency,
      companyCode: selectedCompany.current?.companyCode,
      payerId: payerId || '',
      userEmail: userProfile.eMail || '',
      userName: userProfile.name || '',
      isInternalUser: userProfile.isInternal || false,
      countryCode: countryCode || 'US',
      feedbackEmail: contactEmail || 'nam.customerportal@heidelbergmaterials.com',
      analytics: {analyticsId: analyticsId.current || ''},
      bofAAuthorization: {
        token: bofAEvent.token,
        digiSign: bofAEvent.digiSign,
        customerAccountReference: bofAEvent.customer_account_reference,
        customerReference: bofAEvent.customer_reference,
        amount: totalAmount?.totalGrossAmount || 0,
        date: new Date().toISOString()
      },
      selectedInvoicesWithAmount: totalAmount?.selectedInvoices,
      market: userProfile?.marketId,
      ...(userProfile?.defaultLocale ? {locale: userProfile?.defaultLocale} : {})
    }
    createBofaPayment(request, {
      onSuccess: (res) => navigateToConfirmation(res.data?.id, res.data.status === 'created'),
      onError: () => navigateToConfirmation(undefined, false)
    })
  }, [bofAEvent])

  useEffect(() => {
    if (isBofAPaymentEnabled && loadedScript && totalAmount) {
      setBofaProps({
        amount: totalAmount.totalGrossAmount,
        customerReference: payerNumber || '',
        customerAccountReference: payerNumber || ''
      })
    }
  }, [totalAmount, isBofAPaymentEnabled, loadedScript, payerNumber])

  const payNow = () => {
    if (!totalAmount) return

    trackEventsForPayment(PaymentAnalyticsEvents.HUB_PAYMENT_OPENED, {
      paymentProvider: isBofAPaymentEnabled ? PaymentProviders.BOFA : PaymentProviders.DELEGO,
      entryPoint: 'InvoiceListView'
    })

    if (isBofAPaymentEnabled) {
      setModalOpened(true)
      return
    } else {
      const state: Partial<TotalAmount> = {
        customerReference: totalAmount.customerReference,
        totalGrossAmount: totalAmount.totalGrossAmount,
        selectedInvoices: totalAmount.selectedInvoices,
        entry: 'invoices',
        payAll: allInvoicesSelected
      }
      history.push(
        `/finance/paymentRequest?amount=${totalAmount.totalGrossAmount.toFixed(
          2
        )}&currency=${currency}`,
        state
      )
    }
  }

  const isLoading =
    isTotalAmountLoading || isPaymentOptionsFetching || !loadedScript || isPaymentFetching

  const row = (label: string, testId: string, value?: number) => (
    <Box display="flex" alignItems="center">
      <Typography style={{flexGrow: 1}}>{label}</Typography>
      {value === undefined ? (
        <Skeleton variant="text" sx={{fontSize: 16}} width="60px" />
      ) : (
        <Typography data-test-id={testId}>
          {currencyFormatter.format(value, {code: currency, locale: language})}
        </Typography>
      )}
    </Box>
  )

  const clearingLimitCard = () => {
    if (!clearInvoicesEnabled || invoicesClearingLimitExceeded?.length === 0) {
      setRowsSelectable(true)
      return null
    }
    setRowsSelectable(false)

    const analyticsId = uuidV4()

    trackEvent('clearingLimitReached', {
      analyticsId,
      product: 'hub',
      countryCode,
      userId: userProfile?.user_id,
      payerId
    })
    return (
      <Box mt={3}>
        <PaymentDetailsAlertCard
          text={t('invoice.singleInvoicePayment.clearingLimit', {
            clearingLimit: clearingLimit
          })}
          data-test-id="clearing-limit-card"
        />
      </Box>
    )
  }

  return (
    <>
      <Box data-test-id="payment-details-card">
        <Typography fontWeight={600}>{t('invoice.singleInvoicePayment.totalToPay')}</Typography>
        {!totalAmount ? (
          <Skeleton variant="text" width="180px" height="56px" />
        ) : (
          <Typography
            fontWeight={600}
            fontSize={28}
            lineHeight={2}
            color="#6A9142"
            data-test-id="invoice-payment-sum"
          >
            {currencyFormatter.format(totalAmount.expectedTotalGrossAmount, {
              code: currency,
              locale: language
            })}
          </Typography>
        )}

        {excludedInvoices.length > 0 ? (
          <Box sx={{margin: '16px 0px'}}>
            <ExcludedInvoiceDetailsCard
              excludedInvoices={excludedInvoices}
              t={t}
              showExcludedInvoices={showExcludedInvoices}
              currency={currency}
              language={language}
              invoices={invoices}
            />
          </Box>
        ) : (
          ''
        )}

        {row(
          t('invoice.singleInvoicePayment.invoicesSum'),
          'invoices-net-amount',
          totalAmount?.expectedTotalNetAmount
        )}
        {row(
          t('invoiceDetail.salesTax'),
          'invoices-tax-amount',
          totalAmount?.expectedTotalTaxAmount
        )}

        <Box mt={3} />

        <Button
          color="primary"
          variant="contained"
          startIcon={
            isPaymentFetching ? (
              <CircularProgress size="small" style={{width: 18, height: 18}} />
            ) : (
              <ArrowForwardOutlinedIcon />
            )
          }
          disabled={
            isLoading ||
            (totalAmount?.totalGrossAmount || 0) === 0 ||
            (invoicesClearingLimitExceeded?.length || 0) > 0
          }
          onClick={payNow}
          data-test-id="invoice-payment-button"
          {...(isBofAPaymentEnabled && {id: 'orbipay-checkout-button'})}
        >
          {t('invoice.singleInvoicePayment.proceed')}
        </Button>
        {clearingLimitCard()}
      </Box>
      <Modal open={modalOpened} onClose={() => setModalOpened(false)} fitContent>
        <Box px={4}>
          <img src={BofaBanner} alt="Bank transfer with Bank of America" height={100} />
          <div id="orbipay-checkout-iframe-div" style={{width: 500, height: 400}}></div>
        </Box>
      </Modal>
    </>
  )
}
