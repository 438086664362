import {Typography} from '@hconnect/uikit'
import {Box} from '@mui/material'
import {TFunction} from 'i18next'
import React from 'react'

import MaterialDescription from '../../../../../Molecules/MaterialDescription'
import {Features} from '../../../../../Organisms/Features'
import {DeliveryWithLineItem, Delivery, Shippings} from '../../Delivery.types'
import {BreakdownLineItem} from '../../Invoice.types'

const formatShippingType = (shippingType: Shippings | undefined, t: TFunction) =>
  shippingType ? t(`invoiceDetail.lineItems.expand.${shippingType}`) : ''
interface BaseDeliveryLineItem {
  delivery: DeliveryWithLineItem
  t: TFunction
}

type DeliveryPONumberType = BaseDeliveryLineItem
export const DeliveryPONumber: React.FC<DeliveryPONumberType> = ({delivery, t}) => {
  const item = delivery as BreakdownLineItem
  const PONumber = item.customerReference ? item.customerReference : t('missingValue')
  return (
    <>
      <Features name="ShowPONumber">
        <Box>{PONumber}</Box>
      </Features>
      <Features name="InvoiceOrderNumber">
        <Typography color="secondary" variant="subtitle1">
          {(delivery as Delivery).orderNumber}
        </Typography>
      </Features>
    </>
  )
}

type DeliveryMaterialDescriptionType = BaseDeliveryLineItem
export const DeliveryMaterialDescription: React.FC<DeliveryMaterialDescriptionType> = ({
  delivery
}) => {
  const {materialDescription, customerMaterialDescription} = delivery as BreakdownLineItem

  return (
    <Box style={{wordBreak: 'break-word'}}>
      <MaterialDescription
        materialDescription={materialDescription}
        customerMaterialDescription={customerMaterialDescription}
      />
    </Box>
  )
}

type DeliveryTransportType = BaseDeliveryLineItem
export const DeliveryTransport: React.FC<DeliveryTransportType> = ({delivery, t}) => (
  <Box>{formatShippingType((delivery as Delivery).shippingType, t)}</Box>
)
