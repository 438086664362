import {makeStyles} from 'tss-react/mui'

const cardAndOverlayStyle = {
  card: {
    width: '100%',
    marginBottom: 8,
    paddingTop: 2,
    paddingBottom: 2
  },
  linearOverlay: {
    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)'
  }
}
export const useDeliveryLineItemStyles = makeStyles()(() => ({...cardAndOverlayStyle}))

export const useLineItemListStyles = makeStyles()(() => ({
  ...cardAndOverlayStyle,
  alignRight: {
    textAlign: 'right'
  }
}))
export const useIconStyles = makeStyles()((theme) => ({
  icon: {
    border: 'none',
    boxShadow: 'none',
    color: `${theme.palette.secondary.dark} !important`,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
      background: 'transparent'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem'
    }
  }
}))
