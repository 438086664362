/* eslint-disable complexity */
/* eslint-disable no-duplicate-case */
export const mapAuthorizationErrorFromResponseCode = (authorizationErrorType: string): string => {
  switch (authorizationErrorType) {
    // Authorization responses
    case '110':
      return '13'
    case '506':
    case '131':
      return '14'
    case '208':
      return '41'
    case '209':
      return '43'
    case '116':
      return '54'
    case '121':
      return '61'
    case '782':
      return '65'
    case '224':
      return '78'
    case '516':
    case '907':
      return '91'
    case '215':
    case '902':
    case '505':
    case '339':
    case '726':
    case '118':
    case '506':
    case '131':
    case '729':
      return '12'

    default:
      return 'otherError'
  }
}
