import {dateFormatter} from '@hconnect/uikit'
import {Box, Card, CardContent} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {FieldValue} from '../../../../Molecules/FieldDetails'
import {Analytics, DownloadStatementPDF} from '../Statements.columns'
import {Statement} from '../Statements.types'

interface StatementCardType {
  statement: Statement
  analytics: Analytics
  downloadDocuments: (jobId: string, documentId: string, fileName: string) => void
}

export const StatementCard: React.FC<StatementCardType> = ({
  statement,
  analytics,
  downloadDocuments
}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  return (
    <Card
      sx={{
        marginBottom: 2
      }}
      variant="outlined"
      data-test-id={`statement-card-${statement.statementId}`}
    >
      <CardContent sx={{paddingTop: 3}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box data-test-id="statement-card-date">
            <FieldValue
              label={t('statements.date')}
              dataTestId="statement-date"
              value={dateFormatter(statement.date, language)}
              gutter
            />
          </Box>
          <Box
            data-test-id="statement-card-pdf"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
          >
            <FieldValue
              dataTestId="payment-amount"
              label={t('statements.files')}
              value={
                <DownloadStatementPDF
                  t={t}
                  analytics={analytics}
                  statement={statement}
                  downloadDocuments={downloadDocuments}
                />
              }
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
