import {useQuery} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {CreditCardRates} from '../PayNow.types'

export const useCreditCardRates = (payerId: string | undefined, enabled: boolean) => {
  return useQuery(
    ['payment-credit-rates', {payerId}],
    async () => {
      const res: AxiosResponse<CreditCardRates> = await api.get(`/payers/${payerId}`, {
        params: {
          expand: 'creditCardRates'
        }
      })
      return res.data
    },
    {
      enabled: !!payerId && enabled
    }
  )
}
