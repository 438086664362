import {Theme, makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
  common: {
    boxSizing: 'border-box',
    borderRadius: 4,
    maxHeight: '24px',
    width: 'fit-content',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(0.5),
      height: '12px',
      width: '12px'
    }
  },
  open: {
    background: '#0184441A',
    color: '#018444'
  },
  processing: {
    background: '#8383831A'
  },
  processingCaption: {
    fontSize: '10px',
    marginTop: theme.spacing(0.5)
  },
  cancelled: {
    background: '#EC3D361A',
    color: '#EC3D36'
  },
  closed: {
    background: '#A0A0A01A'
  },
  partiallyPaid: {
    background: '#BAB3081A',
    color: '#BAB308'
  }
}))
