import {useAuth} from '@hconnect/common/authentication/context'
import React from 'react'

import {InvitationsOverviewPage} from '../../../Organisms/Invite/Overview/InvitationsOverviewPage'

export const InviteOverviewPage: React.FC = () => {
  const {decodedToken} = useAuth()
  const loggedInUserId = decodedToken?.user_id || ''
  return <InvitationsOverviewPage loggedInUserId={loggedInUserId} />
}
