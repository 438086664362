import {dateFormatter, Typography} from '@hconnect/uikit'
import {Box, Card, CardContent} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import React from 'react'
import {Trans, useTranslation} from 'react-i18next'

import {FieldValue} from '../../../../Molecules/FieldDetails'
import {Payment} from '../Payments.types'
import {Features} from '../../../../Organisms/Features'
import { ISupplierOrgUnits, getSupplierName } from '../../../../Molecules/SupplierFilter/SupplierNameFilter'

interface PaymentCardType {
  payment: Payment
  surchargeEnabled: boolean
  supplierNames: ISupplierOrgUnits[]
}

export const PaymentCard: React.FC<PaymentCardType> = ({payment, surchargeEnabled, supplierNames}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()

  return (
    <Card
      sx={{
        marginBottom: 2
      }}
      variant="outlined"
      data-test-id={`order-card-${payment.paymentId}`}
    >
      <CardContent sx={{paddingTop: 3}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Box data-test-id="payment-card-posting-date">
            <FieldValue
              label={t('payments.postingDate')}
              dataTestId="posting-date"
              value={dateFormatter(payment.postingDate, language)}
              gutter
            />
          </Box>
          <Box data-test-id="payment-card-reference" width="100%">
            <FieldValue dataTestId="payment-reference" label={t('payments.references')} gutter>
              <Box display="flex" flexDirection="column" py={0.5}>
                <Typography variant="body2">
                  {t('payments.transaction')} {payment.paymentNumber}
                </Typography>
                <Typography color="secondary" variant="subtitle1">
                  {payment.remark}
                </Typography>
              </Box>
            </FieldValue>
          </Box>
          <Features name="ShowSupplierFilter">
            {supplierNames?.length > 1 && <Box data-test-id="payment-card-company-code">
              <FieldValue
                label={t('payments.company')}
                dataTestId="company-code"
                value={getSupplierName(supplierNames, payment.companyCode)}
                gutter
              />
            </Box>}
          </Features>
          <Box
            data-test-id="payment-card-amount"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="100%"
          >
            <FieldValue
              dataTestId="payment-amount"
              label={surchargeEnabled ? t('payments.grossWithSurcharge') : t('payments.gross')}
            >
              {surchargeEnabled && payment.surchargeItem ? (
                <Box display="flex" flexDirection="column" alignItems="flex-start">
                  <Box>
                    <Typography variant="body2" color="textPrimary">
                      {currencyFormatter.format(payment.amount, {
                        code: payment.currency,
                        locale: language
                      })}
                    </Typography>
                  </Box>
                  <Box data-test-id={`surcharge-amount-${payment.paymentId}`}>
                    <Typography variant="subtitle1" color="secondary">
                      <Trans
                        i18nKey="payments.surchargeInformation"
                        values={{
                          amount: currencyFormatter.format(payment.surchargeItem.amount, {
                            code: payment.surchargeItem.currency,
                            locale: language
                          })
                        }}
                      />
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Typography variant="body2" color="textPrimary">
                  {currencyFormatter.format(payment.amount, {
                    code: payment.currency,
                    locale: language
                  })}
                </Typography>
              )}
            </FieldValue>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
