import {TextField, TextFieldProps} from '@material-ui/core'
import React from 'react'

import {usePayNowStyles} from '../PayNow.styles'

export const ControlledTextField: React.FC<
  TextFieldProps & {readOnly?: boolean} & {useLightTheme?: boolean}
> = ({readOnly = false, useLightTheme = false, ...props}) => {
  const styles = usePayNowStyles()
  const textFieldInput = useLightTheme ? styles.textInputLight : styles.textInputDark
  const textFieldLabel = useLightTheme ? styles.textLabelLight : styles.textLabelDark
  return (
    <TextField
      variant={readOnly ? 'standard' : 'filled'}
      InputLabelProps={{
        focused: false,
        classes: {
          root: textFieldLabel,
          focused: textFieldLabel
        }
      }}
      InputProps={{
        readOnly,
        disableUnderline: true,
        classes: {
          root: textFieldInput
        }
      }}
      {...props}
    />
  )
}
