/* eslint-disable complexity */
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {HCThemeType, InlineCheckbox, Modal, Typography} from '@hconnect/uikit'
import {Box, Button, CircularProgress, FormControlLabel, makeStyles} from '@material-ui/core'
import {useQueryClient, useMutation, useIsMutating} from '@tanstack/react-query'
import React, {useEffect, useState} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'

import {api} from '../../../../App.global'
import AmexIcon from '../../../../Assets/ccIcons/ccAMEX.png'
import DiscoverIcon from '../../../../Assets/ccIcons/ccDISC.png'
import MCIcon from '../../../../Assets/ccIcons/ccMC.png'
import VisaIcon from '../../../../Assets/ccIcons/ccVISA.png'
import {Features} from '../../../../Organisms/Features'
import {removeTempCard, DelegoCreditCard} from '../../../../Organisms/Payments'
import {
  createMaskedCardNumber,
  getExpiryDateMasked,
  normalizeCardNumber,
  normalizeExpiryDate
} from '../common'
import {usePayNowStyles} from '../PayNow.styles'

import {ControlledTextField} from './ControlledTextField'
import {OverlayDialog} from './OverlayConfirm'

const getCCIcon = (cardType: string) => {
  switch (cardType) {
    case 'MC':
      return MCIcon
    case 'VISA':
      return VisaIcon
    case 'AMEX':
      return AmexIcon
    case 'DISC':
      return DiscoverIcon
    default:
      return
  }
}

const useModalStyles = makeStyles((theme: HCThemeType) => ({
  label: {
    marginLeft: theme.spacing(1),
    color: 'rgb(0, 55, 77)',
    fontSize: 14
  }
}))

interface PaymentMethodModalFormType {
  cardNumber: string
  cardHolderName: string
  expiryDate: string
  isDefault: boolean
  singleUsedCard: boolean
}
const getExpiryDate = (value: string): string => {
  if (value) {
    const [month, year] = value.split('/')

    return `20${year}-${month}`
  }
  return ''
}
export const ModalContainer: React.FC<{
  open: boolean
  onClose: () => void
  iFrameClosed: boolean
  closeModal: () => void
  editMode?: boolean
  payerId: string
  paymentMethod?: DelegoCreditCard
  addCardRequested: boolean
  errorCards: boolean
  cardsAreSimilar: boolean
  setCardsAreSimilar: React.Dispatch<React.SetStateAction<boolean>>
  countryCode: string
  analytics: any
}> = ({
  open = false,
  onClose,
  iFrameClosed = false,
  closeModal,
  editMode = false,
  payerId,
  paymentMethod,
  addCardRequested,
  errorCards,
  cardsAreSimilar,
  setCardsAreSimilar,
  countryCode,
  analytics
}) => {
  const {t} = useTranslation()
  const {h2} = usePayNowStyles()
  const {label} = useModalStyles()
  const queryClient = useQueryClient()
  const mutating = useIsMutating()
  const dispatch = useDispatch()
  const {control, watch, reset, formState} = useForm<PaymentMethodModalFormType>({
    mode: 'all'
  })
  const {errors} = formState
  const handleClose = () => {
    closeModal()
    reset()
  }
  const useCardMutation = useMutation(
    ({
      card,
      payerId,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handleClose,
      editMode,
      countryCode
    }: {
      card: DelegoCreditCard | undefined
      payerId: string
      handleClose: () => void
      editMode: boolean
      countryCode: string
    }) => {
      const body = {
        provider: 'Delego',
        countryCode,
        payerId,
        startDate: '',
        ...card
      }
      return editMode
        ? api.put('/paymentrequests/cards', body)
        : api.post('/paymentrequests/cards', body)
    },
    {
      onSuccess: (_, {handleClose, payerId}) => {
        queryClient.invalidateQueries(['payment-options', {payerId}])
        handleClose()
        dispatch(removeTempCard())
      }
    }
  )

  const [expiryFieldValue, setExpiryFieldValue] = useState<string>(
    getExpiryDateMasked(paymentMethod?.expiryDate ? paymentMethod.expiryDate : '')
  )
  const [cardHolderName, setCardHolderName] = useState<string>(paymentMethod?.holderName || '')

  const isDefaultDisabled = !!watch('singleUsedCard')
  const singleUsedDisabled = !!watch('isDefault')
  const paymentMethodUpdated: DelegoCreditCard | undefined = paymentMethod
    ? {
        ...paymentMethod,
        isDefault: watch('isDefault') || false,
        holderName: cardHolderName || paymentMethod.holderName,
        expiryDate: getExpiryDate(expiryFieldValue)
      }
    : undefined

  useEffect(() => {
    if (paymentMethod && paymentMethod.expiryDate) {
      setExpiryFieldValue(getExpiryDateMasked(paymentMethod.expiryDate))
    }
    if (paymentMethod?.holderName) {
      setCardHolderName(paymentMethod.holderName)
    }
  }, [paymentMethod])

  const validateCard = () => {
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    const [valMonth, valYear] = expiryFieldValue.split('/')
    const parsedYear = +valYear + 2000
    const parsedMonth = +valMonth
    return parsedYear !== year ? parsedYear > year : parsedMonth >= month
  }

  const validateHolderName = () => {
    return cardHolderName && cardHolderName.trim() !== ''
  }

  const handleSubmit = () => {
    if (paymentMethodUpdated) {
      const cardIsValid = validateCard()
      const cardHolderNameIsValid = validateHolderName()
      if (!cardIsValid || !cardHolderNameIsValid) {
        return
      }
      trackEvent(editMode ? 'hubPaymentMethodEdited' : 'hubPaymentMethodAdded', analytics)
      useCardMutation.mutate({
        card: paymentMethodUpdated,
        payerId,
        editMode,
        handleClose,
        countryCode
      })
    }
  }

  return (
    <Modal open={open} onClose={onClose} fitContent haveCloseButton>
      <Box width={462} height={420}>
        {!iFrameClosed ? <div id="delegoiframehost" className="merchant-custom-style" /> : null}
        {iFrameClosed && paymentMethod ? (
          <Box
            ml={5}
            mr={5}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box display="flex" flexDirection="column">
              <Box data-test-id="credit-card-modal-title">
                <Typography variant="h2" classes={{root: h2}}>
                  {t('lumpSumPayment.creditCardOverview')}
                </Typography>
              </Box>
              {errorCards ? (
                <Box data-test-id="credit-card-modal-error">
                  <Typography variant="caption" color="error">
                    {t('lumpSumPayment.errorCardAdded')}
                  </Typography>
                </Box>
              ) : null}
              <Box
                data-test-id="credit-card-modal-card-details"
                display="flex"
                flexDirection="column"
                mt={errorCards ? 5 : 6.5}
              >
                <Box
                  data-test-id="credit-card-modal-card-number"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Controller
                    render={({field}) => (
                      <ControlledTextField
                        {...field}
                        readOnly
                        label="Card number"
                        value={normalizeCardNumber(createMaskedCardNumber(paymentMethod.token))}
                      />
                    )}
                    control={control}
                    name="cardNumber"
                  />
                  <img src={getCCIcon(paymentMethod.cardType)} height="31px" width="52px" alt="" />
                </Box>
                <Box data-test-id="credit-card-modal-card-holder-name" mt={1.5}>
                  <Controller
                    render={({field}) => (
                      <ControlledTextField
                        {...field}
                        label={t('lumpSumPayment.cardHolderName')}
                        required
                        value={cardHolderName}
                        error={errors?.cardHolderName?.type === 'validate'}
                        helperText={
                          errors?.cardHolderName?.type === 'validate'
                            ? t('lumpSumPayment.cardHolderNameError')
                            : ''
                        }
                        onChange={(event) => {
                          setCardHolderName(event.target.value)
                        }}
                      />
                    )}
                    control={control}
                    rules={{validate: validateHolderName}}
                    name="cardHolderName"
                  />
                </Box>
                <Box data-test-id="credit-card-modal-expiry-date" mt={1.5}>
                  <Controller
                    render={({field}) => (
                      <ControlledTextField
                        {...field}
                        required
                        onChange={(event) => {
                          const {value} = event.target
                          setExpiryFieldValue(normalizeExpiryDate(value))
                        }}
                        value={expiryFieldValue}
                        error={errors?.expiryDate?.type === 'validate'}
                        helperText={
                          errors?.expiryDate?.type === 'validate'
                            ? t('lumpSumPayment.expiryDateError')
                            : ''
                        }
                        label={t('lumpSumPayment.expiryDateMMYY')}
                      />
                    )}
                    control={control}
                    name="expiryDate"
                    rules={{
                      validate: validateCard
                    }}
                  />
                </Box>
                <Features name="PaymentsDefaultCard">
                  <>
                    <Box data-test-id="credit-card-modal-default-checkbox" mt={4}>
                      <Controller
                        name="isDefault"
                        control={control}
                        defaultValue={false}
                        render={({field}) => (
                          <FormControlLabel
                            classes={{
                              label
                            }}
                            control={
                              <InlineCheckbox
                                style={{width: 24, height: 24}}
                                disabled={isDefaultDisabled}
                                {...field}
                              />
                            }
                            label="Save credit card as default"
                          />
                        )}
                      />
                    </Box>
                    <Box data-test-id="credit-card-modal-single-usage" mt={1}>
                      <Controller
                        name="singleUsedCard"
                        control={control}
                        defaultValue={false}
                        render={({field}) => (
                          <FormControlLabel
                            classes={{
                              label
                            }}
                            control={
                              <InlineCheckbox
                                style={{width: 24, height: 24}}
                                disabled={singleUsedDisabled}
                                {...field}
                              />
                            }
                            label="Use this card only for current transaction"
                          />
                        )}
                      />
                    </Box>
                  </>
                </Features>
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" mb={3}>
              <Box minWidth={150} height={40}>
                <Button
                  data-test-id="credit-card-modal-submit-card"
                  fullWidth
                  color="primary"
                  disabled={
                    !paymentMethodUpdated ||
                    addCardRequested ||
                    errorCards ||
                    !!errors?.expiryDate ||
                    !!errors?.cardHolderName
                  }
                  onClick={() => handleSubmit()}
                >
                  {mutating ? (
                    <CircularProgress size="small" style={{width: 18, height: 18}} />
                  ) : (
                    t('lumpSumPayment.saveCard')
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
        <OverlayDialog
          show={cardsAreSimilar && !editMode}
          cancelAction={() => handleClose()}
          confirmAction={() => setCardsAreSimilar(false)}
          title={t('lumpSumPayment.sameCardWarning')}
          confirmLabel={t('lumpSumPayment.sameCardConfirm')}
          cancelLabel={t('lumpSumPayment.sameCardCancel')}
        />
      </Box>
    </Modal>
  )
}
