import {
  DatePicker,
  MOMENTJS_DATE_FORMAT,
  lastFourteenDays,
  lastThirtyDays,
  prevMonthDates,
  prevQuarterDates
} from '@hconnect/uikit'
import {Moment} from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {connect} from 'react-redux'

import {useFeaturesState} from '../../../../Organisms/Features'
import {AppState} from '../../../../Root.store'
import {InvoiceView} from '../Action.types'
import {switchView} from '../Invoice.action'
import {removeLookupFilter, selectDateFilter, setDateFilter} from '../Invoice.filters'
import {useTrackFilterEvents} from '../../../../TrackEvents/hubFilterEvents'
import {PageNames} from '../../../../common/constants'

interface DateFilterType {
  filter: {startDate: Moment; endDate: Moment}
  setFilter: (props: {startDate: string | null; endDate: string | null}) => void
  props?: any
}
const DateFilter: React.FC<DateFilterType> = ({filter = {}, setFilter, ...props}) => {
  const {startDate, endDate} = filter as {startDate: Moment; endDate: Moment}
  const {getFeature} = useFeaturesState()
  const {t} = useTranslation()
  const {trackDateFilterEvent} = useTrackFilterEvents()
  const showMaxDayMessage = getFeature('MaxDayRangeMessage')
  const showMax90DateRange = getFeature('MaxDayRange90')
  const showMax31DateRange = getFeature('MaxDayRange31')
  const getDatePickerQuickSelectionItems = (locale: string) => [
    {
      key: 'last-14-days',
      title: t('invoice.datePicker.lastFourteenDays'),
      type: 'range',
      dates: lastFourteenDays(locale)
    },
    {
      key: 'last-30-days',
      title: t('invoice.datePicker.lastThirtyDays'),
      type: 'range',
      dates: lastThirtyDays(locale)
    },
    {
      key: 'last-month',
      title: t('invoice.datePicker.lastMonth'),
      type: 'range',
      dates: prevMonthDates(locale)
    },
    ...(getFeature('PreviousQuarterInvoices')
      ? [
          {
            key: 'last-quarter',
            title: t('invoice.datePicker.lastQuarter'),
            type: 'range',
            dates: prevQuarterDates(locale)
          }
        ]
      : [])
  ]

  return (
    <DatePicker
      data-test-id="invoice-filter-datepicker"
      startDate={startDate}
      endDate={endDate}
      hideClearButton
      {...(showMax31DateRange && {
        maxDayRange: 31
      })}
      {...(showMax90DateRange && {
        maxDayRange: 90
      })}
      {...(showMaxDayMessage && {
        maxDayRangeInfoMessage: t('datePicker.maxDayRange')
      })}
      handleDateChange={({startDate, endDate}) => {
        const dateRange = {
          startDate: startDate ? startDate.format(MOMENTJS_DATE_FORMAT) : null,
          endDate: endDate ? endDate.format(MOMENTJS_DATE_FORMAT) : null
        }
        setFilter(dateRange)
        trackDateFilterEvent(PageNames.FINANCE_PAGE, dateRange)
      }}
      getQuickSelectionItems={getDatePickerQuickSelectionItems}
      {...props}
    />
  )
}
const mapStateToProps = (state: AppState) => ({
  filter: selectDateFilter(state)
})
const mapDispatchToProps = (dispatch) => ({
  setFilter: (props) => {
    dispatch(
      switchView({
        view: InvoiceView.DEFAULT,
        clearPrevView: true
      })
    )
    dispatch(removeLookupFilter())
    dispatch(setDateFilter(props))
  }
})
export default connect(mapStateToProps, mapDispatchToProps)(DateFilter)
