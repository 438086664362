import {trackEvent} from '@hconnect/common/logging/Analytics'
import {HCThemeType} from '@hconnect/uikit'
import {Box, Button, makeStyles} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import {TFunction} from 'i18next'
import React from 'react'

import {mapAuthorizationErrorFromResponseCode} from '../common'
import {PaymentAnalyticsEvents, PaymentMethods, PaymentProviders} from '../common/constants'

const useStyles = makeStyles((theme: HCThemeType) => ({
  root: {
    minHeight: theme.spacing(4.5),
    alignItems: 'center',
    borderRadius: 7
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}))

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  authorizationErrorType?: Error | string | unknown
  hasSettlementFailed?: boolean
  isRolledBack?: boolean
  onClose: () => void
  t: TFunction
  analytics: {
    payerId: string
    userId: string
    country: string
    creditCardIssuer: string
    creditCardToken: string
  }
  message?: string
}
export const InComponentAlert: React.FC<Props> = ({
  authorizationErrorType,
  hasSettlementFailed,
  isRolledBack,
  onClose,
  t,
  analytics,
  message
}) => {
  const {root, button} = useStyles()
  let errorCode = 'fallbackError'
  let errorMessage: string
  let dataTestId = 'fallbackError'

  if (hasSettlementFailed) {
    trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_SETTLEMENT_ERROR, {
      product: 'hub',
      errorCode: errorCode === 'fetchError' ? errorCode : authorizationErrorType,
      ...analytics
    })
    if (isRolledBack) {
      // settlement failed with rolledback success
      errorMessage = t('lumpSumPayment.settlementError.isRolledBack')
      dataTestId = 'isRolledBack'
    } else if (isRolledBack === false) {
      // settlement failed with failed rollback
      errorMessage = t('lumpSumPayment.settlementError.notRolledBack')
      dataTestId = 'notRolledBack'
    } else {
      // settlement failed and rolledback property is missing
      errorMessage = t('lumpSumPayment.settlementError.settlementFailed')
      dataTestId = 'settlementFailed'
    }
  } else {
    if (!message) {
      if (
        authorizationErrorType &&
        authorizationErrorType instanceof Error &&
        authorizationErrorType.name === 'FetchRequestError'
      ) {
        errorCode = 'fetchError'
      }

      if (authorizationErrorType && typeof authorizationErrorType === 'string') {
        const mappedError = mapAuthorizationErrorFromResponseCode(authorizationErrorType)
        errorCode = mappedError
      }

      trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_AUTHORIZATION_ERROR, {
        product: 'hub',
        errorCode: errorCode === 'fetchError' ? errorCode : authorizationErrorType,
        paymentMethod: PaymentMethods.DELEGO,
        paymentProvider: PaymentProviders.DELEGO,
        ...analytics
      })
      errorMessage = t(`lumpSumPayment.rapidPayError.${errorCode}`, {code: authorizationErrorType})
      dataTestId = errorCode === 'fetchError' ? 'fetchError' : 'authorizationError'
    } else {
      errorMessage = message
      dataTestId = 'overLimit'
    }
  }

  return (
    <Box data-test-id="pay-now-authorization-error" mt={2} mr={11}>
      <Alert
        severity="error"
        variant="filled"
        data-test-id={`pay-now-error-banner-${dataTestId}`}
        action={
          <Button
            data-test-id="pay-now-authorization-error-close"
            size="small"
            variant="text"
            onClick={onClose}
            classes={{root: button}}
          >
            X
          </Button>
        }
        classes={{
          root
        }}
      >
        {errorMessage}
      </Alert>
    </Box>
  )
}
