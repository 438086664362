/* eslint-disable complexity */
import {useQueryParamState} from '@hconnect/common/Invite/Create/QueryParamStateHook'
import {trackEvent} from '@hconnect/common/logging/Analytics'
import {Page} from '@hconnect/uikit'
import {CircularProgress} from '@material-ui/core'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {AxiosRequestConfig} from 'axios'
import currencyFormatter from 'currency-formatter'
import isEmpty from 'lodash/isEmpty'
import React from 'react'
import {useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router'
import {v4 as uuidV4} from 'uuid'

import {api} from '../../../App.global'
import {useBranding} from '../../../Organisms/Branding'
import {CustomerStateType, selectCustomers} from '../../../Organisms/Customers'
import {delegoAuthorize, delegoCheckout} from '../../../Organisms/Delego'
import {useFeaturesState} from '../../../Organisms/Features'
import {Payer, PayersStateType, selectPayers} from '../../../Organisms/Payers'
import {
  DelegoCreditCard,
  PaymentOptionsStateType,
  clearInitialPayment,
  clearAuthorizationError,
  clearSettlementError,
  removeCardFromTheStore
} from '../../../Organisms/Payments'
import {useListChangeFinancePayerIds} from '../../../Roles'
import {AppState} from '../../../Root.store'
import {UserProfileState} from '../../../UserProfile/UserProfile.reducer'
import {InvoiceToPay, TotalAmount} from '../Invoices/hooks'

import {isCardSimilarToOthers, loadSDK, mapDFCardToDelego} from './common'
import {PaymentAnalyticsEvents, PaymentMethods, PaymentProviders} from './common/constants'
import {usePayers, usePaymentOptions, usePaymentResult, useStatusPolling} from './hooks'
import {PayNowConfirmation, PayNowMethodClosed, PayNowMethodOpen, PayNowVerify} from './layouts'
import {PayNowFormState, PayNowPageDispatch, PayNowPageStateType} from './PayNow.types'

const POLLING_INTERVAL = 4000 // 4s

const payNowPageReducer = (
  state: PayNowPageStateType,
  action: PayNowPageDispatch
): PayNowPageStateType => ({...state, ...action})

const initialPageState: PayNowPageStateType = {
  amount: 'opened',
  account: 'closed',
  cards: 'closed',
  verify: 'closed',
  confirmation: 'closed'
}

export interface PaymentAnalyticsBase {
  product: 'hub'
  entryPoint: 'payNowTab' | 'invoices'
  analyticsId: string
  payerId: string
  userId: string
  country: string
  creditCardIssuer: string
  creditCardToken: string
  payAll?: boolean
  amountChanged?: boolean
  paymentProvider?: string
  paymentMethod?: string
}

export const PayNow: React.FC = () => {
  const {
    i18n: {language}
  } = useTranslation()
  const {getFeature} = useFeaturesState()
  const [pageState, setPageState] = React.useReducer(payNowPageReducer, initialPageState)
  const payerIds = useListChangeFinancePayerIds().data

  const [analyticsId, setAnalyticsId] = React.useState<string | undefined>(undefined)
  const [paymentRequest, setPaymentRequest] = React.useState<boolean>(false)
  const [surchargeValue, setSurchargeValue] = React.useState<string | undefined>(undefined)
  const [totalDecimalValue, setTotalDecimalValue] = React.useState<number | undefined>(undefined)
  const [initialDecimalValue, setInitialDecimalValue] = React.useState<number>(0)
  const [invoiceNumbers, setInvoiceNumbers] = React.useState<string[] | undefined>(undefined)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedInvoices, setSelectedInvoices] = React.useState<InvoiceToPay[] | undefined>(
    undefined
  )

  const surchargeRatesEnabled = getFeature('PaymentSurchargeRates')
  const maxLimitValidationEnabled = getFeature('PayAgainstAccountLimit')

  const history = useHistory()
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const {paymentConfirmationMail, contactEmail} = useBranding()
  const customersState = useSelector<AppState, CustomerStateType>((state) => selectCustomers(state))
  const {isFetching: isCustomersFetching, selectedCustomer} = customersState
  const {
    control,
    watch,
    setValue,
    formState: {errors}
  } = useForm<PayNowFormState>({
    mode: 'all'
  })
  const {selectedPayer, isFetching: isPayersFetching} = useSelector<AppState, PayersStateType>(
    (state) => selectPayers(state)
  )
  const [payerForPayment, setPayerForPayment] = React.useState<Payer | null>(selectedPayer)
  const {
    authorized,
    authorization,
    authorizationError,
    authorizationErrorType,
    addCardRequested,
    isFetching,
    errorCards,
    paymentResult,
    settlementError,
    error: createPaymentError
  } = useSelector<AppState, PaymentOptionsStateType>((state) => state.paymentOptions)

  const {isFetching: isOptionsFetching} = useSelector<AppState, PaymentOptionsStateType>(
    (state) => state.paymentOptions
  )

  const clearCardStore = () => dispatch(removeCardFromTheStore())

  const handleCloseAlert = () => {
    dispatch(clearAuthorizationError())
  }

  const handleCloseSettlementError = () => {
    dispatch(clearSettlementError())
  }
  const {userProfile} = useSelector<AppState, UserProfileState>((state) => state.userProfile)
  const paymentMethodAdded = useSelector<AppState, DelegoCreditCard | undefined>(
    (state) => state.paymentOptions.paymentMethodsAdded
  )

  const deleteCreditCard = useMutation(
    ({card}: {card: DelegoCreditCard}) => {
      const {token: cardToken, cardType} = card
      const request: AxiosRequestConfig = {
        params: {
          provider: 'Delego',
          countryCode: selectedCompany?.countryCode || 'US',
          payerId: payerForPayment?.payerId || '',
          cardToken,
          cardType
        }
      }
      return api.delete('/paymentrequests/cards', request)
    },
    {
      onSuccess: () => {
        payerForPayment &&
          queryClient.invalidateQueries(['payment-options', {payerId: payerForPayment.payerId}], {
            exact: true
          })
      }
    }
  )

  const isSelectingAccount = pageState.account === 'opened'
  const isSelectingCards = pageState.cards === 'opened'
  const isSelecting = isSelectingAccount || isSelectingCards

  const {isFetching: isPayerIdsFetching, data: payersList} = usePayers(payerIds)

  const {
    // TODO: handle error from options 👇
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isError,
    isFetching: isPaymentOptionsFetching,
    data: options
  } = usePaymentOptions(payerForPayment?.payerId, userProfile?.country)
  const {creditCardRates} = options || {}

  const [confirmationError, setConfirmationError] = React.useState<undefined | string>(undefined)
  const [documentNumber, setDocumentNumber] = React.useState<undefined | string>(undefined)
  const [documentId, setDocumentId] = React.useState<undefined | string>(undefined)
  const [isRolledBack, setIsRolledBack] = React.useState<undefined | boolean>(undefined)
  const [interval, setRefetchInterval] = React.useState<number>(POLLING_INTERVAL)
  const [cardsAreSimilar, setCardsAreSimilar] = React.useState<boolean>(false)
  const [locationState, setLocationState] = React.useState<TotalAmount | undefined>(undefined)
  const {
    data: pollingData,
    isError: pollingError,
    isFetching: pollingFetching
  } = useStatusPolling(interval, setRefetchInterval, paymentResult?.id || '')

  const {
    data: resultData,
    isError: resultError,
    isFetching: resultFetching
  } = usePaymentResult(setPaymentRequest, pollingData?.status, pollingData?.id)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filter, setFilter] = useQueryParamState<{
    amount?: string
    currency?: string
    type?: 'invoiceIds' | 'filters'
  }>({
    amount: undefined,
    currency: undefined
  })

  const [loadedScript, setLoadedScript] = React.useState(false)
  React.useEffect(() => {
    loadSDK(userProfile?.country || 'US', () => {
      setLoadedScript(true)
    })
  }, [filter.amount, userProfile?.country])

  React.useEffect(() => {
    if (selectedPayer && payersList?.length) {
      const choosenPayer = payersList.find((payer) => payer.payerId === selectedPayer.payerId)
      setPayerForPayment(choosenPayer || (payersList ? payersList[0] : null))
    }
  }, [payersList, selectedPayer])

  React.useEffect(() => {
    if (authorizationError) {
      setPaymentRequest(false)
    }
  }, [authorizationError])

  React.useEffect(() => {
    if (paymentMethodAdded && options && options.creditCards && options.creditCards.length) {
      const similarCard = isCardSimilarToOthers(options.creditCards, paymentMethodAdded)
      setCardsAreSimilar(Boolean(similarCard))
    }
  }, [addCardRequested, paymentMethodAdded, options])

  React.useEffect(() => {
    if (!isFetching && !resultFetching && !pollingError && !resultError && resultData) {
      setConfirmationError(resultData.errorMessage)
      setDocumentNumber(resultData.documentNumber)
      setDocumentId(resultData.id)
      setIsRolledBack(resultData.isRolledBack)
    }
  }, [pollingData, resultData])

  React.useEffect(() => {
    const invoices = filter.amount ? history.location.state : {}
    setLocationState(history.location.state as TotalAmount | undefined)
    // let entry = 'payNowTab'
    // let payAll = false

    if (invoices && !isEmpty(invoices)) {
      const {customerReference} = invoices as TotalAmount
      // entry = (invoices as TotalAmount).entry || 'undefined'
      // payAll = (invoices as TotalAmount).payAll || false

      setInvoiceNumbers(customerReference.split(','))
      setInitialDecimalValue(
        currencyFormatter.unformat(filter.amount ?? '0', {
          code: selectedCompany?.defaultCurrency
        })
      )
      setSelectedInvoices((invoices as TotalAmount).selectedInvoices)
    }
    if (filter.amount && filter.currency) {
      const value = currencyFormatter.format(+filter.amount, {
        code: filter.currency,
        locale: language
      })
      setValue('amount', value)
    }
    const jobId = uuidV4()
    setAnalyticsId(jobId)
    trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_OPENED, {
      ...analytics,
      amount: currencyFormatter.unformat(amountValue, {
        code: selectedCompany?.defaultCurrency
      }),
      paymentMethod: PaymentMethods.DELEGO,
      paymentProvider: PaymentProviders.DELEGO,
      companyId: selectedCompany?.companyCode || '',
      customerId: selectedCustomer?.customerId || ''
    })
    dispatch(clearInitialPayment())
  }, [])

  const paymentIsSuccessful = (): boolean | 'inApproval' | undefined => {
    if (!resultData) {
      return undefined
    }
    if (settlementError) {
      return false
    }

    return resultData?.inApproval ? 'inApproval' : !resultData.errorMessage ? true : undefined
  }

  const selectedCompany =
    userProfile?.country === 'US'
      ? options?.companies.find((listedCompany) => listedCompany.companyCode === 'US92')
      : options?.companies[0]

  const loading =
    !selectedPayer ||
    !loadedScript ||
    isOptionsFetching ||
    isPayersFetching ||
    isCustomersFetching ||
    !selectedCustomer ||
    isPaymentOptionsFetching ||
    isPayerIdsFetching

  const amountValue = watch('amount')
  const paymentMethod = watch('paymentMethodField')
  const customerReference = watch('customerReference')
  // const companyCode = watch('company')

  const decimalValue = currencyFormatter.unformat(amountValue, {
    code: selectedCompany?.defaultCurrency
  })

  // Get the choosen card from react-form-hook
  const choosenCard =
    options && options.creditCards && options.creditCards.length
      ? options.creditCards.find((card) => {
          return paymentMethod && card.token === paymentMethod
        }) ||
        options.creditCards.find((card) => card.isDefault) ||
        options.creditCards[0]
      : undefined

  // Define selectedCard as choosen || first one || no card
  const selectedCard = choosenCard
    ? choosenCard
    : options?.creditCards
    ? options.creditCards[0]
    : null

  const singlePaymentMaxAmount = options?.options?.singlePaymentMaxAmount || undefined

  React.useEffect(() => {
    if (creditCardRates && decimalValue && selectedCard) {
      const validRates = creditCardRates.find(
        (cardRates) =>
          cardRates.cardProvider === selectedCard.cardType &&
          decimalValue >= cardRates.fromRange &&
          decimalValue <= cardRates.toRange
      )
      const value = validRates ? (validRates.rate * decimalValue) / 100 : undefined
      const localizedValue = value
        ? currencyFormatter.format(value, {
            locale: language,
            code: selectedCompany?.defaultCurrency
          })
        : undefined

      const totalDecimalValueTemp = value ? decimalValue + value : undefined

      setTotalDecimalValue(totalDecimalValueTemp)
      setSurchargeValue(localizedValue)
    } else {
      setTotalDecimalValue(undefined)
      setSurchargeValue(undefined)
    }
  }, [decimalValue, selectedCard, payerForPayment])
  const disabledVerifyButton =
    !decimalValue ||
    !selectedCustomer ||
    !payerForPayment ||
    !selectedCompany ||
    !selectedCard ||
    errors.amount?.type === 'validate'

  const handleAuthorization = async (surchargeAmount?: number, summary?: number) => {
    setPaymentRequest(true)
    // remove polling
    queryClient.removeQueries(['payment-polling', paymentResult?.id])
    const selectedInvoices = invoiceNumbers?.join(',')
    // first clear payments from redux store
    dispatch(clearInitialPayment())
    try {
      const currency = selectedCompany?.defaultCurrency || 'USD'
      const payerId = payerForPayment?.payerId || ''
      const country = selectedCompany?.countryCode || 'US'
      // initialize delego sdk
      await delegoCheckout({
        noDelegoUi: true,
        amount: totalDecimalValue || decimalValue,
        currencyCode: selectedCompany?.defaultCurrency || 'USD',
        country,
        analytics,
        sorg: selectedCompany?.companyCode
      })
      trackEvent(PaymentAnalyticsEvents.HUB_PAYMENT_ISSUED, {
        ...analytics,
        product: 'hub',
        analyticsId,
        amount: summary || decimalValue,
        surcharge: surchargeAmount,
        currency,
        cardType: choosenCard?.cardType,
        payerId,
        country: userProfile?.country || 'US'
      })
      // authorize
      payerForPayment &&
        selectedCompany &&
        delegoAuthorize({
          amount: {value: summary || decimalValue, currency},
          countryCode: userProfile?.country || 'US',
          payment: mapDFCardToDelego(selectedCard),
          payerId,
          companyCode: selectedCompany?.companyCode,
          isInternalUser: userProfile?.isInternal || false,
          userEmail: userProfile?.eMail || '',
          userName: userProfile?.name || '',
          feedbackEmail:
            (userProfile?.country === 'US' || userProfile?.country === 'AU') && !!contactEmail
              ? contactEmail
              : 'customerportal@lehighhanson.com',
          analytics: {analyticsId: analyticsId || ''},
          ...(userProfile?.defaultLocale ? {locale: userProfile.defaultLocale} : {}),
          ...(surchargeRatesEnabled ? {surchargeAmount} : {}),
          ...(surchargeRatesEnabled && selectedInvoices ? {selectedInvoices} : {}),
          ...(surchargeRatesEnabled && customerReference ? {customerReference} : {})
        })
      // enable polling again
      setRefetchInterval(POLLING_INTERVAL)
    } catch (err) {
      console.log('error', err)
    }
  }

  const resetAmount = () =>
    setValue(
      'amount',
      currencyFormatter.format(initialDecimalValue, {locale: language, code: filter.currency})
    )

  const setPayer = (payer: Payer) => {
    if (payer === payerForPayment) return

    setValue('amount', '')
    setInvoiceNumbers([])
    setPayerForPayment(payer)
  }

  const analytics: PaymentAnalyticsBase = {
    product: 'hub',
    entryPoint: locationState && locationState.entry ? locationState.entry : 'payNowTab',
    ...(locationState && locationState.entry === 'invoices' ? {payAll: locationState.payAll} : {}),
    ...(locationState && locationState.entry === 'invoices'
      ? {amountChanged: decimalValue !== +locationState.totalGrossAmount}
      : {}),
    analyticsId: analyticsId || '',
    payerId: payerForPayment?.payerId || '',
    userId: userProfile?.user_id || '',
    country: payerForPayment?.countryId || '',
    creditCardIssuer: selectedCard?.cardType || '',
    creditCardToken: selectedCard?.token || '',
    paymentProvider: PaymentProviders.DELEGO,
    paymentMethod: PaymentMethods.DELEGO
  }

  if (loading) {
    return (
      <Page variant="sheet">
        <CircularProgress
          style={{display: 'block', margin: '0 auto'}}
          data-test-id="payment-options-loader"
        />
      </Page>
    )
  }

  if (pageState.cards === 'opened') {
    return (
      <PayNowMethodOpen
        amountValue={amountValue}
        decimalValue={decimalValue}
        choosenCard={choosenCard}
        control={control}
        options={options}
        selectedPayer={payerForPayment}
        paymentMethod={paymentMethod}
        setPageState={setPageState}
        deleteCreditCard={deleteCreditCard}
        selectedCard={selectedCard}
        clearCardCache={clearCardStore}
        paymentMethodAdded={paymentMethodAdded}
        addCardRequested={addCardRequested}
        errorCards={errorCards}
        cardsAreSimilar={cardsAreSimilar}
        setCardsAreSimilar={setCardsAreSimilar}
        country={selectedCompany?.countryCode || 'US'}
        companyCode={selectedCompany?.companyCode}
        analytics={analytics}
      />
    )
  }

  if (
    pageState.verify === 'opened' &&
    (!paymentResult ||
      (resultData &&
        !resultData.documentNumber &&
        resultData.errorMessage &&
        !resultData.inApproval) ||
      paymentIsSuccessful() === false)
  ) {
    return (
      <PayNowVerify
        decimalValue={decimalValue}
        initialAmountValue={amountValue}
        authorizationError={authorizationError}
        authorizationErrorType={authorizationErrorType}
        isSettlementError={paymentIsSuccessful() === false}
        isRolledBack={isRolledBack}
        selectedPayer={payerForPayment}
        choosenCard={choosenCard}
        setPageState={setPageState}
        handleAuthorization={handleAuthorization}
        authorized={authorized}
        authorization={authorization}
        loading={isFetching || paymentRequest}
        handleCloseAlert={handleCloseAlert}
        analytics={analytics}
        paymentConfirmationMail={paymentConfirmationMail}
        handleCloseSettlementError={handleCloseSettlementError}
        countryCode={selectedCompany?.countryCode}
        currency={selectedCompany?.defaultCurrency || 'AUD'}
        surchargeRatesEnabled={surchargeRatesEnabled}
        company={selectedCompany}
        pollingError={pollingError}
        createPaymentError={createPaymentError}
        singlePaymentMaxAmount={singlePaymentMaxAmount}
      />
    )
  }

  if (pageState.verify === 'opened' && paymentResult) {
    return (
      <PayNowConfirmation
        confirmationError={confirmationError}
        isFetching={pollingFetching || resultFetching || paymentRequest}
        pollingError={pollingError}
        pollingData={pollingData}
        documentNumber={documentNumber}
        documentId={documentId}
        paymentIsSuccessful={paymentIsSuccessful()}
        paymentConfirmationMail={paymentConfirmationMail}
        payerId={payerForPayment?.payerId}
      />
    )
  }

  return (
    <PayNowMethodClosed
      amountValue={amountValue}
      decimalValue={decimalValue}
      initialDecimalValue={initialDecimalValue}
      control={control}
      setValue={setValue}
      resetAmount={resetAmount}
      selectedPayer={payerForPayment}
      setPageState={setPageState}
      selectedCard={selectedCard}
      selectedCompany={selectedCompany}
      isSelecting={isSelecting}
      isSelectingAccount={isSelectingAccount}
      disabledVerifyButton={disabledVerifyButton}
      analytics={analytics}
      clearCardCache={clearCardStore}
      payers={payersList || []}
      setPayer={setPayer}
      paymentMethod={paymentMethodAdded}
      addCardRequested={addCardRequested}
      errorCards={errorCards}
      cardsAreSimilar={cardsAreSimilar}
      setCardsAreSimilar={setCardsAreSimilar}
      surchargeValue={surchargeValue}
      surchargeRatesEnabled={surchargeRatesEnabled}
      invoiceNumbers={invoiceNumbers}
      customerReference={customerReference}
      errors={errors}
      maxLimitValidationEnabled={maxLimitValidationEnabled}
      singlePaymentMaxAmount={singlePaymentMaxAmount}
    />
  )
}
