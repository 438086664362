import {Typography} from '@hconnect/uikit'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import {Box, Button, Checkbox} from '@mui/material'
import {TFunction} from 'i18next'
import {useState} from 'react'

import {CustomHeader} from '../../../../Molecules/CustomHeader'
import {Invoice} from '../Invoice.types'
import {findInvoiceDocument, findInvoiceSurchargeDeliveries} from '../Invoice.utils'

interface DownloadOptionsCardProps {
  selectedInvoices: Invoice[]
  bulkDownload: (
    invoiceIds: string[],
    deliveryIds: string[],
    surchargeItems: {invoiceId: string; deliveryIds: string[]}[],
    combined: boolean
  ) => void
  t: TFunction
}
export const DownloadOptionsCard: React.FC<DownloadOptionsCardProps> = ({
  selectedInvoices,
  bulkDownload,
  t
}: DownloadOptionsCardProps) => {
  const [invoicesChecked, setInvoicesChecked] = useState<boolean>(true)
  const [ticketsChecked, setTicketsChecked] = useState<boolean>(true)

  const invoicesWithDocuments = selectedInvoices.filter(
    (invoice) => findInvoiceDocument(invoice) !== undefined
  )

  const getInvoiceIds = (invoices: Invoice[]) => invoices.map((invoice) => invoice.invoiceId)

  const triggerBulkDownload = (combined: boolean) => {
    const surchargeItems = findInvoiceSurchargeDeliveries(selectedInvoices)
    const invoiceIds = invoicesChecked ? getInvoiceIds(invoicesWithDocuments) : []
    const deliveryIds = ticketsChecked ? getInvoiceIds(selectedInvoices) : []
    bulkDownload(invoiceIds, deliveryIds, surchargeItems, combined)
  }

  const canDownload =
    (invoicesWithDocuments.length > 0 && invoicesChecked) ||
    (selectedInvoices.length > 0 && ticketsChecked)

  return (
    <Box data-test-id="invoices-download-options-card">
      <CustomHeader title={t('invoice.multiple.downloadOptions')} color="secondary" />

      <Box display="flex" alignItems="center">
        <Checkbox
          checked={invoicesChecked}
          onChange={(_, checked) => setInvoicesChecked(checked)}
          data-test-id="download-invoices-checkbox"
        />
        <Typography style={{flexGrow: 1}}>{t('invoice.multiple.invoices')}</Typography>
        <Typography>{invoicesWithDocuments.length}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Checkbox
          checked={ticketsChecked}
          onChange={(_, checked) => setTicketsChecked(checked)}
          data-test-id="download-tickets-checkbox"
        />
        <Typography style={{flexGrow: 1}}>{t('invoice.multiple.relatedTickets')}</Typography>
      </Box>

      <Box mt={3} />

      <Box>
        <Button
          color="primary"
          variant="contained"
          startIcon={<DownloadOutlinedIcon />}
          disabled={!canDownload}
          onClick={() => triggerBulkDownload(false)}
          data-test-id="download-selected-pdfs-button-invoices"
        >
          {t('invoice.multiple.downloadSelectedPDFs')}
        </Button>
      </Box>
      <Box mt={3} />
      <Box>
        <Button
          variant="outlined"
          style={{
            borderRadius: 6,
            backgroundColor: '#FFFFFF',
            height: 48,
            color: '#29aaff',
            textTransform: 'none',
            border: '1px solid rgba(0, 39, 77, 0.1)',
            padding: '13px 40px 13px 40px',
            cursor: 'pointer'
          }}
          color="primary"
          startIcon={<PictureAsPdfIcon />}
          disabled={!canDownload}
          onClick={() => triggerBulkDownload(true)}
          data-test-id="combine-to-pdf-and-download-button-invoices"
        >
          {t('invoice.multiple.combineToPdfAndDownload')}
        </Button>
      </Box>
    </Box>
  )
}
