import {TFunction} from 'i18next'
import React from 'react'

import {QuantityUomToSymbolComponent} from '../../../../Order/Order.components'

const formatNumber = (number: number, language: string) =>
  new Intl.NumberFormat(language, {maximumFractionDigits: 2}).format(number)

export const InvoiceItemQuantity: React.FC<{item: any; language: string}> = ({item, language}) => {
  return item?.quantity?.loadQuantity ? (
    <span>
      {formatNumber(item?.quantity?.loadQuantity, language)}{' '}
      {item?.quantity?.loadQuantityUom && (
        <QuantityUomToSymbolComponent uom={item.quantity.loadQuantityUom} />
      )}
    </span>
  ) : (
    <>{'-'}</>
  )
}

export const InvoiceItemContracts: (item: any, t: TFunction) => string = (item, t) => {
  if (item.contracts.size === 0) {
    return '-'
  }
  if (item.contracts.size === 1) {
    return [...item.contracts.values()][0] as string
  }
  return t('order.multipleMaterials')
}
