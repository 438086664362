import {Box} from '@mui/material'
import currencyFormatter from 'currency-formatter'
import React from 'react'

interface Type {
  amount: number
  currency: string
  language: string
}

export const InvoiceAmountValue: React.FC<Type> = ({amount, currency, language}) => (
  <Box>
    {amount && currencyFormatter.format(amount, {
      code: currency,
      locale: language
    })}
  </Box>
)
