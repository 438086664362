import {get} from 'lodash'
import {createSelector} from 'reselect'

import {CheckboxIndetermiateType} from '../../../Molecules/CheckboxDropdown'
import {
  selectInvoice,
  selectInvoiceDeliveries,
  selectInvoiceLineItems,
  selectInvoiceMaterialItems,
  selectInvoiceSurchargeItems,
  selectInvoices
} from '../../../Organisms/Invoices/Invoices.selector'
import {AppState} from '../../../Root.store'

import {Delivery, RelatedEntity} from './Delivery.types'
import {BreakdownLineItem, LineItem, LineItemLink, RelToReference} from './Invoice.types'

export const selectView = (state: AppState) => state.finance.view

export const selectViewCurrent = (state: AppState) => selectView(state).currentView

export const selectViewInvoiceIds = (state: AppState, view: string) => selectView(state)[view]

export const selectViewInvoices = (state: AppState, view: string) =>
  selectView(state)[view].map((invoiceId: string) => selectInvoice(state, invoiceId))

export const selectViewCurrentInvoices = (state: AppState) =>
  selectViewInvoices(state, selectViewCurrent(state))

export const selectExpandedRows = (state: AppState) => state.finance.invoices.expandedIds

export const selectViewCurrentViewCheckedInvoices = (state: AppState) => {
  const availableInvoices = selectViewInvoiceIds(state, selectViewCurrent(state))
  const {checkedInvoiceIds} = selectInvoices(state)
  const checkedAndInViewInvoices = availableInvoices.filter((invoice) =>
    checkedInvoiceIds.includes(invoice)
  )

  return checkedAndInViewInvoices
}
interface NormalizedLineItemsType {
  [key: string]: BreakdownLineItem | LineItem
}
export type DeliveryWithLineItem = Omit<BreakdownLineItem | LineItem, 'links'> & Delivery

export const selectAllChecked = (state: AppState) => {
  const {checkedInvoiceIds, checkedInvoiceTableRows, uncheckedDeliveryIds, byId, allIds} =
    selectInvoices(state)

  const stringifiedAllIds = allIds.sort().toString()
  const allUncheckedDeliveryIds = allIds
    .map((invoiceId: string) => byId[invoiceId].deliveries || [])
    .flat()
    .sort()
    .toString()

  if (
    checkedInvoiceIds.length === 0 &&
    checkedInvoiceTableRows.length === 0 &&
    uncheckedDeliveryIds.sort().toString() === allUncheckedDeliveryIds
  ) {
    return -1
  }
  if (
    checkedInvoiceIds.sort().toString() === stringifiedAllIds &&
    checkedInvoiceTableRows.sort().toString() === stringifiedAllIds &&
    uncheckedDeliveryIds.length === 0
  ) {
    return 1
  }
  return 0
}

export const selectInvoiceChecked = (state: AppState, invoiceId: string): boolean =>
  selectInvoices(state).checkedInvoiceIds.includes(invoiceId)

export const selectDeliveryChecked = (state: AppState, deliveryId: string): boolean =>
  !selectInvoices(state).uncheckedDeliveryIds.includes(deliveryId)

export const selectAvailableRowsOfCurrentView = (state: AppState) =>
  state.finance.view.currentView === 'DEFAULT'
    ? state.finance.view.DEFAULT
    : state.finance.view.LOOKUP

export const selectInvoiceRowChecked = (
  state: AppState,
  rowId: string
): CheckboxIndetermiateType => {
  const deliveries = selectInvoice(state, rowId).deliveries || []
  const deliveriesSomeChecked = deliveries.some((deliveryId: string) =>
    selectDeliveryChecked(state, deliveryId)
  )
  const invoiceChecked = selectInvoiceChecked(state, rowId)
  const someItemsChecked =
    deliveriesSomeChecked ||
    invoiceChecked ||
    selectInvoices(state).checkedInvoiceTableRows.includes(rowId)

  const rowChecked =
    selectInvoices(state).checkedInvoiceTableRows.includes(rowId) &&
    !selectExpandedRows(state).includes(rowId) &&
    someItemsChecked

  const deliveriesAllChecked =
    deliveries.length > 0
      ? deliveries.every((deliveryId: string) => selectDeliveryChecked(state, deliveryId))
      : rowChecked

  if (invoiceChecked && deliveriesAllChecked) {
    return 1
  }
  if (!invoiceChecked && !deliveriesSomeChecked && !rowChecked) {
    return -1
  }
  return 0
}

export const selectInvoiceRowsChecked = (state: AppState) =>
  selectAvailableRowsOfCurrentView(state).reduce<{[key: string]: CheckboxIndetermiateType}>(
    (prev, invoiceId) => ({
      ...prev,
      [invoiceId]: selectInvoiceRowChecked(state, invoiceId)
    }),
    {}
  )

export const selectDeliveryWithLineItems = createSelector(
  [selectInvoiceLineItems, selectInvoiceDeliveries],
  (lineItems: LineItem[], deliveries: Delivery[] | null | undefined) => {
    if (!deliveries || deliveries.length === 0) {
      return null
    }
    const normalizeLineItems: NormalizedLineItemsType = lineItems.reduce<NormalizedLineItemsType>(
      (acc, val) => {
        const links = get(val, 'links')
        if (links && links.length > 0 && links.find((l) => l.rel === 'deliveries')) {
          const link =
            links !== undefined
              ? get(
                  links.find((l: LineItemLink) => l.rel === 'deliveries'),
                  'href',
                  undefined
                )
              : undefined
          return link ? {...acc, [link.split('/')[1]]: val} : {...acc}
        }
        return acc
      },
      {}
    )

    return deliveries.reduce<DeliveryWithLineItem[]>((acc, val) => {
      if (normalizeLineItems[val.deliveryId]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {links, ...rest} = normalizeLineItems[val.deliveryId]
        return [...acc, {...rest, ...val}]
      }
      return acc
    }, [])
  }
)
export type DeliveryWithBreakdown = Array<
  (Omit<BreakdownLineItem, 'links'> & Delivery) | BreakdownLineItem
>

interface NormalizedBreakdownItem {
  withDeliveryId: {
    [key: string]: BreakdownLineItem
  }
  withoutDeliveryId: BreakdownLineItem[]
}
const createNormalizedItems = (items: BreakdownLineItem[] | undefined): NormalizedBreakdownItem => {
  if (!items) {
    return {withDeliveryId: {}, withoutDeliveryId: []}
  }
  return items.reduce<NormalizedBreakdownItem>(
    (acc, curr) => {
      const links = get(curr, 'links')
      if (links && links.length && links.find((l) => l.rel === RelToReference.deliveries)) {
        const link = get(
          links.find((l) => l.rel === RelToReference.deliveries),
          'href'
        )
        return link
          ? {...acc, withDeliveryId: {...acc.withDeliveryId, [link.split('/')[1]]: curr}}
          : {...acc, withoutDeliveryId: [...acc.withoutDeliveryId, curr]}
      }
      return {...acc, withoutDeliveryId: [...acc.withoutDeliveryId, curr]}
    },
    {withDeliveryId: {}, withoutDeliveryId: []}
  )
}
export const selectDeliveryWithMaterialItems = createSelector(
  [selectInvoiceMaterialItems, selectInvoiceDeliveries],
  (materialItems: BreakdownLineItem[] | undefined, deliveries: Delivery[] | null | undefined) => {
    if (!deliveries) {
      return []
    }
    const normalizedMaterialItems = createNormalizedItems(materialItems)
    return deliveries.reduce<DeliveryWithBreakdown>((acc: DeliveryWithBreakdown, curr) => {
      const {deliveryId} = curr
      if (normalizedMaterialItems.withDeliveryId[deliveryId]) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const {links, ...rest} = normalizedMaterialItems.withDeliveryId[curr.deliveryId]
        return [...acc, {...rest, ...curr}]
      }

      return acc
    }, [])
  }
)
export type BreakdownLineItemWithDelivery = BreakdownLineItem &
  Partial<Pick<Delivery, 'deliveryNumber' | 'customerDeliveryNumber'>>
export const selectDeliveryWithSurchargeItems = createSelector(
  [selectInvoiceSurchargeItems, selectInvoiceDeliveries],
  (
    surchargeItems: BreakdownLineItem[] | undefined,
    deliveries: Delivery[] | null | undefined
  ): BreakdownLineItemWithDelivery[] | undefined => {
    if (!deliveries) {
      return surchargeItems
    }
    return (
      surchargeItems?.map((surcharge) => {
        const {links} = surcharge
        const deliveryId = links
          ?.find((l) => l.rel === RelatedEntity.deliveries)
          ?.href.split('/')[1]

        const delivery = deliveries.find((delivery) => delivery.deliveryId === deliveryId)
        if (delivery) {
          const {deliveryNumber, customerDeliveryNumber} = delivery
          surcharge.links = [...(delivery.links as LineItemLink[]), ...(surcharge.links || [])]
          return {...surcharge, deliveryNumber, customerDeliveryNumber}
        }
        return surcharge
      }) || []
    )
  }
)
